import { UseFormSetValue } from 'react-hook-form';

import {
  ContactsTabs,
  CreditCheckTabs,
  FactoringTabs,
  LoadTabs,
  PayrollTabs,
  ProfileTabs,
  TruckBoardTabs,
} from '../components/routes/routes';

import { capitalizeFirstLetter } from './helpers';

export enum USER_EXAMPLE_ROLES {
  ADMIN = 'admin',
  AGENT = 'agent',
  SUPER_ADMIN = 'superAdmin',
  COORDINATOR = 'coordinator',
}

export enum carrierSubTabs {
  carrierTable = 'table',
  carrierRequest = 'request',
  carriersBlackList = 'blacklist',
}

export enum paymentSubTabs {
  Agency = 'Agency',
  Branch = 'Branch',
  Agent = 'Agent',
}

// profile tab visibility
export enum profileTabs {
  agent = 'agents',
  office = 'offices',
  branch = 'branches',
  authority = 'authorities',
  carrier = 'carriers',
  customer = 'customers',
  employee = 'employees',
  factoringCompanies = 'factoringCompanies',
}

const differentNames = {
  office: 'agencies',
  factoringCompanies: 'factoring Companies',
};

export const getProfileTabs = (visibleTabs: any) => {
  const uniqueVisibleTabs: string[] = Array.from(new Set(visibleTabs));
  // todo need open profile factoring
  // && el !== 'factoringCompanies'
  const allowedTabs = ProfileTabs.filter(el => uniqueVisibleTabs.includes(el));

  return allowedTabs?.map((tab: string) => {
    return {
      id: profileTabs[tab as keyof typeof profileTabs],
      name: capitalizeFirstLetter(
        differentNames[tab as keyof typeof differentNames] || profileTabs[tab as keyof typeof profileTabs]
      ),
    };
  });
};

// loads tab visibility
export enum loadsTabs {
  load = 'loads',
  newLoad = 'new_load',
}

export const getLoadsTabs = (visibleTabs: any) => {
  const uniqueVisibleTabs: string[] = Array.from(new Set(visibleTabs));
  const allowedTabs = LoadTabs.filter(el => uniqueVisibleTabs.includes(el));

  return allowedTabs?.map((tab: string) => {
    return {
      id: loadsTabs[tab as keyof typeof loadsTabs],
      name: capitalizeFirstLetter(loadsTabs[tab as keyof typeof loadsTabs]),
    };
  });
};

// payroll tab visibility

export enum payrollTabs {
  payrollInformation = 'information',
  payrollPayout = 'payout',
}

export const getPayrollTabs = (visibleTabs: any) => {
  const uniqueVisibleTabs: string[] = Array.from(new Set(visibleTabs));
  const allowedTabs = PayrollTabs.filter(el => uniqueVisibleTabs.includes(el));

  return allowedTabs?.map((tab: string) => {
    return {
      id: payrollTabs[tab as keyof typeof payrollTabs],
      name:
        payrollTabs[tab as keyof typeof payrollTabs] == 'payout'
          ? 'Payment'
          : capitalizeFirstLetter(payrollTabs[tab as keyof typeof payrollTabs]),
    };
  });
};

//  creditCheck tab visibility

export enum creditCheckTabs {
  newCredit = 'new_credit',
  mcCustomer = 'mc_and_customers',
}

export const getCreditCheckTabs = (visibleTabs: any) => {
  const uniqueVisibleTabs: string[] = Array.from(new Set(visibleTabs));
  const allowedTabs = CreditCheckTabs.filter(el => uniqueVisibleTabs.includes(el));
  return allowedTabs?.map((tab: string) => {
    return {
      id: creditCheckTabs[tab as keyof typeof creditCheckTabs],
      name: tab === 'mcCustomer' ? 'Payment Terms' : 'New Credit',
    };
  });
};

//  contact tab visibility
export enum contactsTabs {
  contact = 'contact',
}

export const getContactTabs = (visibleTabs: any) => {
  const uniqueVisibleTabs: string[] = Array.from(new Set(visibleTabs));
  const allowedTabs = ContactsTabs.filter(el => uniqueVisibleTabs.includes(el));

  return allowedTabs?.map((tab: string) => {
    return {
      id: contactsTabs[tab as keyof typeof contactsTabs],
      name: capitalizeFirstLetter(contactsTabs[tab as keyof typeof contactsTabs]),
    };
  });
};

//  factoring tab visibility
export enum factoringTabs {
  billing = 'billing',
  payable = 'payables',
  receivable = 'receivables',
  accountCollection = 'collection',
}

export const getFactoringTabs = (visibleTabs: any) => {
  const uniqueVisibleTabs: string[] = Array.from(new Set(visibleTabs));
  const allowedTabs = FactoringTabs.filter(el => uniqueVisibleTabs.includes(el));

  return allowedTabs?.map((tab: string) => {
    return {
      id: factoringTabs[tab as keyof typeof factoringTabs],
      name: capitalizeFirstLetter(factoringTabs[tab as keyof typeof factoringTabs]),
    };
  });
};

export enum truckBoardTabs {
  truckBoardCarrierRequest = 'carrier_request',
  truckBoard = 'truck_board',
  truckBoardCoordinator = 'verification_board',
  favoriteTrucks = 'favorite_trucks',
}

const allTabsReversed = {
  ...profileTabs,
  ...loadsTabs,
  ...payrollTabs,
  ...creditCheckTabs,
  ...contactsTabs,
  ...factoringTabs,
  ...truckBoardTabs,
  permission: 'permission',
};

export const allTabs = Object.fromEntries(Object.entries(allTabsReversed).map(el => el.reverse()));

export const getTruckBoardTabs = (visibleTabs: any) => {
  const uniqueVisibleTabs: string[] = Array.from(new Set(visibleTabs));
  const allowedTabs = TruckBoardTabs.filter(el => uniqueVisibleTabs.includes(el));

  return allowedTabs?.map((tab: string) => {
    return {
      id: truckBoardTabs[tab as keyof typeof truckBoardTabs],
      name: capitalizeFirstLetter(truckBoardTabs[tab as keyof typeof truckBoardTabs]),
    };
  });
};

export const PERMISSION_MAIN_TABS = [{ id: 'permission', name: 'Permission' }];

export const USER_SETTINGS_TABS = [{ id: 'user-settings', name: 'Settings' }];

export const LOCATION_DROPDOWN_DATA = [
  {
    title: 'Domestic',
    value: '1',
  },
  {
    title: 'International',
    value: '2',
  },
];

export const BILL_TYPE = [
  {
    title: 'Yes',
    value: '1',
  },
  {
    title: 'No',
    value: '2',
  },
];

export interface IFormatTextInput {
  value: string;
  setter: UseFormSetValue<any>;
  key: string;
}

export const dateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};
export const timeFormatOptions: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
};

export enum UserTypes {
  UNSPECIFIED = 0,
  ADMIN = 1, // main
  EMPLOYEE = 2,
  AGENT = 3,
}
export enum EmployeeLevels {
  UNSPECIFIED = 0,
  CORPORATE = 1, // main
  OFFICE = 2,
  BRANCH = 3,
}
export enum AgentTypes {
  UNSPECIFIED = 0,
  OWNER = 1,
  MANAGER = 2,
  AGENT = 3,
}

export enum AgentTypesText {
  'Owner' = 1,
  'Manager' = 2,
  'Agent' = 3,
}

export const notificationKey = `open_notification_${Date.now()}`;

export const MAX_ALLOWED_FILE_SIZE = 10000001;
