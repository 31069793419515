export const staticPermissions = {
  0: {
    profile: {
      agent: {
        view: true,
        download: true,
        actions: { create: true, edit: true, terminateOn: true, terminateOff: true },
      },
      branch: {
        view: true,
        download: true,
        actions: { create: true, edit: true, terminateOn: true, terminateOff: true },
      },
      office: {
        view: true,
        download: true,
        actions: { create: true, edit: true, terminateOn: true, terminateOff: true },
      },
      authority: {
        view: true,
        download: true,
        actions: { create: true, edit: true, terminateOn: true, terminateOff: true },
      },
      employee: {
        view: true,
        download: true,
        actions: { create: true, edit: true, terminateOn: true, terminateOff: true },
        visibleSubTabs: ['corporate', 'office', 'branch'],
      },
      visibleTabs: ['agent', 'branch', 'authority', 'office', 'carrier', 'customer', 'employee', 'factoringCompanies'],
      carrier: {
        carrierRequest: { view: true, download: true, actions: { approve: true, deny: true } },
        carrierOnBoard: { view: true, download: true, actions: { approve: true, deny: true } },
        carrierTable: {
          view: true,
          download: true,
          actions: { create: true, edit: true, terminateOn: true, terminateOff: true, deleteComment: true },
        },
        carriersBlackList: { view: true, download: true, actions: { lock: true, unlock: true } },
        visibleSubTabs: ['carrierTable', 'carriersBlackList', 'carrierRequest', 'carrierOnBoard'],
      },
      customer: {
        customersTable: {
          view: true,
          download: true,
          actions: { create: true, edit: true, terminateOn: true, terminateOff: true, deleteComment: true },
        },
        customersBlackList: {
          view: true,
          download: true,
          actions: { lock: true, unlock: true },
        },
        visibleSubTabs: ['customersTable', 'customersBlackList'],
      },
      factoringCompanies: {
        view: true,
        download: true,
        actions: { create: true, edit: true, terminateOn: true, terminateOff: true },
      },
    },
    loads: {
      load: {
        view: true,
        download: true,
        actions: {
          acceptToBilling: true,
          addPickUpDropOff: true,
          cancel: true,
          claim: true,
          deleteComment: true,
          editPickUpDropOff: true,
          editReferenceNumber: true,
          editSoldAsMc: true,
          filesDelete: true,
          reject: true,
          resolve: true,
          sendToBilling: true,
          transaction: true,
          transactionDelete: true,
          uploadsFiles: true,
        },
      },
      newLoad: { view: false, download: false },
      visibleTabs: ['load'],
    },
    payroll: {
      payrollAdvance: { view: true, download: true, actions: { create: true, edit: true, delete: true } },
      payrollCharge: { view: true, download: true, actions: { createDistribute: true, editDistribute: true } },
      payrollCollection: {
        view: true,
        download: true,
        actions: { givePaymentPlan: true, editPaymentPlan: true, deletePaymentPlan: true },
      },
      payrollCredit: { view: true, download: true, actions: { createDistribute: true, editDistribute: true } },
      payrollGrossProfit: { view: true, download: true },
      payrollLoad: { view: true, download: true },
      payrollLpf: { view: true },
      payrollOtherServiceFee: {
        view: true,
        download: true,
        actions: { create: true, edit: true, delete: true, turnOnCharge: true, turnOffCharge: true },
      },
      payrollPotentialGrossProfit: { view: true, download: true },
      payrollServiceFee: { view: true, download: true },
      payrollPayout: {
        view: true,
        download: true,
        actions: { payNegativeBalance: true, payPositiveBalance: true },
        visibleSubTabs: ['Agency', 'Branch', 'Agent'],
      },
      visibleTabs: ['payrollInformation', 'payrollPayout'],
      informationCards: [
        'payrollAdvance',
        'payrollCharge',
        'payrollCollection',
        'payrollCredit',
        'payrollGrossProfit',
        'payrollLoad',
        'payrollOtherServiceFee',
        'payrollPotentialGrossProfit',
        'payrollServiceFee',
        'payrollCreditCheck',
        'payrollSoftwareFee',
        'payrollLpf',
        'payrollFactoringFee',
      ],
    },
    'credit-check': {
      newCredit: {
        view: true,
        download: true,
        actions: { approve: true, deny: true, cod: true, consignment: true },
      },
      mcCustomer: { view: true, download: true },
      visibleTabs: ['newCredit', 'mcCustomer'],
    },
    contact: {
      contact: { view: true, download: true, actions: { create: true, edit: true, delete: true } },
      visibleTabs: ['contact'],
    },
    factoring: {
      billing: {
        view: true,
        download: true,
        actions: { acceptToBilling: true, reject: true, claimLoadAcceptToBilling: false, claimLoadReject: false },
      },
      receivable: {
        view: true,
        download: true,
        actions: { receive: true, claimLoadReceive: true, moveToCollection: true },
      },
      accountCollection: { view: true, download: true, actions: { receive: true, claimLoadReceive: true } },
      payable: { view: true, download: true, actions: { pay: true, claimLoadPay: true, receiveInvoice: true } },
      visibleTabs: ['billing', 'receivable', 'payable', 'accountCollection'],
    },
    'truck-board': {
      truckBoard: {
        view: true,
        download: true,
        actions: { createSearch: true },
      },
      truckBoardCarrierRequest: { view: false, download: false, actions: { carrierRequest: false } },
      truckBoardCoordinator: {
        view: true,
        download: true,
        actions: {
          addCoordinator: true,
          deleteCoordinator: true,
          updateSelfLocation: true,
          updateUnassignedLocation: true,
          updateOtherCoordinatorLocation: true,
        },
      },
      favorite: { view: false, download: false, actions: { favorite: false } },
      visibleTabs: ['truckBoardCoordinator', 'truckBoard'],
    },
  },
  1: {
    profile: {
      agent: {
        view: true,
        download: false,
        actions: { create: true, edit: true, terminateOn: true, terminateOff: true },
      },
      branch: {
        view: true,
        download: false,
        actions: { create: true, edit: true, terminateOn: true, terminateOff: true },
      },
      office: {
        view: false,
        download: false,
        actions: { create: false, edit: false, terminateOn: false, terminateOff: false },
      },
      authority: {
        view: true,
        download: false,
        actions: { create: false, edit: false, terminateOn: false, terminateOff: false },
      },
      employee: {
        view: true,
        download: false,
        actions: { create: true, edit: true, terminateOn: true, terminateOff: true },
        visibleSubTabs: ['office', 'branch'],
      },
      visibleTabs: ['agent', 'authority', 'employee', 'branch'],
      carrier: {
        carrierRequest: { view: false, download: false, actions: { approve: false, deny: false } },
        carrierOnBoard: { view: false, download: false, actions: { approve: false, deny: false } },
        carrierTable: {
          view: false,
          download: false,
          actions: { create: false, edit: false, terminateOn: false, terminateOff: false, deleteComment: false },
        },
        carriersBlackList: { view: false, download: false, actions: { lock: false, unlock: false } },
        visibleSubTabs: [],
      },
      customer: {
        customersTable: {
          view: false,
          download: false,
          actions: { create: false, edit: false, terminateOn: false, terminateOff: false, deleteComment: false },
        },
        customersBlackList: {
          view: false,
          download: false,
          actions: { lock: false, unlock: false },
        },
        factoringCompanies: {
          view: false,
          download: false,
          actions: { create: false, edit: false, terminateOn: false, terminateOff: false },
        },
        visibleSubTabs: [],
      },
    },
    loads: {
      load: {
        view: true,
        download: false,
        actions: {
          acceptToBilling: false,
          addPickUpDropOff: true,
          cancel: true,
          claim: true,
          deleteComment: false,
          editPickUpDropOff: true,
          editReferenceNumber: true,
          editSoldAsMc: true,
          filesDelete: true,
          reject: true,
          resolve: false,
          sendToBilling: true,
          transaction: true,
          transactionDelete: false,
          uploadsFiles: true,
        },
      },
      newLoad: { view: true, download: false },
      visibleTabs: ['load', 'newLoad'],
    },
    payroll: {
      payrollAdvance: { view: true, download: false, actions: { create: true, edit: true, delete: true } },
      payrollCharge: {
        view: true,
        download: false,
        actions: { createDistribute: true, editDistribute: true, movingToNextMonth: true, chargeToCollection: true },
      },
      payrollCollection: {
        view: true,
        download: false,
        actions: { givePaymentPlan: true, editPaymentPlan: true, deletePaymentPlan: true, pay: true },
      },
      payrollCredit: { view: true, download: false, actions: { createDistribute: true, editDistribute: true } },
      payrollGrossProfit: { view: true, download: false },
      payrollLoad: { view: true, download: false },
      payrollLpf: { view: true },
      payrollOtherServiceFee: {
        view: true,
        download: false,
        actions: { create: true, edit: true, delete: true, turnOnCharge: true, turnOffCharge: true },
      },
      payrollPotentialGrossProfit: { view: true, download: false },
      payrollServiceFee: { view: true, download: false },
      payrollPayout: {
        view: true,
        download: false,
        actions: { payNegativeBalance: true, payPositiveBalance: true },
        visibleSubTabs: ['Agency', 'Branch', 'Agent'],
      },
      visibleTabs: ['payrollInformation', 'payrollPayout'],
      informationCards: [
        'payrollAdvance',
        'payrollCharge',
        'payrollCollection',
        'payrollCredit',
        'payrollGrossProfit',
        'payrollLoad',
        'payrollOtherServiceFee',
        'payrollPotentialGrossProfit',
        'payrollServiceFee',
        'payrollCreditCheck',
        'payrollSoftwareFee',
        'payrollLpf',
        'payrollFactoringFee',
      ],
    },
    'credit-check': {
      newCredit: {
        view: false,
        download: false,
        actions: { approve: false, deny: false, cod: false, consignment: false },
      },
      mcCustomer: { view: false, download: false },
      visibleTabs: [],
    },
    contact: {
      contact: { view: true, download: false, actions: { create: false, edit: false, delete: false } },
      visibleTabs: ['contact'],
    },
    factoring: {
      billing: {
        view: false,
        download: false,
        actions: { acceptToBilling: false, reject: false, claimLoadAcceptToBilling: false, claimLoadReject: false },
      },
      receivable: {
        view: false,
        download: false,
        actions: { receive: false, claimLoadReceive: false, moveToCollection: false },
      },
      accountCollection: { view: false, download: false, actions: { receive: false, claimLoadReceive: false } },
      payable: { view: false, download: false, actions: { pay: false, claimLoadPay: false, receiveInvoice: false } },
      visibleTabs: [],
    },
    'truck-board': {
      truckBoard: {
        view: true,
        download: false,
        actions: { createSearch: true },
      },
      truckBoardCarrierRequest: { view: true, download: false, actions: { carrierRequest: true } },
      truckBoardCoordinator: {
        view: false,
        download: false,
        actions: {
          addCoordinator: false,
          deleteCoordinator: false,
          updateSelfLocation: false,
          updateUnassignedLocation: false,
          updateOtherCoordinatorLocation: false,
        },
      },
      favorite: { view: true, download: false, actions: { favorite: true } },
      visibleTabs: ['truckBoardCarrierRequest', 'favoriteTrucks', 'truckBoard'],
    },
  },
  2: {
    profile: {
      agent: {
        view: true,
        download: false,
        actions: { create: false, edit: true, terminateOn: true, terminateOff: true },
      },
      branch: {
        view: false,
        download: false,
        actions: { create: false, edit: false, terminateOn: false, terminateOff: false },
      },
      office: {
        view: false,
        download: false,
        actions: { create: false, edit: false, terminateOn: false, terminateOff: false },
      },
      authority: {
        view: true,
        download: false,
        actions: { create: false, edit: false, terminateOn: false, terminateOff: false },
      },
      employee: {
        view: true,
        download: false,
        actions: { create: true, edit: true, terminateOn: true, terminateOff: true },
        visibleSubTabs: ['branch'],
      },
      visibleTabs: ['agent', 'authority', 'employee'],
      carrier: {
        carrierRequest: { view: false, download: false, actions: { approve: false, deny: false } },
        carrierOnBoard: { view: false, download: false, actions: { approve: false, deny: false } },
        carrierTable: {
          view: false,
          download: false,
          actions: { create: false, edit: false, terminateOn: false, terminateOff: false, deleteComment: false },
        },
        carriersBlackList: { view: false, download: false, actions: { lock: false, unlock: false } },
        visibleSubTabs: [],
      },
      customer: {
        customersTable: {
          view: false,
          download: false,
          actions: { create: false, edit: false, terminateOn: false, terminateOff: false, deleteComment: false },
        },
        customersBlackList: {
          view: false,
          download: false,
          actions: { lock: false, unlock: false },
        },
        factoringCompanies: {
          view: false,
          download: false,
          actions: { create: false, edit: false, terminateOn: false, terminateOff: false },
        },
        visibleSubTabs: [],
      },
    },
    loads: {
      load: {
        view: true,
        download: false,
        actions: {
          acceptToBilling: false,
          addPickUpDropOff: true,
          cancel: true,
          claim: true,
          deleteComment: false,
          editPickUpDropOff: true,
          editReferenceNumber: true,
          editSoldAsMc: true,
          filesDelete: true,
          reject: true,
          resolve: false,
          sendToBilling: true,
          transaction: true,
          transactionDelete: false,
          uploadsFiles: true,
        },
      },
      newLoad: { view: true, download: false },
      visibleTabs: ['load', 'newLoad'],
    },
    payroll: {
      payrollAdvance: { view: true, download: false, actions: { create: true, edit: true, delete: true } },
      payrollCharge: { view: true, download: false, actions: { createDistribute: true, editDistribute: true } },
      payrollCollection: {
        view: true,
        download: false,
        actions: { givePaymentPlan: true, editPaymentPlan: true, deletePaymentPlan: true },
      },
      payrollCredit: { view: true, download: false, actions: { createDistribute: true, editDistribute: true } },
      payrollGrossProfit: { view: true, download: false },
      payrollLoad: { view: true, download: false },
      payrollLpf: { view: false },
      payrollOtherServiceFee: {
        view: true,
        download: false,
        actions: { create: true, edit: true, delete: true, turnOnCharge: true, turnOffCharge: true },
      },
      payrollPotentialGrossProfit: { view: true, download: false },
      payrollServiceFee: { view: false, download: false },
      payrollPayout: {
        view: true,
        download: false,
        actions: { payNegativeBalance: true, payPositiveBalance: true },
        visibleSubTabs: ['Branch', 'Agent'],
      },
      visibleTabs: ['payrollInformation', 'payrollPayout'],
      informationCards: [
        'payrollAdvance',
        'payrollCharge',
        'payrollCollection',
        'payrollCredit',
        'payrollGrossProfit',
        'payrollLoad',
        'payrollOtherServiceFee',
        'payrollPotentialGrossProfit',
        // 'payrollServiceFee',
        'payrollCreditCheck',
        'payrollSoftwareFee',
        'payrollLpf',
        'payrollFactoringFee',
      ],
    },
    'credit-check': {
      newCredit: {
        view: false,
        download: false,
        actions: { approve: false, deny: false, cod: false, consignment: false },
      },
      mcCustomer: { view: false, download: false },
      visibleTabs: [],
    },
    contact: {
      contact: { view: true, download: false, actions: { create: false, edit: false, delete: false } },
      visibleTabs: ['contact'],
    },
    factoring: {
      billing: {
        view: false,
        download: false,
        actions: { acceptToBilling: false, reject: false, claimLoadAcceptToBilling: false, claimLoadReject: false },
      },
      receivable: {
        view: false,
        download: false,
        actions: { receive: false, claimLoadReceive: false, moveToCollection: false },
      },
      accountCollection: { view: false, download: false, actions: { receive: false, claimLoadReceive: false } },
      payable: { view: false, download: false, actions: { pay: false, claimLoadPay: false, receiveInvoice: false } },
      visibleTabs: [],
    },
    'truck-board': {
      truckBoard: {
        view: true,
        download: false,
        actions: { createSearch: true },
      },
      truckBoardCarrierRequest: { view: true, download: false, actions: { carrierRequest: true } },
      truckBoardCoordinator: {
        view: false,
        download: false,
        actions: {
          addCoordinator: false,
          deleteCoordinator: false,
          updateSelfLocation: false,
          updateUnassignedLocation: false,
          updateOtherCoordinatorLocation: false,
        },
      },
      favorite: { view: true, download: false, actions: { favorite: true } },
      visibleTabs: ['truckBoardCarrierRequest', 'favoriteTrucks', 'truckBoard'],
    },
  },
  3: {
    profile: {
      agent: {
        view: false,
        download: false,
        actions: { create: false, edit: false, terminateOn: false, terminateOff: false },
      },
      branch: {
        view: false,
        download: false,
        actions: { create: false, edit: false, terminateOn: false, terminateOff: false },
      },
      office: {
        view: false,
        download: false,
        actions: { create: false, edit: false, terminateOn: false, terminateOff: false },
      },
      authority: {
        view: false,
        download: false,
        actions: { create: false, edit: false, terminateOn: false, terminateOff: false },
      },
      employee: {
        view: false,
        download: false,
        actions: { create: false, edit: false, terminateOn: false, terminateOff: false },
        visibleSubTabs: [],
      },
      visibleTabs: [],
      carrier: {
        carrierRequest: { view: false, download: false, actions: { approve: false, deny: false } },
        carrierOnBoard: { view: false, download: false, actions: { approve: false, deny: false } },
        carrierTable: {
          view: false,
          download: false,
          actions: { create: false, edit: false, terminateOn: false, terminateOff: false, deleteComment: false },
        },
        carriersBlackList: { view: false, download: false, actions: { lock: false, unlock: false } },
        visibleSubTabs: [],
      },
      customer: {
        customersTable: {
          view: false,
          download: false,
          actions: { create: false, edit: false, terminateOn: false, terminateOff: false, deleteComment: false },
        },
        customersBlackList: {
          view: false,
          download: false,
          actions: { lock: false, unlock: false },
        },
        factoringCompanies: {
          view: false,
          download: false,
          actions: { create: false, edit: false, terminateOn: false, terminateOff: false },
        },
        visibleSubTabs: [],
      },
    },
    loads: {
      load: {
        view: true,
        download: false,
        actions: {
          acceptToBilling: false,
          addPickUpDropOff: true,
          cancel: true,
          claim: true,
          deleteComment: false,
          editPickUpDropOff: true,
          editReferenceNumber: true,
          editSoldAsMc: true,
          filesDelete: true,
          reject: true,
          resolve: false,
          sendToBilling: true,
          transaction: true,
          transactionDelete: false,
          uploadsFiles: true,
        },
      },
      newLoad: { view: true, download: false },
      visibleTabs: ['load', 'newLoad'],
    },
    payroll: {
      payrollAdvance: { view: true, download: false, actions: { create: false, edit: false, delete: false } },
      payrollCharge: { view: true, download: false, actions: { createDistribute: false, editDistribute: false } },
      payrollCollection: {
        view: true,
        download: false,
        actions: { givePaymentPlan: false, editPaymentPlan: false, deletePaymentPlan: false },
      },
      payrollCredit: { view: true, download: false, actions: { createDistribute: false, editDistribute: false } },
      payrollGrossProfit: { view: true, download: false },
      payrollLoad: { view: true, download: false },
      payrollLpf: { view: false },
      payrollOtherServiceFee: {
        view: true,
        download: false,
        actions: { create: false, edit: false, delete: false, turnOnCharge: false, turnOffCharge: false },
      },
      payrollPotentialGrossProfit: { view: true, download: false },
      payrollServiceFee: { view: false, download: false },
      payrollPayout: {
        view: true,
        download: false,
        actions: { payNegativeBalance: false, payPositiveBalance: false },
        visibleSubTabs: ['Agent'],
      },
      visibleTabs: ['payrollInformation', 'payrollPayout'],
      informationCards: [
        'payrollAdvance',
        'payrollCharge',
        'payrollCollection',
        'payrollCredit',
        'payrollGrossProfit',
        'payrollLoad',
        'payrollOtherServiceFee',
        'payrollPotentialGrossProfit',
        // 'payrollServiceFee',
        'payrollCreditCheck',
        'payrollSoftwareFee',
        // 'payrollLpf',
        'payrollFactoringFee',
      ],
    },
    'credit-check': {
      newCredit: {
        view: false,
        download: false,
        actions: { approve: false, deny: false, cod: false, consignment: false },
      },
      mcCustomer: { view: false, download: false },
      visibleTabs: [],
    },
    contact: {
      contact: { view: true, download: false, actions: { create: false, edit: false, delete: false } },
      visibleTabs: ['contact'],
    },
    factoring: {
      billing: {
        view: false,
        download: false,
        actions: { acceptToBilling: false, reject: false, claimLoadAcceptToBilling: false, claimLoadReject: false },
      },
      receivable: {
        view: false,
        download: false,
        actions: { receive: false, claimLoadReceive: false, moveToCollection: false },
      },
      accountCollection: { view: false, download: false, actions: { receive: false, claimLoadReceive: false } },
      payable: { view: false, download: false, actions: { pay: false, claimLoadPay: false, receiveInvoice: false } },
      visibleTabs: [],
    },
    'truck-board': {
      truckBoard: {
        view: true,
        download: false,
        actions: { createSearch: true },
      },
      truckBoardCarrierRequest: { view: true, download: false, actions: { carrierRequest: true } },
      truckBoardCoordinator: {
        view: false,
        download: false,
        actions: {
          addCoordinator: false,
          deleteCoordinator: false,
          updateSelfLocation: false,
          updateUnassignedLocation: false,
          updateOtherCoordinatorLocation: false,
        },
      },
      favorite: { view: true, download: false, actions: { favorite: true } },
      visibleTabs: ['favoriteTrucks', 'truckBoardCarrierRequest', 'truckBoard'],
    },
  },
};

export const employeeLevelSubTabs = ['corporate', 'office', 'branch'];
export const paymentLevelSubTabs = ['Agency', 'Branch', 'Agent'];
