import { IAuthoritiesFormData } from 'pages/profile/tabs/authorities/constants/types';
import { handleFloatedBrackeds } from 'utils/helpers';

import { AuthorityTypes } from './interfaces';

export const createAutorityDeserializer = (body: IAuthoritiesFormData) => {
  return {
    address: body.address,
    ...(body?.secondAddress ? { secondAddress: body?.secondAddress } : {}),
    city: body.city,
    fax: body.fax ?? '',
    mc: String(body.mc),
    ...(body?.dot ? { dot: body?.dot } : {}),
    ...(body?.ffNumber ? { ff: body?.ffNumber } : {}),
    ...(body?.stateNumber ? { stateNumber: body?.stateNumber } : {}),
    name: body.name,
    phone: body.phone,
    stateId: Number(body.stateId),
    type: Number(body.authority_type),
    email: body?.email,
    ...(Number(body.authority_type) === AuthorityTypes.Broker ? { rateConfirmationDetails: body?.privacyPolicy } : {}),
    ...(Number(body.authority_type) === AuthorityTypes.Broker ? { templateId: Number(body?.templateId) } : {}),
    zip: body.zip,
    fileIds: body.files?.map(el => Number(el)),
    packetFileIds: body?.packet?.map(el => Number(el)),
    ...(Number(body.authority_type) === AuthorityTypes.Broker
      ? { brokerSignAgreementFiles: body?.broker?.map(el => Number(el)) }
      : {}),
    bankName: body.bankName,
    accountingNumber: body.accounting_number,
    achRoutingNumber: body.ach_routing_number,
    physical: body?.physical ? 1 : 2,
    ...(body?.physical ? { physicalAddress: body?.physicalAddress } : {}),
    ...(body?.physical ? { physicalZip: body?.physicalZip } : {}),
    ...(body?.physical ? { physicalStateId: Number(body?.physicalStateId) } : {}),
    ...(body?.physical ? { physicalCity: body?.physicalCity } : {}),
    ...(body?.physical && body?.physicalSecondAddress ? { physicalSecondAddress: body?.physicalSecondAddress } : {}),
    ...(body?.factoring
      ? {
          brackets: handleFloatedBrackeds(body.brackets)?.map(item => {
            return {
              to: Number(item.to),
              from: Number(item.from),
              percent: Number(item.percent),
            };
          }),
        }
      : {}),
    ...(body?.factoringCompanyId ? { factoringCompanyId: body?.factoringCompanyId[0]?.id } : {}),
    ...(body?.lpf ? { lpf: Number(body?.lpf) } : {}),
    ...(body?.aging030 || body.aging3160 || body.aging6190
      ? {
          agings: [
            ...(body?.aging030
              ? [
                  {
                    from: 0,
                    to: 30,
                    percent: Number(body?.aging030) || null,
                  },
                ]
              : []),
            ...(body?.aging3160
              ? [
                  {
                    from: 31,
                    to: 60,
                    percent: Number(body?.aging3160) || null,
                  },
                ]
              : []),
            ...(body?.aging6190
              ? [
                  {
                    from: 61,
                    to: 90,
                    percent: Number(body?.aging6190) || null,
                  },
                ]
              : []),
          ],
        }
      : {}),
  };
};

export const updateAutorityDeserializer = (body: IAuthoritiesFormData) => {
  return {
    address: body.address,
    ...(body?.secondAddress ? { secondAddress: body?.secondAddress } : {}),
    city: body.city,
    fax: body?.fax,
    mc: String(body.mc),
    ...(body?.dot ? { dot: body?.dot } : {}),
    ...(body?.ffNumber ? { ff: body?.ffNumber } : {}),
    ...(body?.stateNumber ? { stateNumber: body?.stateNumber } : {}),
    name: body.name,
    phone: body.phone,
    stateId: Number(body.stateId),
    type: Number(body.authority_type),
    zip: body.zip,
    bankName: body.bankName,
    accountingNumber: body.accounting_number,
    achRoutingNumber: body.ach_routing_number,
    email: body?.email,
    ...(Number(body.authority_type) === AuthorityTypes.Broker ? { rateConfirmationDetails: body?.privacyPolicy } : {}),
    ...(Number(body.authority_type) === AuthorityTypes.Broker ? { templateId: Number(body?.templateId) } : {}),

    physical: body?.physical ? 1 : 2,
    ...(body?.physical ? { physicalAddress: body?.physicalAddress } : {}),
    ...(body?.physical ? { physicalZip: body?.physicalZip } : {}),
    ...(body?.physical ? { physicalStateId: Number(body?.physicalStateId) } : {}),
    ...(body?.physical ? { physicalCity: body?.physicalCity } : {}),
    ...(body?.physical && body?.physicalSecondAddress ? { physicalSecondAddress: body?.physicalSecondAddress } : {}),
    ...(Number(body?.factoring) === 1
      ? {
          brackets: handleFloatedBrackeds(body.brackets)?.map(item => {
            return {
              to: Number(item.to),
              from: Number(item.from),
              percent: Number(item.percent),
            };
          }),
        }
      : {}),
    ...(Number(body?.factoring) === 1 ? { factoringCompanyId: body?.factoringCompanyId[0]?.id } : {}),
    ...(Number(body?.factoring) === 1 && body?.lpf ? { lpf: Number(body?.lpf) } : {}),
    ...(Number(body?.factoring) === 1 && (body?.aging030 || body.aging3160 || body.aging6190)
      ? {
          agings: [
            ...(body?.aging030
              ? [
                  {
                    from: 0,
                    to: 30,
                    percent: Number(body?.aging030) || null,
                  },
                ]
              : []),
            ...(body?.aging3160
              ? [
                  {
                    from: 31,
                    to: 60,
                    percent: Number(body?.aging3160) || null,
                  },
                ]
              : []),
            ...(body?.aging6190
              ? [
                  {
                    from: 61,
                    to: 90,
                    percent: Number(body?.aging6190) || null,
                  },
                ]
              : []),
          ],
        }
      : {}),
  };
};
