import { useSelector } from 'react-redux';

import { selectUserPermissionsInfo } from '../store/user-slice/selector';

export const usePermittedActions = (string: any) => {
  const {
    permissionsInfo: { permissions },
  } = useSelector(selectUserPermissionsInfo);
  const path = string.split('.');
  const userPermissions: any = {
    profiles: {
      agents: {
        ...permissions?.profile?.agent?.actions,
        download: permissions?.profile?.agent?.download,
        view: permissions?.profile?.agent?.view,
      },
      agencies: {
        ...permissions?.profile?.office?.actions,
        download: permissions?.profile?.office?.download,
        view: permissions?.profile?.office?.view,
      },
      branches: {
        ...permissions?.profile?.branch?.actions,
        download: permissions?.profile?.branch?.download,
        view: permissions?.profile?.branch?.view,
      },
      authorities: {
        ...permissions?.profile?.authority?.actions,
        download: permissions?.profile?.authority?.download,
        view: permissions?.profile?.authority?.view,
      },
      carriers: {
        carrierTable: {
          ...permissions?.profile?.carrier?.carrierTable?.actions,
          download: permissions?.profile?.carrier?.carrierTable?.download,
          view: permissions?.profile?.carrier?.carrierTable?.view,
        },
        carrierRequest: {
          ...permissions?.profile?.carrier?.carrierRequest?.actions,
          download: permissions?.profile?.carrier?.carrierRequest?.download,
          view: permissions?.profile?.carrier?.carrierRequest?.view,
        },
        carrierOnBoard: {
          ...permissions?.profile?.carrier?.carrierOnBoard?.actions,
          download: permissions?.profile?.carrier?.carrierOnBoard?.download,
          view: permissions?.profile?.carrier?.carrierOnBoard?.view,
        },
        blacklist: {
          ...permissions?.profile?.carrier?.carriersBlackList?.actions,
          download: permissions?.profile?.carrier?.carriersBlackList?.download,
          view: permissions?.profile?.carrier?.carriersBlackList?.view,
        },
      },
      customers: {
        customerTable: {
          ...permissions?.profile?.customer?.customersTable?.actions,
          download: permissions?.profile?.customer?.customersTable?.download,
          view: permissions?.profile?.customer?.customersTable?.view,
        },
        blackList: {
          ...permissions?.profile?.customer?.customersBlackList?.actions,
          download: permissions?.profile?.customer?.customersBlackList?.download,
          view: permissions?.profile?.customer?.customersBlackList?.view,
        },
      },
      employees: {
        ...permissions?.profile?.employee?.actions,
        download: permissions?.profile?.employee?.download,
        view: permissions?.profile?.employee?.view,
      },
      factoringCompanies: {
        ...permissions?.profile?.factoringCompanies?.actions,
        download: permissions?.profile?.factoringCompanies?.download,
        view: permissions?.profile?.factoringCompanies?.view,
      },
    },
    loads: {
      loads: {
        ...permissions?.loads?.load?.actions,
        download: permissions?.loads?.load?.download,
        view: permissions?.loads?.load?.view,
      },
      newLoad: {
        ...permissions?.loads?.newLoad?.actions,
        download: permissions?.loads?.newLoad?.download,
        view: permissions?.loads?.newLoad?.view,
      },
    },
    payroll: {
      payrollAdvance: {
        ...permissions?.payroll?.payrollAdvance?.actions,
        download: permissions?.payroll?.payrollAdvance?.download,
        view: permissions?.payroll?.payrollAdvance?.view,
      },
      payrollCharge: {
        ...permissions?.payroll?.payrollCharge?.actions,
        download: permissions?.payroll?.payrollCharge?.download,
        view: permissions?.payroll?.payrollCharge?.view,
      },
      payrollCollection: {
        ...permissions?.payroll?.payrollCollection?.actions,
        download: permissions?.payroll?.payrollCollection?.download,
        view: permissions?.payroll?.payrollCollection?.view,
      },
      payrollCredit: {
        ...permissions?.payroll?.payrollCredit?.actions,
        download: permissions?.payroll?.payrollCredit?.download,
        view: permissions?.payroll?.payrollCredit?.view,
      },
      payrollGrossProfit: {
        ...permissions?.payroll?.payrollGrossProfit?.actions,
        download: permissions?.payroll?.payrollGrossProfit?.download,
        view: permissions?.payroll?.payrollGrossProfit?.view,
      },
      payrollLoad: {
        ...permissions?.payroll?.payrollLoad?.actions,
        download: permissions?.payroll?.payrollLoad?.download,
        view: permissions?.payroll?.payrollLoad?.view,
      },
      payrollLpf: {
        ...permissions?.payroll?.payrollLpf?.actions,
        download: permissions?.payroll?.payrollLpf?.download,
        view: permissions?.payroll?.payrollLpf?.view,
      },
      payrollSoftwareFee: {
        ...permissions?.payroll?.payrollSoftwareFee?.actions,
        download: permissions?.payroll?.payrollSoftwareFee?.download,
        view: permissions?.payroll?.payrollSoftwareFee?.view,
      },
      payrollFactoringFee: {
        ...permissions?.payroll?.payrollFactoringFee?.actions,
        download: permissions?.payroll?.payrollFactoringFee?.download,
        view: permissions?.payroll?.payrollFactoringFee?.view,
      },
      payrollOtherServiceFee: {
        ...permissions?.payroll?.payrollOtherServiceFee?.actions,
        download: permissions?.payroll?.payrollOtherServiceFee?.download,
        view: permissions?.payroll?.payrollOtherServiceFee?.view,
      },
      payrollPotentialGrossProfit: {
        ...permissions?.payroll?.payrollPotentialGrossProfit?.actions,
        download: permissions?.payroll?.payrollPotentialGrossProfit?.download,
        view: permissions?.payroll?.payrollPotentialGrossProfit?.view,
      },
      payrollServiceFee: {
        ...permissions?.payroll?.payrollServiceFee?.actions,
        download: permissions?.payroll?.payrollServiceFee?.download,
        view: permissions?.payroll?.payrollServiceFee?.view,
      },
      payment: {
        ...permissions?.payroll?.payrollPayout?.actions,
        download: permissions?.payroll?.payrollPayout?.download,
        view: permissions?.payroll?.payrollPayout?.view,
        visibleSubTabs:
          permissions?.payroll?.payment?.visibleSubTabs || permissions?.payroll?.payrollPayout?.visibleSubTabs,
      },
    },
    creditCheck: {
      newCredit: {
        ...permissions?.['credit-check']?.newCredit?.actions,
        download: permissions?.['credit-check']?.newCredit?.download,
        view: permissions?.['credit-check']?.newCredit?.view,
      },
      mcAndCustomers: {
        ...permissions?.['credit-check']?.mcCustomer?.actions,
        download: permissions?.['credit-check']?.mcCustomer?.download,
        view: permissions?.['credit-check']?.mcCustomer?.view,
      },
    },
    contact: {
      contact: {
        ...permissions?.contact?.contact?.actions,
        download: permissions?.contact?.contact?.download,
        view: permissions?.contact?.contact?.view,
      },
    },
    factoring: {
      billing: {
        ...permissions?.factoring?.billing?.actions,
        download: permissions?.factoring?.billing?.download,
        view: permissions?.factoring?.billing?.view,
      },
      payables: {
        ...permissions?.factoring?.payable?.actions,
        download: permissions?.factoring?.payable?.download,
        view: permissions?.factoring?.payable?.view,
      },
      receivables: {
        ...permissions?.factoring?.receivable?.actions,
        download: permissions?.factoring?.receivable?.download,
        view: permissions?.factoring?.receivable?.view,
      },
      accountCollection: {
        ...permissions?.factoring?.accountCollection?.actions,
        download: permissions?.factoring?.accountCollection?.download,
        view: permissions?.factoring?.accountCollection?.view,
      },
    },
    truckBoard: {
      truckBoard: {
        ...permissions?.['truck-board']?.truckBoard?.actions,
        download: permissions?.['truck-board']?.truckBoard?.download,
        view: permissions?.['truck-board']?.truckBoard?.view,
      },
      carrierRequest: {
        ...permissions?.['truck-board']?.truckBoardCarrierRequest?.actions,
        download: permissions?.['truck-board']?.truckBoardCarrierRequest?.download,
        view: permissions?.['truck-board']?.truckBoardCarrierRequest?.view,
      },
      verification: {
        ...permissions?.['truck-board']?.truckBoardCoordinator?.actions,
        download: permissions?.['truck-board']?.truckBoardCoordinator?.download,
        view: permissions?.['truck-board']?.truckBoardCoordinator?.view,
      },
      favorite: {
        ...permissions?.['truck-board']?.favorite?.actions,
        download: permissions?.['truck-board']?.favorite?.download,
        view: permissions?.['truck-board']?.favorite?.view,
      },
    },
  };
  const allPossiblePaths: any = {
    1: userPermissions?.[string],
    2: userPermissions[path[0]]?.[path[1]],
    3: userPermissions[path[0]]?.[path[1]]?.[path[2]],
  };

  return allPossiblePaths[`${path?.length || 1}`];
};
