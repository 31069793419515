export const getAuthoritiesListSerializer = (body: any): any => {
  const result = body?.result?.map((authority: any) => {
    return {
      ...authority,
      state: authority?.state?.name,
      ffNumber: authority?.ff,
      factoringCompanyName: authority.factoringCompanyName || 'No',
    };
  });
  return {
    result,
    count: body?.count,
    used: body?.used,
    notUsed: body?.notUsed,
  };
};

export const getTemplatesSerializer = (body: any) => {
  const result = body.result.map((item: any) => {
    return {
      value: item?.id,
      title: item?.name,
      url: item?.url,
    };
  });
  return {
    result,
    count: result.length,
  };
};

export const getAuthoritiesShortSerializer = (queries: any) => {
  const {
    search,
    skip,
    limit,
    order,
    orderBy,
    branchId,
    officeId,
    ignoreAuthorityIds,
    ignoreCustomerId,
    ignoreOfficeId,
    ignoreBranchId,
    ignoreAgentId,
    onlyAssigned,
  } = queries;
  const searchParams = new URLSearchParams();
  search && searchParams.append('search', search);
  searchParams.append('skip', skip);
  searchParams.append('limit', limit);
  order && searchParams.append('order', order);
  orderBy && searchParams.append('orderBy', orderBy);
  onlyAssigned && searchParams.append('onlyAssigned', onlyAssigned);
  officeId && searchParams.append('officeId', String(officeId));
  branchId && searchParams.append('branchId', String(branchId));
  ignoreOfficeId && searchParams.append('ignoreOfficeId', ignoreOfficeId);
  ignoreBranchId && searchParams.append('ignoreBranchId', ignoreBranchId);
  ignoreCustomerId && searchParams.append('ignoreCustomerId', ignoreCustomerId);
  ignoreAgentId && searchParams.append('ignoreAgentId', ignoreAgentId);

  ignoreAuthorityIds?.length &&
    ignoreAuthorityIds?.forEach((el: any) => searchParams.append('ignoreAuthorityIds[]', el));
  return searchParams;
};
