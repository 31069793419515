import { IBracket } from 'services/profile/agents/interfaces';
import { withController } from 'ui/inputs/hoc/Controller';
import { IInputProps } from 'ui/inputs/input/constants/types';
import CustomInput from 'ui/inputs/input/Input';
import { ISearchableSelectProps } from 'ui/inputs/searchable-select/constants/types';
import CustomSearchableSelect from 'ui/inputs/searchable-select/SearchableSelect';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import CustomSelect from 'ui/inputs/select/Select';
import { ISwitchProps } from 'ui/inputs/switch/constants/types';
import CustomSwitch from 'ui/inputs/switch/Switch';
import { ITextAreaProps } from 'ui/inputs/textarea/constants/types';
import CustomTextArea from 'ui/inputs/textarea/Textarea';
import { ISearchableDropdownProps } from 'ui/searchable-dropdown/constants/types';
import CustomSearchableDropdown from 'ui/searchable-dropdown/SearchableDropdown';

import { IAuthoritiesFilter, IAuthoritiesFormData } from '../constants/types';

export const Input = withController<IInputProps, IAuthoritiesFormData>(CustomInput);
export const Switch = withController<ISwitchProps, IAuthoritiesFormData>(CustomSwitch);

export const FiltersSelect = withController<ISelectProps, IAuthoritiesFilter>(CustomSelect);
export const SearchableSelect = withController<ISearchableSelectProps, IAuthoritiesFilter>(CustomSearchableSelect);

export const TextArea = withController<ITextAreaProps, any>(CustomTextArea);
export const SearchableDropdown = withController<ISearchableDropdownProps, any>(CustomSearchableDropdown);
export const BracketInput = withController<IInputProps, IBracket>(CustomInput);
