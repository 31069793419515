import { IAuthoritiesFormData, ISelectOptions } from './types';

export const defaultValues: IAuthoritiesFormData = {
  name: '',
  authority_type: '',
  dot: null,
  ffNumber: null,
  stateNumber: null,
  bankName: '',
  accounting_number: '',
  ach_routing_number: '',
  mc: null,
  phone: null,
  fax: null,
  email: '',
  address: '',
  secondAddress: '',
  city: '',
  stateId: '',
  zip: null,
  files: [],
  packet: [],
  broker: [],
  physical: false,
  physicalAddress: '',
  physicalSecondAddress: '',
  physicalCity: '',
  physicalStateId: '',
  physicalZip: null,
  templateId: '',
  privacyPolicy: '',

  brackets: [],
  factoring: null,
  factoringCompanyId: [],
  aging030: null,
  aging3160: null,
  aging6190: null,
  lpf: null,
};

export const defaultBracketValues = {
  percent: null,
  from: 0,
  to: null,
};

export const filterDefaultValues = {
  officeId: '',
  status: '',
  type: null,
  used: '',
};

export const SEARCH_FILTER_OPTIONS: ISelectOptions[] = [
  { title: 'Used', value: 'Used' },
  { title: 'Not used', value: 'Not used' },
];

export const AUTHORITIES_TYPE_OPTIONS: ISelectOptions[] = [
  { title: 'Broker Authority', value: '2' },
  { title: 'Carrier Authority', value: '1' },
];

export const FACTORING_TYPE_OPTIONS: ISelectOptions[] = [
  { title: 'Yes', value: '1' },
  { title: 'No', value: '2' },
];

export const SEARCH_FACTORING_COMPANIES_COLUMNS = [
  { key: 'companyName', name: 'Company Name' },
  { key: 'companyId', name: 'ID' },
];

export const FACTORING_COMPANY_KEYS = ['id', 'companyName', 'companyId'];

export enum SUBJECT {
  ALREADY_DELETED = 'You Can Only View Or Download File As It Was Already Deleted',
  DISABLED_DELETE_FIRST = 'Sorry, You Are Not Allowed To Delete Last File',
  PROCESSING_RULE = 'In This Load Status, Action Is Not Allowed',
  SEARCH_SELECT = 'Search Select',
  STATUS = 'Status',
  NOT_SELECTED = 'Not Selected',
  RESET_ALL = 'Reset All',
  AUTHORITIES_LIST_IS_EMPTY = 'Authorities List Is Empty',
  ALL_OFFICES = 'All Agencies',
  OFFICES = 'Agency',
  ADD_AUTHORITIES = 'Add New Authorities',
  EDIT_AUTHORITIES = 'Edit Authorities',
  AUTHORITIES_INFO = 'Authorities Info',
  AUTHORITY_NAME = 'Name',
  AUTHORITIES_TYPE = 'Authorities Type',
  MC_NUMBER = 'MC Number',
  PHONE_NUMBER = 'Phone Number',
  FAX = 'Fax Number',
  FF_NUMBER = 'FF Number',
  ADDRESS = 'Address',
  STATE_NUMBER = 'State Number',
  SECOND_ADDRESS = 'Second Address',
  CITY = 'City',
  MAIL_CITY = 'Mail City',
  MAIL_ZIP = 'Mail ZIP Code',
  MAIL_STATE = 'Mail State',
  MAIL_ADDRESS = 'Mail Address',
  EMAIL = 'E-Mail',
  TEMPLATE = 'Template',
  MAIL_SECOND_ADDRESS = 'Mail Second Adress',

  PHYSICAL_CITY = 'Physical City',
  PHYSICAL_ZIP = 'Physical ZIP Code',
  PHYSICAL_STATE = 'Physical State',
  PHYSICAL_ADDRESS_VIEW = 'Physical Address',
  PHYSICAL_SECOND_ADDRESS = 'Physical Second Address',
  STATE = 'State',
  ZIP_CODE = 'Zip Code',
  UPLOAD_NOA = 'Upload NOA',
  UPLOAD_PACKAGE = 'Upload Authority Package',
  UPLOAD_TEXT = 'Drag And Drop Or',
  ALLOWED_FORMAT = 'Allowed Format (jpeg, pdf, tiff, png)',
  ALLOWED_FORMAT_PDF = 'Allowed Format (pdf)',
  BTN_TEXT = 'Add Authority',
  EDIT_BTN_TEXT = 'Save Changes',
  UNSAVED_CHANGES = 'You Have Unsaved Changes',
  CLOSE = 'Close',
  SAVE = 'Save',
  EDIT = 'Edit',
  DATE = 'Date',
  MC = 'MC',
  DOT = 'DOT',
  AUTHORITIES_ID = 'Authorities ID',
  NOA = 'NOA',
  AUTHORITY_PACKAGE = 'Authority Package',
  TERMINATE = 'Terminate',
  NAME_COLON = 'Name:',
  MC_NUMBER_COLON = 'MC Number:',
  AUTHORITIES_ID_COLON = 'Authorities ID:',
  TYPES_COLON = 'Type:',
  PHONE_NUMBER_COLON = 'Phone Number:',
  FAX_COLON = 'Fax:',
  FILE_NAME = 'filename_red confirmation_45454121M-454.jpg',
  UPLOADER_NAME = 'Added by Miles Frost',
  UPLOADED_DATE = '12-30-2021, 07:45 PM',
  PHYSICAL_ADDRESS = 'Add Physical Address',
  TOTAL_MC = 'Total MC',
  ALL = 'All',
  USED = 'Used',
  NOT_USED = 'Unassigned',
  NO = 'No',
  YES = 'Yes',
  CHECKED_ACTIVATE = 'If  Activated Authority Can Be Used Again',
  CHECKED_DEACTIVATE = 'If Terminated Authority  Can’t be  Used Anymore',
  STAY_HERE = 'Stay Here',
  ADDED_BY = 'Added By',
  ARE_YOU_SURE = 'Are You Sure Want To Delete?',
  DELETE = 'Delete',
  LAST_ITEM = 'Sorry, You Are Not Allowed To Delete The Last File',
  BANK_NAME = 'Bank Name',
  ACCOUNTING_NUMBER = 'Accounting Number',
  ACH_ROUTING_NUMBER = 'ACH Routing Number',
  SOMETHING_WENT_WRONG = 'Something Went Wrong',
  SUCCESSFULLY_ACTIVATED = 'Successfully Activated',
  SUCCESSFULLY_DEACTIVATED = 'Successfully Deactivated',
  BANK_INFORMATION = 'Bank Information',
  FULL_INFO = 'Full Info',
  RATE_CONFIRMATION_TEMPLATE = 'Rate Confirmation Template',
  RATE_CONFIRMATION_PRIVACY_POLICY = 'Rate Confirmation Privacy Policy',
  WRITE_TEXT = 'Write text',
  BROKER_AGREEMENT_FILES = 'Broker Agreements',
}

export enum VALIDATION_MESSAGES {
  MIN_OPTION = 'At least one option is required',
  POSITIVE = 'Please enter a positive number',
  INTEGER = 'Please enter an integer',
  MIN_NUMBER = 'Number must be greater than or equal to 0',
  VALID_NUMBER = 'Please enter a valid number',
  PHONE = 'Incorrect phone format',
  INCORRECT_FAX = 'Incorrect fax format',
  MAX_LENGTH = 'Maximum of 10 digits allowed',
  NAME_REQUIRED = 'Name is a required field',
  AUTHORITY_TYPE_REQUIRED = 'Authority type is a required field',
  MC_NUMBER_REQUIRED = 'MC number is a required field',
  PHONE_NUMBER_REQUIRED = 'Phone number is a required field',
  ADDRESS_REQUIRED = 'Address is a required field',
  CITY_REQUIRED = 'City is a required field',
  STATE_ID_REQUIRED = 'State ud is a required field',
  ZIP_REQUIRED = 'Zip Code is a required field',
  INVALID_EMAIL = 'Invalid email format',
  EMAIL_REQUIRED = 'Email is a required field',
  DOT_REQUIRED = 'DOT is required',
  FILE_REQUIRED = 'NOA file is a required',
  BROKER_FILE_REQUIRED = 'Broker sign agreement file is a required',
  PACKAGE_REQUIRED = 'Authority package is a required',
  ADDRESS_MAX_LENGTH = 'The address cannot be more than 300 characters long',
  MAX_MIN_ZIP_LENGTH = 'Must be 5 digits',
  TEMPLATE_REQUIRED = 'Template is a required field',
  RATE_CONFIRMATION_PRIVACY_POLICY_REQUIRED = 'Rate confirmation privacy policy is a required field',
}

export enum AuthorityStatusTypes {
  AUTHORITY_STATUS_TYPES_UNSPECIFIED = 0,
  AUTHORITY_STATUS_TYPES_ACTIVE = 1,
  AUTHORITY_STATUS_TYPES_INACTIVE = 2,
}

export const AuthorityTypes: any = {
  1: 'Carrier',
  2: 'Broker',
};
