import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import { useHandleCopyActive } from 'hooks/useCopy';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { formatPhone } from 'utils/helpers';

import CopyIcon from 'components/svgs/CopyIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { setIsValid } from '../../../../../../store/check-is-dirty-slice/isValid';
import { AuthorityTypes, SUBJECT } from '../../constants/constants';
import CreateAuthority from '../create-authority/CreateAuthority';

import { IEditAuthorityProps } from './constants/types';

import styles from './EditAuthority.module.scss';

const EditAuthority = ({
  handleSubmit,
  onSubmit,
  control,
  trigger,
  getValues,
  errors,
  mode,
  data,
  watch,
  statesData,
  beforeUpload,
  allFiles,
  authorityId,
  handleDeleteAuthorityFile,
  handleDownloadAuthorityFile,
  handleViewAuthorityFileEdit,
  handleDownloadNewFile,
  handleViewNewFile,
  setValue,
  isFetchingById,
  isEditLoading,
  templates,
  isCreateLoading,
  factoringCompaniesAutocomplete,
  setFactoringCompaniesAutocompleteFilter,
  brackets,
  bracketErrors,
  getBracketValues,
  setBracketsValue,
  bracketControl,
  handleBracketSubmit,
  onBracketSubmit,
  isBracketValid,
  handleDeleteBracket,
  resetField,
}: IEditAuthorityProps) => {
  const dispatch = useDispatch();
  const { copiedText, handleCopy } = useHandleCopyActive();

  useEffect(() => {
    dispatch(setIsValid({ isValid: !isEditLoading }));
  }, [isEditLoading]);

  return (
    <div onSubmit={handleSubmit(onSubmit)} className={styles.editAuthoritiesContainer}>
      <MainLoader isFetching={isFetchingById} />

      <ViewItemWrapper title={SUBJECT.AUTHORITIES_INFO}>
        <Row gutter={[20, 14]}>
          <Col span={8}>
            <div className={styles.infoColumns}>
              <span className="top-banner-title">{SUBJECT.NAME_COLON}</span>{' '}
              <TooltipText
                prefixClass="top-banner-value"
                data={data?.result?.name}
                subject={`${SUBJECT.AUTHORITIES_INFO}${SUBJECT.NAME_COLON}`}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.infoColumns}>
              <span className="top-banner-title">{SUBJECT.MC_NUMBER_COLON}</span>{' '}
              <TooltipText
                prefixClass="top-banner-value"
                data={data?.result?.mc}
                subject={`${SUBJECT.AUTHORITIES_INFO}${SUBJECT.MC_NUMBER_COLON}`}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.infoColumns}>
              <span className="top-banner-title">{SUBJECT.AUTHORITIES_ID_COLON}</span>{' '}
              <Col span={13}>
                <div
                  className={clsx(styles.copyIconText, {
                    [styles.copyActive]: copiedText === 'topBannerId',
                  })}
                >
                  <Typography variant="paragraph" children={data?.result?.id} className={styles.infoParagraph} />
                  <div
                    className={styles.copyIcon}
                    role="button"
                    onClick={() => handleCopy(data?.result?.id, 'topBannerId')}
                  >
                    <CopyIcon active={copiedText === 'topBannerId'} />
                  </div>
                </div>
              </Col>
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.infoColumns}>
              <span className="top-banner-title">{SUBJECT.TYPES_COLON}</span>{' '}
              <span className="top-banner-value">{AuthorityTypes[data?.result?.type]}</span>
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.infoColumns}>
              <span className="top-banner-title">{SUBJECT.PHONE_NUMBER_COLON} </span>
              <Link className="top-banner-value" to={`tel:${data?.result?.phone}`}>
                {formatPhone(data?.result?.phone)}
              </Link>
            </div>
          </Col>
          {data?.result?.fax && (
            <Col span={8}>
              <div className={styles.infoColumns}>
                <span className="top-banner-title">{SUBJECT.FAX_COLON}</span>{' '}
                <TooltipText
                  prefixClass="top-banner-value"
                  data={data?.result?.fax}
                  subject={`${SUBJECT.AUTHORITIES_INFO}${SUBJECT.FAX_COLON}`}
                />
              </div>
            </Col>
          )}
        </Row>
      </ViewItemWrapper>
      <div className={styles.editAuthoritiesSection}>
        <CreateAuthority
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          control={control}
          trigger={trigger}
          getValues={getValues}
          errors={errors}
          mode={mode}
          statesData={statesData}
          beforeUpload={beforeUpload}
          allFiles={allFiles}
          authorityId={authorityId}
          handleDeleteAuthorityFile={handleDeleteAuthorityFile}
          handleDownloadAuthorityFileEdit={handleDownloadAuthorityFile}
          handleViewAuthorityFileEdit={handleViewAuthorityFileEdit}
          handleDownloadAuthorityFile={handleDownloadNewFile}
          handleViewAuthorityFileCreate={handleViewNewFile}
          watch={watch}
          setValue={setValue}
          isCreateLoading={isCreateLoading}
          templates={templates}
          factoringCompaniesAutocomplete={factoringCompaniesAutocomplete}
          setFactoringCompaniesAutocompleteFilter={setFactoringCompaniesAutocompleteFilter}
          brackets={brackets}
          bracketErrors={bracketErrors}
          getBracketValues={getBracketValues}
          setBracketsValue={setBracketsValue}
          bracketControl={bracketControl}
          handleBracketSubmit={handleBracketSubmit}
          onBracketSubmit={onBracketSubmit}
          isBracketValid={isBracketValid}
          handleDeleteBracket={handleDeleteBracket}
          resetField={resetField}
        />
      </div>
    </div>
  );
};

export default EditAuthority;
