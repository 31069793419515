import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Popover, Row } from 'antd';
import clsx from 'clsx';
import { useHandleCopyActive } from 'hooks/useCopy';
import { SUBJECT } from 'pages/profile/tabs/office/constants/constants';
import { useGetWhiteListQuery } from 'services/permission/permission';
import { Location } from 'services/profile/agents/interfaces';
import {
  OfficeCreditCheckChargeTypes,
  OfficeSetUpDisplayTypes,
  OfficeSetUpTypes,
} from 'services/profile/offices/interfaces';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import Button from 'ui/button/Button';
import DataGrid from 'ui/data-grid/DataGrid';
import Switch from 'ui/inputs/switch/Switch';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { UserTypes } from 'utils/constants';
import { formatDate } from 'utils/dates';
import { formatNumberWithThousandsSeparatorAndDecimal, formatPhone } from 'utils/helpers';

import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { FeeTypes } from '../../constants/types';
import WhiteListDataGrid from '../white-list/data-grid/WhiteListDataGrid';

import {
  billingPermissionSerializerForView,
  payablesPermissionSerializerForView,
  receivablesPermissionSerializerForView,
} from './helpers';
import { useOfficeView } from './useOfficeView';

import styles from './OfficeView.module.scss';

const OfficeView = ({
  data,
  isFetchingById,
  pagePermissions: { edit, terminateOn, terminateOff },
  authorityByOfficeId,
  setGetAuthoritiesByOfficeFilter,
}: any) => {
  const { handleChecked, handleChangeMode, handlePaginationClick, mode } = useOfficeView({
    id: data?.result?.id,
    setGetAuthoritiesByOfficeFilter,
  });
  const { copiedText, handleCopy } = useHandleCopyActive();
  const checkedValue = !data?.result?.terminatedAt;
  const { userType } = useSelector(selectUserPermissionsInfo);
  const { data: whiteListData, isLoading } = useGetWhiteListQuery(
    { entityId: data?.result?.id },
    { skip: !data?.result?.id }
  );

  return (
    <div className={styles.viewMain}>
      <MainLoader isFetching={isFetchingById} />
      <ViewItemWrapper
        title={SUBJECT.OFFICE_INFO}
        Component={
          edit && (
            <Button children={SUBJECT.EDIT} size="large" onClick={handleChangeMode} className="viewDrawerEditBtn" />
          )
        }
      >
        <Row gutter={[20, 15]}>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.OFFICE_NAME}:{' '}
              </Typography>
              <TooltipText
                prefixClass="top-banner-value"
                data={data?.result?.name}
                subject={`${SUBJECT.OFFICE_INFO}${SUBJECT.OFFICE_NAME}`}
              />
            </div>
          </Col>
          {!!data?.result?.location && (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <Typography variant="paragraph" className="top-banner-title">
                  {SUBJECT.LOCATION}:{' '}
                </Typography>
                <Typography variant="paragraph" className="top-banner-value">
                  {Location[data?.result?.location]}
                </Typography>
              </div>
            </Col>
          )}
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.NUMBER_OF_BRANCHES}:{' '}
              </Typography>
              <Typography variant="paragraph" className="top-banner-value">
                {data?.result?.branchCount}
              </Typography>
            </div>
          </Col>
        </Row>
        <Row gutter={[20, 15]}>
          {data?.result?.owners?.length ? (
            <Col span={8}>
              <div className={styles.headingBlock}>
                <>
                  <Typography variant="paragraph" className="top-banner-title">
                    {data?.result?.owners?.length === 1 ? SUBJECT.OWNER_NUMBER : SUBJECT.OWNERS_NUMBER}:
                  </Typography>
                </>
                <>
                  {data?.result?.owners?.length === 1 && (
                    <TooltipText
                      phoneLink={formatPhone(data?.result?.owners?.[0]?.phone)}
                      prefixClass={styles.infoParagraph}
                      data={`${data?.result?.owners?.[0]?.firstName} ${data?.result?.owners?.[0]?.lastName}`}
                      subject={`${SUBJECT.OFFICE_INFO}${SUBJECT.OWNERS}`}
                    />
                  )}
                  {data?.result?.owners?.length > 1 && (
                    <Popover
                      placement="top"
                      content={data?.result?.owners?.map((el: any, index: any) => (
                        <div className={clsx(styles.phoneItem)} key={index}>
                          <a className={styles.phoneLinkInArray} href={`tel:${el?.phone}`}>
                            {formatPhone(el?.phone)}
                          </a>
                          <Typography variant="paragraph" className={clsx(styles.phoneNameInArray, 'top-banner-value')}>
                            {`${el?.firstName} ${el?.lastName}`}
                          </Typography>
                        </div>
                      ))}
                    >
                      <a className="top-banner-value">{`+${data?.result?.owners?.length}`}</a>
                    </Popover>
                  )}
                </>
              </div>
            </Col>
          ) : null}
        </Row>
      </ViewItemWrapper>
      <ViewItemWrapper title={SUBJECT.FULL_INFO}>
        <Row justify="space-between" align="top" gutter={[10, 30]}>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.DATE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={formatDate(data?.result?.createdAt)}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.OFFICE_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={data?.result?.name}
                  subject={`${SUBJECT.FULL_INFO}${SUBJECT.OFFICE_NAME}`}
                />
              </Col>
            </Row>
          </Col>
          {data?.result?.type !== 0 && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.AGENCY_TYPE} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass="top-banner-value"
                    data={OfficeSetUpDisplayTypes[data?.result?.type]}
                    subject={OfficeSetUpDisplayTypes[data?.result?.type]}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {!!data?.result?.location && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.OFFICE_LOCATION} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={Location[data?.result?.location]}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {data?.result?.owners?.length ? (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography
                    variant="heading"
                    children={data?.result?.owners?.length === 1 ? SUBJECT.OWNER : SUBJECT.OWNERS}
                    className={styles.infoHeading}
                  />
                </Col>
                <Col span={13}>
                  {data?.result?.owners?.length === 1 && (
                    <TooltipText
                      phoneLink={formatPhone(data?.result?.owners?.[0]?.phone)}
                      prefixClass={styles.infoParagraph}
                      data={`${data?.result?.owners?.[0]?.firstName} ${data?.result?.owners?.[0]?.lastName}`}
                      subject={`${SUBJECT.FULL_INFO}${SUBJECT.OWNERS}`}
                    />
                  )}
                  {data?.result?.owners?.length > 1 && (
                    <Popover
                      placement="top"
                      content={data?.result?.owners?.map((el: any, index: any) => (
                        <div className={clsx(styles.phoneItem)} key={index}>
                          <a className={styles.phoneLinkInArray} href={`tel:${el?.phone}`}>
                            {formatPhone(el?.phone)}
                          </a>
                          <Typography variant="paragraph" className={clsx(styles.phoneNameInArray, 'top-banner-value')}>
                            {`${el?.firstName} ${el?.lastName}`}
                          </Typography>
                        </div>
                      ))}
                    >
                      <a className={styles.infoParagraph}>{`+${data?.result?.owners?.length}`}</a>
                    </Popover>
                  )}
                </Col>
              </Row>
            </Col>
          ) : null}
          {data?.result?.bankName && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.BANK_NAME} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={data?.result?.bankName}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.BANK_NAME}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {data?.result?.accountingNumber && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.ACCOUNTING_NUMBER} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={data?.result?.accountingNumber}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.ACCOUNTING_NUMBER}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {data?.result?.achRoutingNumber && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.ACH_ROUTING_NUMBER} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={data?.result?.achRoutingNumber}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.ACH_ROUTING_NUMBER}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.NUMBER_OF_BRANCHES} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={data?.result?.branchCount} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          {!!data?.result?.authorityCount && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.AUTHORITIES} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={data?.result?.authorityCount}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.ADDRESS} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={data?.result?.address}
                  subject={`${SUBJECT.FULL_INFO}${SUBJECT.ADDRESS}`}
                />
              </Col>
            </Row>
          </Col>
          {data?.result?.secondAddress && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.SECOND_ADDRESS} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass="top-banner-value"
                    data={data?.result?.secondAddress}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.SECOND_ADDRESS}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.CITY} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass="top-banner-value"
                  data={data?.result?.city}
                  subject={`${SUBJECT.FULL_INFO}${SUBJECT.CITY}`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.STATE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={data?.result?.state?.name} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.ZIPCODE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={data?.result?.zip} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.PHONE_NUMBER} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Link className="top-banner-value" to={`tel:${data?.result?.phone}`}>
                  {formatPhone(data?.result?.phone)}
                </Link>
              </Col>
            </Row>
          </Col>
          {data?.result?.fax && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.FAX} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass="top-banner-value"
                    data={data?.result?.fax}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.FAX}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.PAYOUT_PERCENTAGE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={`${data?.result?.payoutPercent}%`}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.STATUS} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Switch
                  checked={checkedValue || false}
                  onChange={handleChecked}
                  disabled={(checkedValue && !terminateOff) || (!checkedValue && !terminateOn)}
                />
              </Col>
            </Row>
          </Col>
          {data?.result?.creator.username && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.ADDED_BY} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={`${data?.result?.creator.username}`}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </ViewItemWrapper>
      {data?.result?.type === OfficeSetUpTypes.OFFICE_SET_UP_TYPES_OUTSIDE && (
        <ViewItemWrapper title={SUBJECT.OUTSIDE_AGENCY}>
          <Row justify="space-between" align="top" gutter={[10, 30]}>
            {data?.result?.permissions.billingPermission && (
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.BILLING} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={billingPermissionSerializerForView(data?.result?.permissions.billingPermission)}
                      subject={billingPermissionSerializerForView(data?.result?.permissions.billingPermission)}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            {data?.result?.permissions.payablePermission && (
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.PAYABLES} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={payablesPermissionSerializerForView(data?.result?.permissions.payablePermission)}
                      subject={payablesPermissionSerializerForView(data?.result?.permissions.payablePermission)}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            {data?.result?.permissions.receivablePermission && (
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.RECEIVABLES} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={receivablesPermissionSerializerForView(data?.result?.permissions.receivablePermission)}
                      subject={receivablesPermissionSerializerForView(data?.result?.permissions.receivablePermission)}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            {data?.result?.permissions.accountCollectionPermission && (
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children="Collection" className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={receivablesPermissionSerializerForView(
                        data?.result?.permissions.accountCollectionPermission
                      )}
                      subject={receivablesPermissionSerializerForView(
                        data?.result?.permissions.accountCollectionPermission
                      )}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.CREDIT_CHECK} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={data?.result?.creditCheckChargeType ? 'Yes' : 'No'}
                    subject={data?.result?.creditCheckChargeType ? 'Yes' : 'No'}
                  />
                </Col>
              </Row>
            </Col>
            {data?.result?.creditCheckChargeAmount && (
              <Col span={10}>
                <Row gutter={10}>
                  <Col span={11}>
                    <Typography
                      variant="heading"
                      children={
                        +data?.result?.creditCheckChargeType ===
                        OfficeCreditCheckChargeTypes.OFFICE_CREDIT_CHECK_CHARGE_TYPES_MONTH
                          ? SUBJECT.MONTHLY
                          : SUBJECT.PER_LOAD
                      }
                      className={styles.infoHeading}
                    />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={formatNumberWithThousandsSeparatorAndDecimal(data?.result?.creditCheckChargeAmount)}
                      subject={data?.result?.creditCheckChargeAmount}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </ViewItemWrapper>
      )}

      {!!authorityByOfficeId?.result?.length && (
        <ViewItemWrapper title={`${SUBJECT.ADDED} Authority (${authorityByOfficeId?.count})`}>
          <Row>
            <Col span={24}>
              <DataGrid
                data={authorityByOfficeId?.result}
                count={authorityByOfficeId?.count}
                type="authoritiesView"
                handlePaginationClick={handlePaginationClick}
                mode={mode}
                copiedText={copiedText}
                handleCopy={handleCopy}
              />
            </Col>
          </Row>
        </ViewItemWrapper>
      )}
      {data?.result?.feeType && (
        <ViewItemWrapper title={SUBJECT.LPF_SOFTWARE_FEE}>
          <Row justify="space-between" align="top" gutter={[10, 30]}>
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.FEE_TYPE} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={data?.result?.feeType === FeeTypes.lpf ? 'LPF' : 'Software fee'}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography
                    variant="heading"
                    children={data?.result?.feeType === FeeTypes.lpf ? SUBJECT.PER_LOAD_AMOUNT : SUBJECT.MONTHLY_AMOUNT}
                    className={styles.infoHeading}
                  />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={`$ ${data?.result?.fee}`}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </ViewItemWrapper>
      )}
      {userType === UserTypes.ADMIN && !!whiteListData?.result?.length && (
        <ViewItemWrapper title={SUBJECT.WHITE_LIST}>
          <WhiteListDataGrid data={whiteListData?.result} count={false} isLoading={isLoading} withoutAction={true} />
        </ViewItemWrapper>
      )}
    </div>
  );
};

export default OfficeView;
