import dayjs from 'dayjs';
import { ITransactionTypes } from 'pages/loads/components/edit-load/constants/constants';
import { formatDate } from 'utils/dates';
import { changeTimeZone } from 'utils/helpers';
import { v4 as uuid } from 'uuid';

import { changeTimezoneToUs } from '../../../hooks/useTimeZoneDetector';

import {
  IAdvanceRes,
  ICollectionItem,
  ICollectionRes,
  IDistribute,
  IDistributeResponse,
  IOtherServiceFee,
  IOtherServiceFeeItem,
  IPayrollCreditChargesItem,
  IPayrollCreditsCharges,
} from './types';

export const informationFactoredSerializer = (data: any) => {
  return {
    cardsData: {
      grossProfit: data.grossProfit,
      ...(data?.lpf === undefined ? {} : { lpf: data?.lpf }),
      ...(data?.softwareFee === undefined ? {} : { softwareFee: data?.softwareFee }),
      ...(data?.factoringFee === undefined ? {} : { factoringFee: data?.factoringFee }),
      serviceFee: data.serviceFee,
      loads: data?.loads,
    },
    count: data.count,
    result: data.result.map((item: any) => ({
      acceptBillingTimeAt: item?.acceptToBillingAt,
      customer: item?.customer?.name,
      code: item?.code,
      loadType: item?.type,
      loadId: item?.id,
      id: uuid(),
      referenceNumber: item?.referenceNumber,
      agency: item?.office?.name,
      agent: `${item?.agent?.username}`,
      profit: item?.profit,
      serviceFeeAmount: item?.serviceFeeAmount,
      percent: item?.percent,
      loadProfitFeeAmount: item?.lpfAmount,
      softwareFeeAmount: item?.softwareFee,
      factoringFee: item?.factoringFee,
    })),
  };
};

export const informationNotFactoredSerializer = (data: any) => {
  return {
    cardsData: { potentialGrossProfit: data?.potentialGrossProfit },
    count: data.count,
    result: data.result.map((item: any) => {
      return {
        addedLoadAt: item?.addLoadAt,
        customer: item?.customer?.name,
        code: item?.code,
        loadType: item?.type,
        loadId: item?.id,
        id: uuid(),
        referenceNumber: item?.referenceNumber,
        agency: item?.office?.name,
        agent: `${item?.agent?.username}`,
        potentialProfit: item?.profit,
      };
    }),
  };
};

export const informationCreditsChargesSerializer = (data: IPayrollCreditsCharges, isCredit = false) => {
  const result = data?.result?.map((item: IPayrollCreditChargesItem) => {
    return {
      id: item?.distributeId,
      acceptBillingTimeAt: item?.acceptBillingTimeAt,
      customer: item?.customer?.name,
      loadType: item?.loadType,
      loadId: item?.loadId,
      loadCode: item?.code,
      referenceNumber: item?.referenceNumber,
      agency: item?.office?.name,
      agent: `${item?.agent?.username}`,
      amount: {
        amount: item?.amount,
        percent: item?.myPercent,
      },
      chargesAmount: {
        amount: item?.amount,
        percent: item?.myPercent,
      },
      percentAmount: item?.percentAmount,
      loadCharges: item?.percentAmount,
      distribute: 'Distribute',
      transactionType: ITransactionTypes[item?.type],
      movedToNextMonthAt: item?.movedToNextMonthAt || null,
      movedToNextMonthBy: item?.movedToNextMonthBy?.username || null,
      movedToCollectionAt: item?.movedToCollectionAt || null,
      movedToCollectionBy: item?.movedToCollectionBy?.username || null,
    };
  });

  return {
    cardsData: {
      ...(isCredit && { credit: data?.credit }),
      ...(!isCredit && { charges: data?.charges }),
    },
    count: data?.count,
    result,
    moveToCollection: data.moveToCollection,
  };
};

export const informationCreditCheckSerializer = (data: any) => {
  return {
    cardsData: {
      grossProfit: data.grossProfit,
      lpf: data.lpf,
      serviceFee: data.serviceFee,
      loads: data?.loads,
    },
    count: data.count,
    result: data.result.map((item: any) => ({
      acceptBillingTimeAt: formatDate(item?.acceptToBillingAt),
      customer: item?.customer?.name,
      loadId: item?.loadId,
      loadType: item?.type,
      code: item?.code,
      referenceNumber: item?.referenceNumber,
      office: item?.office?.name,
      agent: `${item?.agent?.username}`,
      creditCheckChargeAmount: item?.creditCheckChargeAmount,
    })),
  };
};

export const informationWithFeeSerializer = (data: any) => {
  return {
    cardsData: {
      grossProfit: data.grossProfit,
      lpf: data.lpf,
      serviceFee: data.serviceFee,
      loads: data?.loads,
    },
    count: data.count,
    result: data.result.map((item: any) => ({
      acceptBillingTimeAt: formatDate(item?.acceptToBillingAt),
      customer: item?.customer?.name,
      loadId: item?.id,
      loadType: item?.type,
      code: item?.code,
      referenceNumber: item?.referenceNumber,
      agency: item?.office?.name,
      agent: `${item?.agent?.username}`,
      softwareFeeAmount: item?.softwareFeeAmount,
      lpfAmount: item?.lpfAmount,
      factoringFeeAmount: item?.factoringFeeAmount,
    })),
  };
};

export const getDistributeSerializer = (data: IDistribute): IDistributeResponse => {
  return {
    acceptBillingTimeAt: data?.acceptBillingTimeAt,
    id: data?.id,
    corporatePercent: data?.corporatePercent,
    amount: data?.amount,
    agent: `${data?.agent?.firstName} ${data?.agent?.lastName}`,
    agentPercent: data?.agentPercent,
    agency: data?.office?.name,
    agencyPercent: data?.officePercent,
    branch: data?.branch?.name,
    branchPercent: data?.branchPercent,
    edited: data?.edited,
  };
};

export const getRequestsParamsSerializer = (params: any) => {
  const queryParams = new URLSearchParams();

  const currentMonthEnd = dayjs(new Date(Number(params.dateStart)))
    .endOf('month')
    .valueOf();

  if (params.search) {
    queryParams.append('search', String(params.search));
  }
  if (params.feeType) {
    queryParams.append('feeType', String(params.feeType));
  }
  if (params.mine === 'true') {
    queryParams.append('mine', params.mine);
  }
  if (params.field) {
    queryParams.append('field', String(params.field));
  }
  if (String(params.skip)) {
    queryParams.append('skip', String(params.skip));
  }
  if (params.limit) {
    queryParams.append('limit', String(params.limit));
  }
  if (params.order) {
    queryParams.append('order', String(params.order));
  }
  if (params.orderBy) {
    queryParams.append('orderBy', String(params.orderBy));
  }
  if (params.officeIds?.length && params.mine !== 'true') {
    params.officeIds?.forEach((el: string | number) => queryParams.append('officeIds[]', String(el)));
  }
  if (params.branchIds?.length && params.mine !== 'true') {
    params.branchIds?.forEach((el: string | number) => queryParams.append('branchIds[]', String(el)));
  }
  if (params.agentIds?.length && params.mine !== 'true') {
    params.agentIds?.forEach((el: string | number) => queryParams.append('agentIds[]', String(el)));
  }
  if (params.dateStart) {
    queryParams.append('dateStart', String(changeTimeZone(new Date(Number(params.dateStart)), 'America/Los_Angeles')));
  }
  if (params.endDate) {
    queryParams.append('dateEnd', changeTimeZone(new Date(Number(params.endDate)), 'America/Los_Angeles'));
  } else if (params.dateStart) {
    queryParams.append('dateEnd', changeTimeZone(new Date(Number(currentMonthEnd)), 'America/Los_Angeles'));
  }
  return queryParams;
};

export const getSummariesParamsSerializer = (params: any) => {
  const queryParams = new URLSearchParams();

  const currentMonthEnd = dayjs(new Date(Number(params.dateStart)))
    .endOf('month')
    .valueOf();
  const endDateMonthEnd = dayjs(new Date(Number(params.dateEnd)))
    .endOf('month')
    .valueOf();

  if (params.officeIds?.length) {
    params.officeIds?.forEach((el: string | number) => queryParams.append('officeIds[]', String(el)));
  }
  if (params.mine === 'true') {
    queryParams.append('mine', params?.mine);
  }
  if (params.branchIds?.length) {
    params.branchIds?.forEach((el: string | number) => queryParams.append('branchIds[]', String(el)));
  }
  if (params.agentIds?.length) {
    params.agentIds?.forEach((el: string | number) => queryParams.append('agentIds[]', String(el)));
  }
  if (params.dateStart) {
    queryParams.append('dateStart', String(changeTimeZone(new Date(Number(params.dateStart)), 'America/Los_Angeles')));
  }
  if (!params.dateEnd && params.dateStart) {
    queryParams.append('dateEnd', String(changeTimeZone(new Date(Number(currentMonthEnd)), 'America/Los_Angeles')));
  }
  if (params.dateEnd && params.dateMode === 'range') {
    queryParams.append('dateEnd', String(changeTimeZone(new Date(Number(endDateMonthEnd)), 'America/Los_Angeles')));
  } else if (params.dateEnd && (params.dateMode === 'custom' || !params.dateMode)) {
    queryParams.append('dateEnd', String(changeTimeZone(new Date(Number(params.dateEnd)), 'America/Los_Angeles')));
  }

  return queryParams;
};

export const getAdvanceSerializer = (body: IAdvanceRes) => {
  const result = body?.result.map(item => {
    const isInCurrentMonth =
      changeTimezoneToUs(new Date()).getMonth() === changeTimezoneToUs(new Date(item?.createdAt)).getMonth();

    return {
      createdAt: item?.createdAt ? formatDate(item?.createdAt) : '',
      id: item?.id,
      agency: item?.office?.name,
      branch: item?.branch?.name,
      agent: item?.agent ? `${item?.agent?.username}` : '',
      type: item?.type,
      reason: item?.comment,
      amount: item?.amount,
      withoutEditDelete: !isInCurrentMonth,
    };
  });

  return {
    cardsData: { advance: body?.advance },
    count: body?.count,
    result,
  };
};

export const getCollectionSerializer = (body: ICollectionRes) => {
  return {
    count: body?.count,
    cardsData: {
      collection: body?.collection,
    },
    result: body?.result?.map((item: ICollectionItem) => ({
      createdFor: item?.createdFor,
      id: item?.id,
      distributed: item?.distributed,
      agent: item?.agent ? `${item?.agent?.username}` : '',
      amount: item?.amount,
      branch: item?.branch ? item?.branch?.name : '',
      agency: item?.office?.name,
      paymentPlan: item?.paymentPlan,
      lastPaidAt: item?.lastPaidAt,
      lastPaidBy: item?.lastPaidBy,
    })),
  };
};

export const getOtherServiceFeeSerializer = (body: IOtherServiceFee) => {
  return {
    count: body?.count,
    cardsData: {
      otherServiceFee: body?.otherServiceFee,
    },
    result: body?.result?.map((item: IOtherServiceFeeItem) => {
      const isInCurrentMonth =
        changeTimezoneToUs(new Date()).getMonth() === changeTimezoneToUs(new Date(item?.createdAt)).getMonth();

      return {
        id: item?.id,
        createdAt: item?.createdAt,
        agency: item?.office?.name ?? '',
        branch: item?.branch?.name ?? '',
        agent: item?.agent ? `${item?.agent?.username}` : '',
        serviceType: item?.type,
        amount: item?.amount,
        status: item?.status !== 1,
        withoutEditDelete: !isInCurrentMonth,
      };
    }),
  };
};
