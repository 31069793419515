import { BillingTypesText } from 'services/profile/customers/interfaces';
import { formatDate } from 'utils/dates';
import { changeTimeZone, checkIsNumberDecimal, formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import { ICustomerInfo, IGetCustomerInfo, IReceivable, IReceivablesData } from './interface';

export const getReceivablesSerializer = (body: IReceivablesData) => {
  const result = body?.result?.map((item: IReceivable) => {
    return {
      id: item?.receivable?.id,
      acceptBillingTimeAt: formatDate(item?.receivable?.acceptBillingTimeAt as Date),
      loadId: item?.receivable?.id,
      loadStatus: {
        processing: item?.receivable?.processing,
        status: item?.receivable?.status || 0,
        carrierStatus: item?.receivable?.carrierStatus,
        customerStatus: item?.receivable?.customerStatus,
      },
      dataType: 'receivables',
      refNumber: item?.receivable?.referenceNumber,
      aging: item?.receivable?.aging + 1,
      paymentTermDay: item?.receivable?.paymentTermDay,
      paymentMethod: BillingTypesText[item?.receivable?.paymentTermType],
      paymentPercent: item?.receivable?.paymentTermPercent,
      customerId: item?.receivable?.customer?.id,
      customerName: item?.receivable?.customer?.name,
      customerFinalAmount: formatNumberWithThousandsSeparatorAndDecimal(item?.receivable?.customerFinalAmount),
      customerBalance: formatNumberWithThousandsSeparatorAndDecimal(item?.receivable?.customerBalance),
      carrierName: item?.receivable?.carrier?.companyName,
      carrierFinalAmount: formatNumberWithThousandsSeparatorAndDecimal(item?.receivable?.carrierFinalAmount),
      carrierAmount: formatNumberWithThousandsSeparatorAndDecimal(item?.receivable?.carrierAmount),
      mcNumber: item?.receivable?.authority?.mc,
      mcId: item?.receivable?.authority?.id,
      invoiceIds: item.invoiceIds,
      invoice: formatDate(item?.invoiceDate as Date),
      invoiceReceivedByUser: item?.invoiceReceivedByUser?.username,
      sendInvoice: item?.receivable?.customerStatus,
      proccesing: !!item?.receivable?.processing,
      lastComment: item?.comment?.message,
      claimed: item?.receivable?.claimed,
      loadType: item?.receivable?.loadType,
      invoiceReceivedAt: item?.receivable?.invoiceReceivedAt,
      code: item?.receivable?.code,
      agencyName: item?.receivable?.office?.name,
      branchName: item?.receivable?.branch?.name,
      agentName: `${item?.receivable?.creator?.username}`,
      takenAs: item?.receivable?.authority?.name,
      factoringFee: item?.receivable?.factoringFee || '',
    };
  });

  return {
    result,
    count: body?.count,
    expectedCustomAmount: body?.expectedCustomAmount,
    expectedQuickAmount: body?.expectedQuickAmount,
    expectedTotalAmount: body?.expectedTotalAmount,
    expectedTotalCount: body?.expectedTotalCount,
    receivedCustomAmount: body?.receivedCustomAmount,
    receivedQuickAmount: body?.receivedQuickAmount,
    receivedTotalAmount: body?.receivedTotalAmount,
    receivedTotalCount: body?.receivedTotalCount,
  };
};

export const getCustomerInfoSerializer = (body: IGetCustomerInfo) => {
  const result = body?.result?.map((info: ICustomerInfo) => {
    return {
      keyType: 'receivables',
      id: info?.id,
      key: info?.id,
      code: info?.code,
      regDate: formatDate(info?.createdAt as Date),
      loadId: info?.id,
      customerBalance: checkIsNumberDecimal(info?.customerBalance),
      customerReceivable: info?.customerFinalAmount,
      customerDept: checkIsNumberDecimal(info?.customerDeptAmount),
      mc: info?.authority?.name,
      payment: info?.havePayment,
      status: info?.customerStatus,
      loadType: info?.type,
      invoice: {
        date: formatDate(info?.invoiceCreatedAt as Date),
        ids: info?.invoiceFileIds,
      },
      claimed: info?.claimed,
      refNumber: info?.referenceNumber,
      takenAs: info?.authority?.name,
    };
  });

  return {
    result,
    expected: checkIsNumberDecimal(body?.expected),
    received: checkIsNumberDecimal(body?.received),
    count: body?.count,
  };
};

export const getRecievablesParamsSerializer = (params: any) => {
  const queryParams = new URLSearchParams();
  if (params.search) {
    queryParams.append('search', String(params.search));
  }
  if (params.field) {
    queryParams.append('field', String(params.field));
  }
  if (String(params.skip)) {
    queryParams.append('skip', String(params.skip));
  }
  if (params.limit) {
    queryParams.append('limit', String(params.limit));
  }
  if (params.order) {
    queryParams.append('order', String(params.order));
  }
  if (params.orderBy) {
    queryParams.append('orderBy', String(params.orderBy));
  }
  if (params.filter.createdFrom) {
    queryParams.append(
      'createdFrom',
      params.filter?.isConvertingCreatedAtTimeNeeded
        ? String(changeTimeZone(new Date(Number(params.filter?.createdFrom)), 'America/Los_Angeles'))
        : String(params.filter?.createdFrom)
    );
  }
  if (params.filter.createdTo) {
    queryParams.append(
      'createdTo',
      params.filter?.isConvertingCreatedAtTimeNeeded
        ? String(changeTimeZone(new Date(Number(params.filter?.createdTo)), 'America/Los_Angeles'))
        : String(params.filter?.createdTo)
    );
  }
  if (params.filter.paidFrom) {
    queryParams.append(
      'paidFrom',
      params.filter?.isConvertingPaidAtTimeNeeded
        ? String(changeTimeZone(new Date(Number(params.filter?.paidFrom)), 'America/Los_Angeles'))
        : String(params.filter?.paidFrom)
    );
  }
  if (params.filter.paidTo) {
    queryParams.append(
      'paidTo',
      params.filter?.isConvertingPaidAtTimeNeeded
        ? String(changeTimeZone(new Date(Number(params.filter?.paidTo)), 'America/Los_Angeles'))
        : String(params.filter?.paidTo)
    );
  }
  if (params.filter?.officeIds?.length) {
    params.filter?.officeIds?.forEach((el: string | number) => queryParams.append('officeIds[]', String(el)));
  }
  if (params.filter?.branchIds?.length) {
    params.filter?.branchIds?.forEach((el: string | number) => queryParams.append('branchIds[]', String(el)));
  }
  if (params.filter?.agentIds?.length) {
    params.filter?.agentIds?.forEach((el: string | number) => queryParams.append('agentIds[]', String(el)));
  }
  if (params.filter?.customerIds?.length) {
    params.filter?.customerIds?.forEach((el: string | number) => queryParams.append('customerIds[]', String(el)));
  }
  if (params.filter?.authorityIds?.length) {
    params.filter?.authorityIds?.forEach((el: string | number) => queryParams.append('authorityIds[]', String(el)));
  }
  if (params.filter?.customerPaymentTypes?.length) {
    params.filter?.customerPaymentTypes?.forEach((el: string | number) =>
      queryParams.append('customerPaymentTypes[]', String(el))
    );
  }
  return queryParams;
};
