import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import useResetColumns from 'hooks/useResetColumns';
import { useGetCustomersQuery } from 'services/autocomplete/autocomplete';
import {
  useGetCollectionsQuery,
  useGetCustomerInfoMutation,
  useGetDetailedCustomerQuery,
  useMarkAsInvoiceReceivedMutation,
  useSendInvoiceReceivablesMutation,
} from 'services/factoring/collection/collection';
import { IGetCollectionParams } from 'services/factoring/collection/interface';
import { IGetLoadsParams } from 'services/loads/interface';
import { useGetLoadFilesByIdsMutation } from 'services/loads/loads';
import { useGetAgentQuery, useGetAgentsAutocompleteQuery } from 'services/profile/agents/agents';
import { useGetAuthoritiesShortQuery, useGetAuthorityQuery } from 'services/profile/authorities/authorities';
import { IGetAuthoritiesParams } from 'services/profile/authorities/interfaces';
import { useGetBranchesAutocompleteQuery } from 'services/profile/branches/branches';
import { useGetOfficesAutocompleteQuery } from 'services/profile/offices/offices';
import { useDeleteColumnsMutation, useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { setButtonVisibility } from 'store/add-button-slice/addButton';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { multiplyDownloadWithDelay } from 'utils/helpers';
import { IChildren } from 'utils/types';

import { IColumn } from 'components/custom-column/constants/types';
import CustomColumn from 'components/custom-column/CustomColumn';
import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { usePermittedActions } from '../../../../hooks/usePermittedActions';

import CollectionView from './components/collection-view/CollectionView';
import { filterDefaultValues, SUBJECT } from './constants/constants';
import { ICollectionFilter } from './constants/types';

const useCollection = (setExcelParams: any) => {
  const dispatch = useDispatch();

  const { searchParams, setSearchParams } = useDetectedParams();
  const {
    mode,
    tab,
    open,
    authorityId,
    id,
    regOption,
    paidAllDate,
    paidEndDate,
    paidStartDate,
    regAllDate,
    regEndDate,
    regStartDate,
    paidOption,
    mcReceivablesFilter,
    customerReceivablesFilter,
    officeReceivablesFilter,
    branchReceivablesFilter,
    agentReceivablesFilter,
    paymentMethodReceivablesFilter,
  } = searchParams;

  const collectionPagePermissions = usePermittedActions('factoring.accountCollection');

  const [collectionList, setCollectionsList] = useState<Partial<IGetCollectionParams>>({
    search: '',
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: 'aging',
    filter: {},
    field: '',
  });

  const { data: columnsData } = useGetColumnsQuery({ type: 'collection' });
  const [editColumns, { isLoading: isLoadingColumnsEdit }] = useEditColumnsMutation();
  const [deleteColumns, { isLoading: isLoadingColumnsDelete }] = useDeleteColumnsMutation();

  const [markAsInvoiceReceived] = useMarkAsInvoiceReceivedMutation();

  const [getLoadFilesByIds] = useGetLoadFilesByIdsMutation();

  const [getCustomerInfo, { data: customerInfo, isLoading: isReceiveLoading }] = useGetCustomerInfoMutation();
  const { currentData: customerById, isFetching: isLoadingById } = useGetDetailedCustomerQuery(id, {
    skip: !id,
  });

  const [sendInvoiceReceivables] = useSendInvoiceReceivablesMutation();

  const {
    reset,
    control,
    watch,
    setValue,
    formState: { isDirty },
    resetField,
  } = useForm<ICollectionFilter>({
    defaultValues: filterDefaultValues,
    mode: 'onChange',
  });

  const { data, isLoading } = useGetCollectionsQuery(
    {
      ...collectionList,
      filter: {
        ...(customerReceivablesFilter ? { customerIds: [customerReceivablesFilter] } : {}),
        ...(mcReceivablesFilter ? { authorityIds: [Number(mcReceivablesFilter)] } : {}),

        branchIds: branchReceivablesFilter?.length ? branchReceivablesFilter?.split(',')?.map(el => Number(el)) : [],
        officeIds: officeReceivablesFilter?.length ? officeReceivablesFilter?.split(',')?.map(el => Number(el)) : [],
        ...(agentReceivablesFilter ? { agentIds: [Number(agentReceivablesFilter)] } : {}),

        ...(watch('customerPaymentMethod') && watch('customerPaymentMethod') !== '0'
          ? { customerPaymentTypes: [Number(watch('customerPaymentMethod'))] }
          : {}),

        ...(!regAllDate && regOption !== '4' && regStartDate
          ? { createdFrom: Number(regStartDate), createdTo: Number(regEndDate) }
          : {}),
        ...(!paidAllDate && paidOption !== '4' && paidStartDate
          ? { paidFrom: Number(paidStartDate), paidTo: Number(paidEndDate) }
          : {}),
        isConvertingCreatedAtTimeNeeded: regOption === '5',
        isConvertingPaidAtTimeNeeded: paidOption === '5',
      },
    },
    {
      skip: open === 'true',
    }
  );
  useEffect(() => {
    if (setExcelParams) {
      setExcelParams((prev: any) => {
        return {
          ...prev,
          ...collectionList,
          ...(customerReceivablesFilter ? { customerIds: [customerReceivablesFilter] } : {}),
          ...(mcReceivablesFilter ? { authorityIds: [Number(mcReceivablesFilter)] } : {}),

          branchIds: branchReceivablesFilter?.length ? branchReceivablesFilter?.split(',')?.map(el => Number(el)) : [],
          officeIds: officeReceivablesFilter?.length ? officeReceivablesFilter?.split(',')?.map(el => Number(el)) : [],
          ...(agentReceivablesFilter ? { agentIds: [Number(agentReceivablesFilter)] } : {}),

          ...(watch('customerPaymentMethod') && watch('customerPaymentMethod') !== '0'
            ? { customerPaymentTypes: [Number(watch('customerPaymentMethod'))] }
            : {}),

          ...(!regAllDate && regOption !== '3' && regStartDate
            ? { createdFrom: Number(regStartDate), createdTo: Number(regEndDate) }
            : {}),
          ...(!paidAllDate && paidOption !== '3' && paidStartDate
            ? { paidFrom: Number(paidStartDate), paidTo: Number(paidEndDate) }
            : {}),
          isConvertingCreatedAtTimeNeeded: regOption === '5',
          isConvertingPaidAtTimeNeeded: paidOption === '5',
        };
      });
    }
  }, [
    collectionList,
    customerReceivablesFilter,
    regAllDate,
    mcReceivablesFilter,
    regOption,
    regStartDate,
    paidAllDate,
    paidEndDate,
    paidOption,
    paidStartDate,
    branchReceivablesFilter?.length,
    officeReceivablesFilter?.length,
    agentReceivablesFilter,
    watch('customerPaymentMethod'),
  ]);

  const [authoritiesState, setAuthoritiesState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });
  const { data: authorities } = useGetAuthoritiesShortQuery(authoritiesState, {
    skip: tab !== 'collection',
  });

  const { data: selectedMc } = useGetAuthorityQuery(mcReceivablesFilter, { skip: !mcReceivablesFilter });

  const [selectedMcOption, setSelectedMcOption] = useState<any>({});
  const [finalMcOptions, setFinalMcOptions] = useState<any>({ result: [], count: 0 });

  useEffect(() => {
    if (selectedMc?.result) {
      setSelectedMcOption([
        {
          name: selectedMc?.result?.name,
          id: selectedMc?.result?.id,
          mc: selectedMc?.result?.mc,
        },
      ]);
    }
  }, [selectedMc]);

  useEffect(() => {
    if (authorities && !selectedMcOption.id) {
      setFinalMcOptions(authorities);
    } else if (authorities) {
      setFinalMcOptions({
        result: [selectedMcOption, ...authorities.result.filter((el: any) => el.id !== selectedMcOption?.id)],
        count: authorities?.count,
      });
    }
  }, [selectedMcOption, authorities]);

  ////////////////////////// CUSTOMER FILTER
  const [customersState, setCustomersState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });
  const { data: customers = [] } = useGetCustomersQuery(customersState, {
    skip: tab !== 'collection',
  });

  const { data: selectedCustomer } = useGetDetailedCustomerQuery(customerReceivablesFilter, {
    skip: !customerReceivablesFilter,
  });

  const [selectedCustomerOption, setSelectedCustomerOption] = useState<any>({});
  const [finalCustomerOptions, setFinalCustomerOptions] = useState<any>({ result: [], count: 0 });

  useEffect(() => {
    if (selectedCustomer) {
      setSelectedCustomerOption([
        {
          id: selectedCustomer?.result?.id,
          mcID: selectedCustomer?.result?.mc,
          name: selectedCustomer?.result?.name,
        },
      ]);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (customers && !selectedCustomerOption.id) {
      setFinalCustomerOptions(customers);
    } else if (customers) {
      setFinalCustomerOptions({
        result: [selectedCustomerOption, ...customers.result.filter((el: any) => el.id !== selectedCustomerOption?.id)],
        count: customers?.count,
      });
    }
  }, [selectedCustomerOption, customers]);
  //////////////////////

  const [branchFilter, setBranchFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
    withDefaults: true,
  });

  const [officesFilter, setOfficesFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const [selectedOffices, setSelectedOffices] = useState<any>([]);
  const [finalOfficeOptions, setFinalOfficeOptions] = useState<any>({ result: [], count: 0 });

  const [selectedBranches, setSelectedBranches] = useState<any>([]);
  const [finalBranchesOptions, setFinalBranchesOptions] = useState<any>({ result: [], count: 0 });

  const { data: officesOptions = { result: [], count: 0 } } = useGetOfficesAutocompleteQuery(officesFilter, {
    skip: tab !== 'collection',
  });
  const { data: branchesData = { result: [], count: 0 } } = useGetBranchesAutocompleteQuery(
    { officeId: selectedOffices?.[0]?.id, ...branchFilter },
    {
      skip: !watch('receivablesOffice')?.length || watch('receivablesOffice')!.length > 1,
    }
  );

  ///////////////////// AGENT FILTER
  const [agentsFilter, setAgentsFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
    excludeOwners: true,
  });

  const { data: agentsOptions = { result: [], count: 0 } } = useGetAgentsAutocompleteQuery(
    {
      ...agentsFilter,
      branchId: watch('receivablesBranch')?.[0],
    },
    {
      skip: typeof watch('receivablesBranch') === 'string' || watch('receivablesBranch')?.length !== 1,
    }
  );

  const { data: selectedAgent } = useGetAgentQuery(agentReceivablesFilter, { skip: !agentReceivablesFilter });

  const [selectedAgentOption, setSelectedAgentOption] = useState<any>({});
  const [finalAgentOptions, setFinalAgentOptions] = useState<any>({ result: [], count: 0 });

  useEffect(() => {
    if (selectedAgent) {
      setSelectedAgentOption([
        {
          firstName: selectedAgent?.result?.firstName,
          lastName: selectedAgent?.result?.lastName,
          username: selectedAgent?.result?.username,
          id: selectedAgent?.result?.id,
          value: String(selectedAgent?.result?.id),
        },
      ]);
    }
  }, [selectedAgent]);

  useEffect(() => {
    if (agentsOptions && !selectedAgentOption.id) {
      setFinalAgentOptions(agentsOptions);
    } else if (agentsOptions) {
      setFinalAgentOptions({
        result: [selectedAgentOption, ...agentsOptions.result.filter((el: any) => el.id !== selectedAgentOption?.id)],
        count: agentsOptions?.count,
      });
    }
  }, [selectedAgentOption, agentsOptions?.result?.length]);
  ////////////////////

  useEffect(() => {
    if (Array.isArray(watch('receivablesBranch'))) {
      setSelectedBranches((prev: any) =>
        [
          ...prev.filter((el: any) => !branchesData?.result?.some((item: any) => item.value == el.value)),
          ...branchesData.result,
        ].filter((el: any) => watch('receivablesBranch')?.some((item: any) => item == el.value))
      );
    }
  }, [watch('receivablesBranch'), branchesData?.result?.length]);

  useEffect(() => {
    if (Array.isArray(watch('receivablesOffice'))) {
      setSelectedOffices((prev: any) =>
        [
          ...prev.filter((el: any) => !officesOptions?.result?.some((item: any) => item.value == el.value)),
          ...officesOptions.result,
        ].filter((el: any) => watch('receivablesOffice')?.some((item: any) => item == el.value))
      );
    }
  }, [watch('receivablesOffice'), officesOptions?.result?.length]);

  useEffect(() => {
    if (officesOptions?.result) {
      setFinalOfficeOptions({
        result: [
          ...selectedOffices,
          ...officesOptions.result.filter((el: any) => !selectedOffices.some((item: any) => item.value == el.value)),
        ],
        count: officesOptions.count,
      });
    } else {
      setFinalOfficeOptions(selectedOffices);
    }
  }, [selectedOffices, officesOptions?.result]);

  useEffect(() => {
    if (branchesData?.result?.length) {
      setFinalBranchesOptions({
        result: [
          ...selectedBranches,
          ...branchesData.result.filter((el: any) => !selectedBranches.some((item: any) => item.value == el.value)),
        ],
        count: branchesData.count,
      });
    } else {
      setFinalBranchesOptions({ result: [], count: 0 });
    }
  }, [selectedBranches?.length, branchesData?.result?.length]);
  ////////////

  const receivablesMc = watch('receivablesMc')?.[0]?.id ? watch('receivablesMc')?.[0]?.id : watch('receivablesMc');
  const customerReceivables = watch('receivablesCustomer')?.[0]?.id
    ? watch('receivablesCustomer')?.[0]?.id
    : watch('receivablesCustomer');

  const officeReceivables = watch('receivablesOffice');
  const branchReceivables = watch('receivablesBranch');
  const agentReceivables = watch('receivablesAgent')?.[0]?.id
    ? watch('receivablesAgent')?.[0]?.id
    : watch('receivablesAgent');

  const paymentMethod = watch('customerPaymentMethod');

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ...(receivablesMc ? { mcReceivablesFilter: receivablesMc } : { mcReceivablesFilter: '' }),
      ...(customerReceivables ? { customerReceivablesFilter: customerReceivables } : { customerReceivablesFilter: '' }),

      ...(officeReceivables
        ? { officeReceivablesFilter: Array.isArray(officeReceivables) ? officeReceivables?.join(',') : '' }
        : { officeReceivablesFilter: '' }),
      ...(branchReceivables
        ? { branchReceivablesFilter: Array.isArray(branchReceivables) ? branchReceivables?.join(',') : '' }
        : { branchReceivablesFilter: '' }),
      ...(agentReceivables ? { agentReceivablesFilter: agentReceivables } : { agentReceivablesFilter: '' }),

      ...(paymentMethod ? { paymentMethodReceivablesFilter: paymentMethod } : { paymentMethodReceivablesFilter: '' }),
    });
  }, [receivablesMc, customerReceivables, officeReceivables, branchReceivables, agentReceivables, paymentMethod]);

  useEffect(() => {
    if (watch('receivablesOffice')!.length !== 1) {
      setSearchParams({
        ...searchParams,
        branchReceivablesFilter: '',
        agentReceivablesFilter: '',
      });
      setValue('receivablesBranch', []);
      setValue('receivablesAgent', []);
      setSelectedAgentOption({});
    }
  }, [watch('receivablesOffice')]);

  useEffect(() => {
    if (watch('receivablesBranch')!.length !== 1) {
      setSearchParams({
        ...searchParams,
        agentReceivablesFilter: '',
      });
      setValue('receivablesAgent', []);
      setSelectedAgentOption({});
    }
  }, [watch('receivablesBranch')]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
    });
  }, []);

  useEffect(() => {
    const filterOptions = [
      { key: 'receivablesMc', value: mcReceivablesFilter },
      { key: 'receivablesCustomer', value: customerReceivablesFilter },
      {
        key: 'receivablesOffice',
        value: officeReceivablesFilter ? officeReceivablesFilter.split(',') : officeReceivablesFilter,
      },
      {
        key: 'receivablesBranch',
        value: branchReceivablesFilter ? branchReceivablesFilter.split(',') : branchReceivablesFilter,
      },
      { key: 'receivablesAgent', value: agentReceivablesFilter },
      { key: 'customerPaymentMethod', value: paymentMethodReceivablesFilter },
    ];

    filterOptions.forEach(({ key, value }) => {
      if (value) {
        setValue(key as keyof ICollectionFilter, value, { shouldDirty: true });
      }
    });
    setCollectionsList((prev: any) => {
      return {
        ...prev,
        skip: 0,
        limit: prev.limit,
        filter: {
          ...prev.filter,
        },
      };
    });
  }, [
    mcReceivablesFilter,
    customerReceivablesFilter,
    officeReceivablesFilter,
    branchReceivablesFilter,
    agentReceivablesFilter,
    paymentMethodReceivablesFilter,
    agentsOptions?.result?.length,
  ]);

  const handleResetFilters = () => {
    reset();
    setSearchParams({
      tab,
      open,
      mode,
      paidStartDate: '',
      paidEndDate: '',
      paidAllDate: '',
      paidOption: '4',
      regStartDate: '',
      regEndDate: '',
      regAllDate: '',
      regOption: '4',
    });
    setSelectedAgentOption({});
    setSelectedCustomerOption({});
    setSelectedMcOption({});
  };

  const handleRowClick = (rowId: string, { customerId, mcId }: { customerId: string; mcId: string }) => {
    setSearchParams({
      ...searchParams,
      mode: 'view',
      open: 'true',
      id: customerId,
      authorityId: mcId,
    });
    setValue('authorityIds', [String(mcId)]);
  };

  const handleReceivedInvoice = (loadId: number) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_INVOICE_CHECK,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.CANCEL,
        onOk: () => {
          markAsInvoiceReceived(String(loadId)).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: SUBJECT.SUCCESSFULLY_SENT,
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(closeModal());
            }
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleDownloadClick = (fileIds: number[], id: number) => {
    if (fileIds.length) {
      getLoadFilesByIds({ id, fileIds }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: SUBJECT.DOWNLOADING_IN_PROCCESS,
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          const files = data?.data?.result.map((file: any) => {
            return {
              download: file?.file?.download,
              id: file?.file?.id,
            };
          });
          multiplyDownloadWithDelay(files);
        }
      });
    }
  };

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setCollectionsList(prev => {
      if (sortOrder === 0)
        return {
          ...prev,
          order: 2,
          orderBy: 'aging',
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };
  const [customerParam, setCustomerParam] = useState({ skip: 0, limit: 10 });
  const handleRefetchCustomer = () => {
    getCustomerInfo({
      customerId: id,
      authorityIds: watch('authorityIds')?.map((el: any) => el.id),
      skip: customerParam.skip,
      limit: customerParam.limit,
    });
  };

  useEffect(() => {
    if (id && authorityId) {
      getCustomerInfo({
        customerId: id,
        authorityIds: watch('authorityIds')?.map((el: any) => el.id),
        skip: customerParam.skip,
        limit: customerParam.limit,
      });
    }
  }, [id, authorityId, watch('authorityIds')?.length]);

  const handlePaginationClick = (skip: number, limit: number) => {
    setCollectionsList(prev => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handlePayablePaginationClick = (skip: number, limit: number) => {
    getCustomerInfo({
      customerId: id,
      authorityIds: watch('authorityIds')?.map((el: any) => el.id),
      skip,
      limit,
    });
    setCustomerParam({ skip, limit });
  };
  const handleColumnSearch = (value: string, column: string) => {
    setCollectionsList((prev: any) => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const handleSendInvoice = (id: string | number) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.SEND,
        cancelText: SUBJECT.CANCEL,
        onOk: () => {
          sendInvoiceReceivables(id as string).then((data: any) => {
            if (data.error) {
              notification.error({
                message: data?.error?.data?.details?.[0] || ServerErrorCodes[0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully sent',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
            dispatch(closeModal());
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleCancel = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      authorityId: '',
      id: '',
    });
    resetField('authorityIds');
  };

  const handleStopResize = (columns: IColumn[]): void => {
    editColumns({ type: 'collection', columns });
  };

  const [dragColumns, setDragColumns] = useState<any>([]);

  useEffect(() => {
    if (columnsData?.columns.length) {
      setDragColumns(columnsData?.columns);
    }
  }, [columnsData?.columns]);

  const handleCloseModal = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
    });
    dispatch(closeModal());
  };

  const { isSuccess, setIsSuccess, defaultColumnsData, handleResetToDefault, onCustomizeSubmit, setIsDragged } =
    useResetColumns({
      setDragColumns,
      deleteColumns,
      editColumns,
      handleCloseModal,
      dragColumns,
      type: 'collection',
    });

  const drawerChildren: IChildren = {
    customize: {
      component: (
        <CustomColumn
          columns={columnsData?.columns}
          isDefault={columnsData?.isDefault}
          dragColumns={dragColumns}
          setDragColumns={setDragColumns}
          handleResetToDefault={handleResetToDefault}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          defaultColumnsData={defaultColumnsData}
          setIsDragged={setIsDragged}
          onCustomizeSubmit={onCustomizeSubmit}
          isLoading={isLoadingColumnsEdit || isLoadingColumnsDelete}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: onCustomizeSubmit,
    },
    view: {
      component: (
        <CollectionView
          data={customerById}
          isLoading={isLoadingById}
          tableData={customerInfo!}
          isReceiveLoading={isReceiveLoading}
          getCustomerInfo={getCustomerInfo}
          resetField={resetField}
          watch={watch}
          setValue={setValue}
          control={control}
          pagePermissions={collectionPagePermissions}
          handleRefetchCustomer={handleRefetchCustomer}
          handleDownloadClick={handleDownloadClick}
          handlePaginationClick={handlePayablePaginationClick}
        />
      ),
      onCancel: handleCancel,
    },
  };

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren[mode]?.component,
      buttonText: drawerChildren[mode]?.buttonText,
      onCancel: drawerChildren[mode]?.onCancel,
      onSubmit: drawerChildren[mode]?.onSubmit,
      withFooter: mode !== 'view',
    };
  }, [
    mode,
    open,
    columnsData,
    columnsData?.columns,
    dragColumns,
    columnsData?.isDefault,
    isSuccess,
    id,
    authorityId,
    customerById,
    customerInfo,
    isLoadingById,
    isLoadingColumnsEdit,
    isLoadingColumnsDelete,
  ]);

  useEffect(() => {
    if (data?.result) {
      dispatch(setButtonVisibility({ visible: true }));
    } else {
      dispatch(setButtonVisibility({ visible: false }));
    }
  }, [data?.result]);

  return {
    isLoading,
    data,
    columns: columnsData?.columns,
    handleSortClick,
    handleRowClick,
    currentChildren,
    control,
    handleResetFilters,
    watch,
    setValue,
    handlePaginationClick,
    isDirty,
    regOption,
    paidOption,
    handleSendInvoice,
    handleStopResize,
    skip: collectionList.skip,
    handleDownloadClick,
    limit: collectionList.limit,
    setCustomersState,
    handleReceivedInvoice,
    setAuthoritiesState,
    offices: finalOfficeOptions,
    branches: finalBranchesOptions,
    setAgentsFilter,
    setOfficesFilter,
    setBranchFilter,
    selectedBranches,
    selectedOffices,
    agentReceivablesFilter,
    finalMcOptions,
    selectedMcOption,
    finalCustomerOptions,
    selectedCustomerOption,
    selectedAgentOption,
    finalAgentOptions,
    handleColumnSearch,
  };
};

export default useCollection;
