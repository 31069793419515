import { IOptions, IPermissionListTableColumns, IPermissionTypes } from './types';

export enum permissionEditTitles {
  profiles = 'Profiles',
  loads = 'Loads',
  payroll = 'Agency Finance',
  creditcheck = 'Credit check',
  contact = 'Contact',
  factoring = 'Accounting',
  truckboard = 'Truck board',
  permission = 'Permission',
}
export enum SUBJECT {
  ARE_YOU_SURE_DELETE = 'Are You Sure Want To Delete Truck From Favorite List ?',
  SUCCESSFULLY_ADDED = 'Successfully Added To Favorite',
  CANCEL = 'Cancel',
  DELETE = 'Delete',
  PERMISSION_GROUP = 'Permission Group',
  NOT_SELECTED = 'Not Selected',
  SAVE = 'Save',
  UNSAVED_CHANGES = 'You Have Unsaved Changes',
  TERMINATE_ON = 'After Activating The Permission Group, All Employees With This Permissions Can Use The Application',
  TERMINATE_OFF = 'After Deactivating The Permission Group, All Employees With This Permissions Will Lose The Access Of The Application',
  ACTIVATE = 'Activate',
  DEACTIVATE = 'Deactivate',
  CLOSE = 'Close',
  EDIT_BTN_TEXT = 'Save Changes',
  SUPER_ADMIN = 'Super Admin',
  EDIT = 'Edit',
  PERMISSION = 'Permission',
  PROFILE = 'Profiles',
  LOADS = 'Loads',
  AGENTS = 'Agents',
  OFFICES = 'Agency',
  BRANCHES = 'Branches',
  AUTHORITIES = 'Authorities',
  CARRIER_TABLE = 'Carrier Table',
  CARRIER_REQUEST = 'Carrier Request',
  CARRIER_BLACK_LIST = 'Carrier Black List',
  CUSTOMER_TABLE = 'Customer Table',
  CUSTOMER_BLACK_LIST = 'Customer Black List',
  EMPLOYEES = 'Employees',
  ACTION = 'Action',
  VIEW = 'View',
  CREATE = 'Create, Edit',
  PAYROLL = 'Payroll',
  CREDIT_CHECK = 'Credit Check',
  CONTACT = 'Contact',
  FACTORING = 'Factoring',
  TRUCK_BOARD = 'Truck Board',
  RESET_ALL = 'Reset All',
  RESET = 'Reset',
  SOMETHING_WENT_WRONG = 'Something Went Wrong',
  SUCCESSFULLY_UPDATED = 'Successfully Updated',
  SUCCESSFULLY_DELETED = 'Successfully Deleted',
  SUCCESSFULLY_CREATED = 'Successfully Created',
  SUCCESSFULLY_EDITED = 'Successfully Edited',
  SUCCESSFULLY_ACTIVATED = 'Successfully Activated',
  SUCCESSFULLY_DEACTIVATED = 'Successfully Deactivated',
  STAY_HERE = 'Stay Here',
}

export enum VALIDATION_MESSAGES {
  PERMISSION_GROUP_REQUIRED = 'Permission Group Is a Required Field',
}

export const MOCK_DATA_PERMISSION = {
  terminate: true,
};

export const PERMISSION_LIST_TYPE_OPTIONS: IOptions[] = [
  {
    title: 'Super Admin',
    value: 'Super admin',
  },
  {
    title: 'Agency',
    value: 'Agency',
  },
  {
    title: 'Branch',
    value: 'Branch',
  },
];

export const filterDefaultValues = {
  permissionListType: null,
};

export const PERMISSION_LIST_TABLE_COLUMNS: IPermissionListTableColumns[] = [
  {
    title: 'Section',
    id: 'section',
    dataIndex: 'section',
    displayType: 'text',
    isVisible: true,
    isSearchable: false,
    sortable: false,
  },
];

export const getPermissionListTableData = (
  type: any,
  billing: any,
  payables: any,
  receivables: any,
  accountCollection: any
) => {
  if (type === 0) {
    return [
      {
        id: 1,
        section: 'Profiles',
        deserialized: 'profiles',
      },
      {
        id: 2,
        section: 'Loads',
        deserialized: 'loads',
      },
      {
        id: 3,
        section: 'Agency Finance',
        deserialized: 'payroll',
      },
      {
        id: 4,
        section: 'Credit Check',
        deserialized: 'creditcheck',
      },
      {
        id: 5,
        section: 'Contact',
        deserialized: 'contact',
      },
      {
        id: 6,
        section: 'Accounting',
        deserialized: 'factoring',
      },
      {
        id: 7,
        section: 'Truck Board',
        deserialized: 'truckboard',
      },
    ];
  } else if (type === 1) {
    return [
      {
        id: 1,
        section: 'Profiles',
        deserialized: 'profiles',
      },
      {
        id: 2,
        section: 'Loads',
        deserialized: 'loads',
      },
      {
        id: 3,
        section: 'Agency Finance',
        deserialized: 'payroll',
      },

      {
        id: 5,
        section: 'Contact',
        deserialized: 'contact',
      },
      ...(billing.view || payables.view || receivables.view || accountCollection?.view
        ? [
            {
              id: 6,
              section: 'Accounting',
              deserialized: 'factoring',
            },
          ]
        : []),
      {
        id: 7,
        section: 'Truck Board',
        deserialized: 'truckboard',
      },
    ];
  } else if (type === 2) {
    return [
      {
        id: 1,
        section: 'Profiles',
        deserialized: 'profiles',
      },
      {
        id: 2,
        section: 'Loads',
        deserialized: 'loads',
      },
      {
        id: 3,
        section: 'Agency Finance',
        deserialized: 'payroll',
      },
      {
        id: 5,
        section: 'Contact',
        deserialized: 'contact',
      },
      {
        id: 7,
        section: 'Truck Board',
        deserialized: 'truckboard',
      },
    ];
  }
};

export const profiles: any = {
  agent: {
    actions: [],
    permissions: [],
  },
  office: {
    actions: [],
    permissions: [],
  },
  branch: {
    actions: [],
    permissions: [],
  },
  authority: {
    actions: [],
    permissions: [],
  },
  employee: {
    actions: [],
    permissions: [],
  },
  carrierTable: {
    actions: [],
    permissions: [],
  },
  carrierOnBoard: {
    actions: [],
    permissions: [],
  },
  carrierRequest: {
    actions: [],
    permissions: [],
  },
  carriersBlackList: {
    actions: [],
    permissions: [],
  },

  customersTable: {
    actions: [],
    permissions: [],
  },
  customersBlackList: {
    actions: [],
    permissions: [],
  },
  factoringCompanies: {
    actions: [],
    permissions: [],
  },
};
export const loads: any = {
  load: {
    actions: [],
    permissions: [],
  },
  newLoad: {
    permissions: [],
  },
};
export const payroll: any = {
  payrollLoad: {
    actions: [],
    permissions: [],
  },
  payrollPotentialGrossProfit: {
    actions: [],
    permissions: [],
  },
  payrollGrossProfit: {
    actions: [],
    permissions: [],
  },
  payrollServiceFee: {
    actions: [],
    permissions: [],
  },
  payrollLpf: {
    actions: [],
    permissions: [],
  },
  payrollCredit: {
    actions: [],
    permissions: [],
  },
  payrollCharge: {
    actions: [],
    permissions: [],
  },
  payrollOtherServiceFee: {
    actions: [],
    permissions: [],
  },
  payrollAdvance: {
    actions: [],
    permissions: [],
  },
  payrollCollection: {
    actions: [],
    permissions: [],
  },
  payrollPayout: {
    actions: [],
    permissions: [],
  },
  payrollCreditCheck: {
    actions: [],
    permissions: [],
  },
  payrollSoftwareFee: {
    actions: [],
    permissions: [],
  },
  payrollFactoringFee: {
    actions: [],
    permissions: [],
  },
};
export const creditcheck: any = {
  newCredit: {
    actions: [],
    permissions: [],
  },
  mcCustomer: {
    permissions: [],
  },
};
export const contact: any = {
  contact: {
    actions: [],
    permissions: [],
  },
};
export const factoring: any = {
  billing: {
    actions: [],
    permissions: [],
  },
  payable: {
    actions: [],
    permissions: [],
  },
  receivable: {
    actions: [],
    permissions: [],
  },
  accountCollection: {
    actions: [],
    permissions: [],
  },
};
export const truckboard: any = {
  truckBoard: {
    actions: [],
    permissions: [],
  },
  truckBoardCoordinator: {
    actions: [],
    permissions: [],
  },
};
export const permission: any = {
  permission: {
    actions: [],
    permissions: [],
  },
};

export const defaultPermissionsValues = {
  profiles,
  loads,
  payroll,
  creditcheck,
  contact,
  factoring,
  truckboard,
  permission,
};

const TYPES = [
  { value: 'view', title: 'View' },
  { value: 'actions', title: 'Actions' },
];

export const defaultPermissionsOptions = [
  { value: 'view', title: 'View' },
  { value: 'actions', title: 'Actions' },
];

export const onlyViewPermissionsOptions = [{ value: 'view', title: 'View' }];

export const PERMISSIONS_TYPES: IPermissionTypes = {
  profiles: {
    agent: TYPES,
    office: TYPES,
    branch: TYPES,
    authority: TYPES,
    carrierTable: TYPES,
    carrierRequest: TYPES,
    carriersBlackList: TYPES,
    carrierOnBoard: TYPES,
    customersTable: TYPES,
    customersBlackList: TYPES,
    employee: TYPES,
    factoringCompanies: TYPES,
  },
  loads: {
    load: TYPES,
    newLoad: [
      { value: 'view', title: 'View' },
      { value: 'download', title: 'Download' },
    ],
  },
  payroll: {
    payrollLoad: TYPES,
    payrollGrossProfit: TYPES,
    payrollPotentialGrossProfit: TYPES,
    payrollServiceFee: TYPES,
    payrollLpf: [{ value: 'view', title: 'View' }],
    payrollCredit: TYPES,
    payrollCharge: TYPES,
    payrollOtherServiceFee: TYPES,
    payrollAdvance: TYPES,
    payrollCollection: TYPES,
    payrollPayout: TYPES,
    payrollCreditCheck: [{ value: 'view', title: 'View' }],
    payrollSoftwareFee: [{ value: 'view', title: 'View' }],
    payrollFactoringFee: [{ value: 'view', title: 'View' }],
  },
  creditcheck: {
    newCredit: TYPES,
    mcCustomer: [
      { value: 'view', title: 'View' },
      { value: 'download', title: 'Download' },
    ],
  },
  contact: {
    contact: TYPES,
  },
  factoring: {
    billing: TYPES,
    payable: TYPES,
    receivable: TYPES,
    accountCollection: TYPES,
  },
  truckboard: {
    truckBoard: TYPES,
    truckBoardCoordinator: TYPES,
    truckBoardCarrierRequest: TYPES,
  },
  permission: {
    permission: TYPES,
  },
};

export const permissions = (type: any, outside: boolean | undefined, hasLPF: boolean): any => {
  if (type === 0) {
    return {
      profiles: [
        { id: 'agent', name: 'Agents' },
        { id: 'office', name: 'Agency' },
        { id: 'branch', name: 'Branches' },
        { id: 'authority', name: 'Authorities' },
        { id: 'employee', name: 'Employees' },
        { id: 'carrierTable', name: 'Carrier Table' },
        { id: 'carrierOnBoard', name: 'Request From Carrier' },
        { id: 'carrierRequest', name: 'Request From Agent' },
        { id: 'carriersBlackList', name: 'Carrier Black List' },
        { id: 'customersTable', name: 'Customer Table' },
        { id: 'customersBlackList', name: 'Customer Black List' },
        { id: 'factoringCompanies', name: 'Factoring Companies' },
      ],
      loads: [{ id: 'load', name: 'Loads' }],
      creditcheck: [
        { id: 'newCredit', name: 'New Credit' },
        { id: 'mcCustomer', name: 'Mc And Customers' },
      ],
      payroll: [
        { id: 'payrollLoad', name: 'Loads' },
        { id: 'payrollPotentialGrossProfit', name: 'Potential Gross Profit' },
        { id: 'payrollGrossProfit', name: 'Gross Profit' },
        { id: 'payrollServiceFee', name: 'Service Fee' },
        { id: 'payrollLpf', name: 'LPF' },
        { id: 'payrollCredit', name: 'Credit' },
        { id: 'payrollCharge', name: 'Charges' },
        { id: 'payrollOtherServiceFee', name: 'Other Service Fee' },
        { id: 'payrollAdvance', name: 'Advance' },
        { id: 'payrollCollection', name: 'Collection' },
        { id: 'payrollPayout', name: 'Payment' },
        { id: 'payrollCreditCheck', name: 'Credit Check' },
        { id: 'payrollSoftwareFee', name: 'Software Fee' },
        { id: 'payrollFactoringFee', name: 'Factoring Fee' },
      ],
      contact: [{ id: 'contact', name: 'Contact' }],
      factoring: [
        { id: 'billing', name: 'Billing' },
        { id: 'payable', name: 'Payables' },
        { id: 'receivable', name: 'Receivables' },
        { id: 'accountCollection', name: 'Collection' },
      ],
      truckboard: [
        { id: 'truckBoard', name: 'Truck Board' },
        { id: 'truckBoardCoordinator', name: 'Verification Board' },
      ],
    };
  } else if (type === 1) {
    return {
      profiles: [
        { id: 'agent', name: 'Agents' },
        { id: 'branch', name: 'Branches' },
        { id: 'authority', name: 'Authorities' },
        { id: 'employee', name: 'Employees' },
      ],
      loads: [
        { id: 'load', name: 'Loads' },
        { id: 'newLoad', name: 'New Loads' },
      ],
      payroll: outside
        ? [
            { id: 'payrollLoad', name: 'Loads' },
            { id: 'payrollPotentialGrossProfit', name: 'Potential Gross Profit' },
            { id: 'payrollGrossProfit', name: 'Gross Profit' },
            { id: 'payrollServiceFee', name: 'Service Fee' },
            ...(hasLPF ? [{ id: 'payrollLpf', name: 'LPF' }] : []),
            { id: 'payrollCredit', name: 'Credit' },
            { id: 'payrollCharge', name: 'Charges' },
            { id: 'payrollOtherServiceFee', name: 'Other Service Fee' },
            { id: 'payrollAdvance', name: 'Advance' },
            { id: 'payrollCollection', name: 'Collection' },
            { id: 'payrollPayout', name: 'Payment' },
            { id: 'payrollCreditCheck', name: 'Credit Check' },
            ...(!hasLPF ? [{ id: 'payrollSoftwareFee', name: 'Software Fee' }] : []),
          ]
        : [
            { id: 'payrollLoad', name: 'Loads' },
            { id: 'payrollPotentialGrossProfit', name: 'Potential Gross Profit' },
            { id: 'payrollGrossProfit', name: 'Gross Profit' },
            { id: 'payrollServiceFee', name: 'Service Fee' },
            ...(hasLPF ? [{ id: 'payrollLpf', name: 'LPF' }] : []),
            { id: 'payrollCredit', name: 'Credit' },
            { id: 'payrollCharge', name: 'Charges' },
            { id: 'payrollOtherServiceFee', name: 'Other Service Fee' },
            { id: 'payrollAdvance', name: 'Advance' },
            { id: 'payrollCollection', name: 'Collection' },
            { id: 'payrollPayout', name: 'Payment' },
            ...(!hasLPF ? [{ id: 'payrollSoftwareFee', name: 'Software Fee' }] : []),
          ],
      contact: [{ id: 'contact', name: 'Contact' }],
      factoring: [
        { id: 'billing', name: 'Billing' },
        { id: 'payable', name: 'Payables' },
        { id: 'receivable', name: 'Receivables' },
        { id: 'accountCollection', name: 'Collection' },
      ],
      truckboard: [
        { id: 'truckBoard', name: 'Truck board' },
        // { id: 'truckBoardCoordinator', name: 'Verification board' },
      ],
    };
  } else if (type === 2) {
    return {
      profiles: [
        { id: 'agent', name: 'Agents' },
        { id: 'authority', name: 'Authorities' },
        { id: 'employee', name: 'Employees' },
      ],
      loads: [
        { id: 'load', name: 'Loads' },
        { id: 'newLoad', name: 'New Loads' },
      ],
      payroll: [
        { id: 'payrollLoad', name: 'Loads' },
        { id: 'payrollPotentialGrossProfit', name: 'Potential Gross Profit' },
        { id: 'payrollGrossProfit', name: 'Gross Profit' },
        { id: 'payrollCredit', name: 'Credit' },
        { id: 'payrollCharge', name: 'Charges' },
        { id: 'payrollOtherServiceFee', name: 'Other Service Fee' },
        { id: 'payrollAdvance', name: 'Advance' },
        { id: 'payrollCollection', name: 'Collection' },
        { id: 'payrollPayout', name: 'Payment' },
      ],
      contact: [{ id: 'contact', name: 'Contact' }],
      truckboard: [
        { id: 'truckBoard', name: 'Truck Board' },
        // { id: 'truckBoardCoordinator', name: 'Verification board' },
      ],
    };
  } else if (type === 'all') {
    return {
      profiles: [
        { id: 'agent', name: 'Agents' },
        { id: 'office', name: 'Agency' },
        { id: 'branch', name: 'Branches' },
        { id: 'authority', name: 'Authorities' },
        { id: 'employee', name: 'Employees' },
        { id: 'carrierTable', name: 'Carrier Table' },
        { id: 'carrierOnBoard', name: 'Request From Carrier' },
        { id: 'carrierRequest', name: 'Request From Agent' },
        { id: 'carriersBlackList', name: 'Carrier Black List' },
        { id: 'customersTable', name: 'Customer Table' },
        { id: 'customersBlackList', name: 'Customer Black List' },
        { id: 'factoringCompanies', name: 'Factoring Companies' },
      ],
      loads: [
        { id: 'load', name: 'Loads' },
        { id: 'newLoad', name: 'New Loads' },
      ],
      payroll: [
        { id: 'payrollLoad', name: 'Loads' },
        { id: 'payrollPotentialGrossProfit', name: 'Potential Gross Profit' },
        { id: 'payrollGrossProfit', name: 'Gross Profit' },
        { id: 'payrollServiceFee', name: 'Service Fee' },
        { id: 'payrollLpf', name: 'LPF' },
        { id: 'payrollCredit', name: 'Credit' },
        { id: 'payrollCharge', name: 'Charges' },
        { id: 'payrollOtherServiceFee', name: 'Other Service Fee' },
        { id: 'payrollAdvance', name: 'Advance' },
        { id: 'payrollCollection', name: 'Collection' },
        { id: 'payrollPayout', name: 'Payment' },
        { id: 'payrollCreditCheck', name: 'Credit Check' },
        { id: 'payrollSoftwareFee', name: 'Software Fee' },
        { id: 'payrollFactoringFee', name: 'Factoring Fee' },
      ],
      creditcheck: [
        { id: 'newCredit', name: 'New Credit' },
        { id: 'mcCustomer', name: 'Mc And Customers' },
      ],
      contact: [{ id: 'contact', name: 'Contact' }],
      factoring: [
        { id: 'billing', name: 'Billing' },
        { id: 'payable', name: 'Payables' },
        { id: 'receivable', name: 'Receivables' },
        { id: 'accountCollection', name: 'Collection' },
      ],
      truckboard: [
        { id: 'truckBoard', name: 'Truck Board' },
        { id: 'truckBoardCoordinator', name: 'Verification Board' },
      ],
    };
  }
};

export const profilesTabs = (type: any) => permissions(type, true, true)!.profiles?.map((el: any) => el.id);
export const loadsTabs = (type: any) => permissions(type, true, true)!.loads?.map((el: any) => el.id);
export const payrollTabs = (type: any) => permissions(type, true, true)!.payroll?.map((el: any) => el.id);
export const creditcheckTabs = (type: any) => permissions(type, true, true)!.creditcheck?.map((el: any) => el.id);
export const contactTabs = (type: any) => permissions(type, true, true)!.contact?.map((el: any) => el.id);
export const factoringTabs = (type: any) => permissions(type, true, true)!.factoring?.map((el: any) => el.id);
export const truckboardTabs = (type: any) => permissions(type, true, true)!.truckboard?.map((el: any) => el.id);

export const permissionDeserializer: Record<string, string> = {
  receiveInvoice: 'Receive Invoice',
  factoringCompanies: 'Factoring Companies',
  addCoordinator: 'Add Coordinator',
  deleteCoordinator: 'Delete Coordinator',
  updateOtherCoordinatorLocation: 'Update Other Coordinator Location',
  updateSelfLocation: 'Update Self Location',
  updateUnassignedLocation: 'Update Unassigned location',
  payrollAdvance: 'Advance',
  payrollCharge: 'Charges',
  payrollCollection: 'Collection',
  payrollCredit: 'Credit',
  payrollCreditCheck: 'Credit check',
  payrollGrossProfit: 'Gross Profit',
  payrollLoad: 'Loads',
  payrollLpf: 'LPF',
  payrollSoftwareFee: 'Software fee',
  payrollFactoringFee: 'Factoring Fee',
  payrollOtherServiceFee: 'Other Service Fee',
  payrollPotentialGrossProfit: 'Potential Gross Profit',
  payrollServiceFee: 'Service fee',
  createDistribute: 'Create Distribute',
  editDistribute: 'Edit Distribute',
  chargeToCollection: 'Move To Collection',
  movingToNextMonth: 'Move To Next Month',
  turnOnCharge: 'Turn On Charge',
  turnOffCharge: 'Turn Off Charge',
  givePaymentPlan: 'Give Payment Plan',
  editPaymentPlan: 'Edit Payment Plan',
  deletePaymentPlan: 'Delete Payment Plan',
  payPositiveBalance: 'Pay Positive Balance',
  payNegativeBalance: 'Pay Negative Balance',
  agent: 'Agents',
  office: 'Agency',
  branch: 'Branches',
  authority: 'Authorities',
  carrierTable: 'Carrier Table',
  carrierRequest: 'Request From Agent',
  carriersBlackList: 'Carrier Black List',
  carrierOnBoard: 'Request From Carrier',
  customersTable: 'Customer Table',
  customersBlackList: 'Customer Black List',
  employee: 'Employees',
  load: 'Loads',
  newLoad: 'New Load',
  payrollInformation: 'Information',
  payrollPayout: 'Payment',
  newCredit: 'New Credit',
  mcCustomer: 'MC And Customers',
  contact: 'Contact',
  billing: 'Billing',
  payable: 'Payables',
  receivable: 'Receivables',
  accountCollection: 'Collection',
  truckBoard: 'Truck board',
  truckBoardCoordinator: 'Verification Board',
  truckBoardCarrierRequest: 'Truck Board Request',
  view: 'View',
  actions: 'Actions',
  download: 'Download',
  create: 'Create',
  edit: 'Edit',
  terminateOn: 'Terminated On',
  terminateOff: 'Terminated Off',
  deleteComment: 'Delete Comment',
  approve: 'Approve',
  lock: 'Add Black List',
  unlock: 'Unlock',
  deny: 'Deny',
  acceptToBilling: 'Accept To Billing (Create Invoice)',
  addPickUpDropOff: 'Add Pick Ups And Drop Offs',
  cancel: 'Cancel Load',
  claim: 'Claim Load',
  editPickUpDropOff: 'Edit Pick Ups And Drop Offs',
  editReferenceNumber: 'Change Reference Number',
  editSoldAsMc: 'Can Edit Sold as MC',
  filesDelete: 'Files Delete',
  reject: 'Reject (Send To No Processing)',
  resolve: 'Resolve Load (Unclaim)',
  sendToBilling: 'Send To Billing',
  transaction: 'Transaction',
  transactionDelete: 'Transaction Delete',
  uploadsFiles: 'Upload Files',
  //
  activeOtherServiceFee: 'Activate Other Service Fee',
  createAdvance: 'Create Advance',
  createOtherServiceFee: 'Create Other Service Fee',
  deleteAdvance: 'Delete Advance',
  deleteOtherServiceFee: 'Delete Other Service Fee',
  distribute: 'Distribute',
  editAdvance: 'Edit Advance',
  editOtherServiceFee: 'Edit Other Service Fee',
  passivateOtherServiceFee: 'Passivate Other Service See',
  //
  payOffice: 'Pay Agency',
  payBranch: 'Pay Branch',
  payAgent: 'Pay Agent',
  //
  cod: 'COD',
  consignment: 'Consignment',
  //
  claimLoadAcceptToBilling: 'Claim Load Accept To Billing',
  claimLoadReject: 'Claim Load Reject',
  //
  pay: 'Pay',
  claimLoadPay: 'Claim Load Pay',
  //
  receive: 'Receive',
  claimLoadReceive: 'Claim Load Receive',
  moveToCollection: 'Move To Collection',
  //
  createSearch: 'Create Search',
  //
  coordinator: 'Coordinator',
  delete: 'Delete',
};
