import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import useResetColumns from 'hooks/useResetColumns';
import { IBracket } from 'services/profile/agents/interfaces';
import {
  useAddNewFileToAuthorityMutation,
  useChangeStatusMutation,
  useCreateAutorityMutation,
  useDeleteFileFromAuthorityMutation,
  useGetAuthoritiesQuery,
  useGetAuthoritiesTemplatesQuery,
  useGetAuthorityQuery,
  useGetFactoringCompaniesAutocompleteForAuthorityQuery,
  useUpdateAuthorityMutation,
} from 'services/profile/authorities/authorities';
import { useUploadFileNOAMutation } from 'services/profile/authorities/authorities-file';
import { IGetAuthoritiesParams } from 'services/profile/authorities/interfaces';
import { useGetOfficeQuery, useGetOfficesAutocompleteQuery } from 'services/profile/offices/offices';
import { useGetStatesQuery } from 'services/states/states';
import { useDeleteColumnsMutation, useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { setButtonVisibility } from 'store/add-button-slice/addButton';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';
import { notificationKey } from 'utils/constants';
import { FILE_MAX_SIZE_ERROR, ServerErrorCodes } from 'utils/errors';
import { formatPhone, isFileAllowedToBeUpload, multiplyDownloadWithDelay } from 'utils/helpers';
import { IChildren } from 'utils/types';

import CustomColumn from 'components/custom-column/CustomColumn';
import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { usePermittedActions } from '../../../../hooks/usePermittedActions';

import AuthoritiesInfo from './components/authorities-info/AuthoritiesInfo';
import CreateAuthority from './components/create-authority/CreateAuthority';
import EditAuthority from './components/edit-authority/EditAuthority';
import {
  AuthorityStatusTypes,
  defaultBracketValues,
  defaultValues,
  filterDefaultValues,
  SUBJECT,
} from './constants/constants';
import {
  AuthorityFileTypes,
  IAuthoritiesFilter,
  IAuthoritiesFormData,
  IAuthoritiesParams,
  IAuthoritiesTableColumns,
} from './constants/types';
import { bracketValidation, validation } from './constants/validation';

const useAuthorities = ({ setExcelParams }: IAuthoritiesParams) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { searchParams, setSearchParams } = useDetectedParams();
  const { mode, type, officeId, id, authorityUsed, open } = searchParams;

  const {
    permissionsInfo: { roleLevel },
    userType,
  } = useSelector(selectUserPermissionsInfo);

  const [getAuthoritiesFilter, setGetAuthoritiesFilter] = useState<Partial<IGetAuthoritiesParams>>({
    search: '',
    field: '',
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: 'createdAt',
    filter: {},
  });

  const [officesFilter, setOfficesFilter] = useState<Partial<IGetAuthoritiesParams>>({
    search: '',
    skip: 0,
    limit: 10,
  });
  const { data: filterOfficeData } = useGetOfficesAutocompleteQuery(officesFilter);
  const { data: selectedOffice } = useGetOfficeQuery(officeId, { skip: !officeId });

  const [selectedOfficeOption, setSelectedOfficeOption] = useState<any>({});
  const [finalOfficeOptions, setFinalOfficeOptions] = useState<any>({ result: [], count: 0 });

  useEffect(() => {
    setSelectedOfficeOption({
      title: selectedOffice?.result.name,
      id: selectedOffice?.result.id,
      value: String(selectedOffice?.result.id),
    });
  }, [selectedOffice]);

  useEffect(() => {
    if (filterOfficeData && !selectedOfficeOption.id) {
      setFinalOfficeOptions(filterOfficeData);
    } else if (filterOfficeData) {
      setFinalOfficeOptions({
        result: [
          selectedOfficeOption,
          ...filterOfficeData.result.filter((el: any) => el.id !== selectedOfficeOption.id),
        ],
        count: filterOfficeData?.count,
      });
    }
  }, [selectedOfficeOption, filterOfficeData]);

  const { data: columnsData } = useGetColumnsQuery({ type: 'authorities' });
  const [editColumns, { isLoading: isLoadingColumnsEdit }] = useEditColumnsMutation();
  const [deleteColumns, { isLoading: isLoadingColumnsDelete }] = useDeleteColumnsMutation();

  const [changeStatus] = useChangeStatusMutation();

  const [editAuthority, { isLoading: isEditLoading }] = useUpdateAuthorityMutation();
  const [createAuthority, { isLoading: isCreateLoading }] = useCreateAutorityMutation();

  const [uploadFileNOA] = useUploadFileNOAMutation();
  const [addNewFile] = useAddNewFileToAuthorityMutation();
  const [deleteFile] = useDeleteFileFromAuthorityMutation();

  const { currentData: authorityById, isFetching: isFetchingById } = useGetAuthorityQuery(id, { skip: !id });

  const { data: statesData } = useGetStatesQuery({}, { skip: mode === 'view' });
  const { data = [], isFetching, refetch: refetchAuthoritiesList } = useGetAuthoritiesQuery(getAuthoritiesFilter);

  const { data: templates, isFetching: isTemplatesFetching } = useGetAuthoritiesTemplatesQuery(
    {},
    { skip: open !== 'true' }
  );

  const [allFiles, setAllFiles] = useState<any>({ file: [], packet: [], broker: [] });

  const savedFiles = useMemo(() => {
    return {
      files:
        authorityById?.result?.files?.map((el: any) => ({
          fullName: `${el.creator.username}`,
          fileName: el.name,
          createdAt: el.createdAt,
          id: el.id,
          preview: el.preview,
          download: el.download,
        })) || [],
      packet:
        authorityById?.result?.packetFiles?.map((el: any) => ({
          fullName: `${el.creator.username}`,
          fileName: el.name,
          createdAt: el.createdAt,
          id: el.id,
          preview: el.preview,
          download: el.download,
        })) || [],
      broker:
        authorityById?.result?.brokerAgreementFiles?.map((el: any) => ({
          fullName: `${el.creator.username}`,
          fileName: el.name,
          createdAt: el.createdAt,
          id: el.id,
          preview: el.preview,
          download: el.download,
        })) || [],
    };
  }, [authorityById?.result?.files, authorityById?.result?.packetFiles, authorityById?.result?.brokerAgreementFiles]);

  const userInfo = useSelector(selectUserInfo);

  const resetOfficeParams = () => {
    setSearchParams({
      ...searchParams,
      officeId: '',
    });

    setFilterValue('officeId', '');
    setOfficesFilter(prev => ({ ...prev, limit: 10, search: '' }));
    setGetAuthoritiesFilter((prev: any) => {
      const {
        filter: { officeId: _, ...rest },
      } = prev;

      return { ...prev, skip: 0, filter: { ...rest } };
    });
  };

  useEffect(() => {
    if (selectedOffice?.result?.name) {
      setOfficesFilter(prev => ({ ...prev }));
    }
  }, [selectedOffice]);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors },
    clearErrors,
    getValues,
    watch,
    reset,
    setValue,
    trigger,
    resetField,
  } = useForm<IAuthoritiesFormData>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validation),
  });

  const [factoringCompaniesAutocompleteFilter, setFactoringCompaniesAutocompleteFilter] = useState<Partial<any>>({
    skip: 0,
    limit: 10,
    search: '',
  });
  const { data: factoringCompaniesAutocomplete } = useGetFactoringCompaniesAutocompleteForAuthorityQuery(
    factoringCompaniesAutocompleteFilter,
    { skip: !watch('factoring') || Number(watch('factoring')) !== 1 }
  );
  console.log(factoringCompaniesAutocomplete, 'factoringCompaniesAutocomplete');

  const [brackets, setBrackets] = useState<IBracket[]>([]);
  const [deletedIndex, setDeletedIndex] = useState<number | null>(null);

  const {
    handleSubmit: handleBracketSubmit,
    control: bracketControl,
    getValues: getBracketValues,
    watch: watchBrackets,
    formState: { isValid: isBracketValid, errors: bracketErrors, isDirty: isBracketDirty },
    reset: resetBrackets,
    setValue: setBracketsValue,
  } = useForm<IBracket>({
    defaultValues: defaultBracketValues,
    mode: 'onBlur',
    resolver: yupResolver(bracketValidation(brackets)),
  });

  const onBracketSubmit = useCallback(() => {
    if (brackets?.length) {
      setBrackets(prev => [...prev, watchBrackets()]);
      setValue('brackets', [...brackets, watchBrackets()]);
    } else {
      setBrackets([watchBrackets()]);
      setValue('brackets', [watchBrackets()]);
    }
    setTimeout(() => {
      clearErrors('brackets');
    }, 0);
  }, [watchBrackets()]);

  useEffect(() => {
    resetBrackets(defaultBracketValues);
  }, [mode, open]);

  const handleDeleteBracketRow = (index: number) => {
    setDeletedIndex(index);
    setBrackets(prev => prev.filter((item, currentIndex) => currentIndex !== index));
    dispatch(closeModal());
    setValue(
      'brackets',
      brackets?.filter((item, currentIndex) => currentIndex !== index),
      { shouldDirty: true }
    );
  };

  const handleDeleteBracket = (index: number) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          handleDeleteBracketRow(index);
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const lastIndex = brackets?.length - 1;
  const lastItem = brackets?.[lastIndex];

  useEffect(() => {
    if (brackets?.length) {
      resetBrackets({
        percent: null,
        from: Number(lastItem!.to!) + 1,
        to: null,
      });
      setBrackets(
        brackets?.map((item, index) => {
          if (deletedIndex === brackets?.length) {
            return {
              ...item,
            };
          }
          if (deletedIndex && deletedIndex <= brackets?.length && brackets[index] === brackets[deletedIndex! - 1]) {
            return {
              ...item,
              to: Number(brackets[deletedIndex!]!.from!) - 1,
            };
          }
          return item;
        })
      );
    } else {
      resetBrackets(defaultBracketValues);
    }
    setBrackets(prev =>
      prev?.map((item, index) => {
        if (index === 0) {
          return {
            ...item,
            from: 0,
          };
        }
        return item;
      })
    );
  }, [brackets?.length]);
  console.log(watch('factoringCompanyId'), 'jeloo');

  useEffect(() => {
    if (mode === 'edit') {
      reset({
        name: authorityById?.result.name,
        authority_type: authorityById?.result?.type,
        mc: Number(authorityById?.result.mc),
        phone: formatPhone(authorityById?.result.phone),
        dot: authorityById?.result?.dot || null,
        ffNumber: authorityById?.result?.ff || null,
        stateNumber: authorityById?.result?.stateNumber || null,
        fax: authorityById?.result.fax,
        email: authorityById?.result?.email,
        address: authorityById?.result.address,
        secondAddress: authorityById?.result?.secondAddress || '',
        city: authorityById?.result.city,
        stateId: authorityById?.result?.state?.id,
        zip: authorityById?.result.zip,
        files: savedFiles.files,
        packet: savedFiles.packet,
        broker: savedFiles.broker || [],
        bankName: authorityById?.result?.bankName,
        accounting_number: authorityById?.result?.accountingNumber,
        ach_routing_number: authorityById?.result?.achRoutingNumber,
        physicalSecondAddress: authorityById?.result?.physicalSecondAddress || '',
        physicalAddress: authorityById?.result?.physicalAddress || '',
        physicalCity: authorityById?.result?.physicalCity || '',
        physicalStateId: authorityById?.result?.physicalStateId || '',
        physicalZip: authorityById?.result?.physicalZip || null,
        physical: authorityById?.result?.physical === 1 ? true : false,
        templateId: authorityById?.result?.templateId,
        privacyPolicy: authorityById?.result?.rateConfirmationDetails || '',
        brackets: authorityById?.result?.brackets?.map((item: IBracket) => {
          return {
            to: item?.to,
            from: item?.from,
            percent: item?.percent,
          };
        }),
        lpf: authorityById?.result?.lpf,
        factoring: authorityById?.result?.factoringCompanyId ? '1' : '2',
        factoringCompanyId: authorityById?.result?.factoringCompanyId
          ? ([
              {
                id: authorityById?.result?.factoringCompanyId,
                companyId: authorityById?.result?.factoringCompanyId,
                name: authorityById?.result?.factoringCompanyName,
                type: 2,
              },
            ] as any)
          : null,
        aging030: authorityById?.result?.agings?.find((el: any) => el.from === 0)?.percent || null,
        aging3160: authorityById?.result?.agings?.find((el: any) => el.from === 31)?.percent || null,
        aging6190: authorityById?.result?.agings?.find((el: any) => el.from === 61)?.percent || null,
      });
      setBrackets(
        authorityById?.result?.brackets?.map((item: IBracket) => {
          return {
            to: item.to,
            from: item.from,
            percent: item.percent,
          };
        })
      );
      trigger(['files', 'packet', 'broker']);
      setAllFiles(savedFiles);
    } else {
      reset(defaultValues);
      setBrackets([]);
      setAllFiles({ files: [], packet: [], broker: [] });
    }
  }, [authorityById, savedFiles, mode, id]);

  const {
    formState: { isDirty: isFilterDirty },
    control: filterControl,
    reset: resetFilterForm,
    setValue: setFilterValue,
    watch: watchFilter,
  } = useForm<IAuthoritiesFilter>({
    defaultValues: filterDefaultValues,
    mode: 'onChange',
  });

  const authoritiesTypeFilter = watchFilter('type')?.toString();
  const usedFilter = watchFilter('used').toString();
  const officeFilterValue = watchFilter('officeId');

  const handleUsedFilter = (type: string | number) => {
    if (type != authorityUsed) {
      setSearchParams({
        ...searchParams,
        authorityUsed: String(type),
      });
    }
  };

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ...(usedFilter ? { authorityUsed: usedFilter } : { authorityUsed: '' }),
      ...(authoritiesTypeFilter ? { type: authoritiesTypeFilter } : {}),
      ...(officeId ? { officeId } : {}),
    });
  }, [authoritiesTypeFilter, usedFilter, officeId]);

  useEffect(() => {
    const filterOptions = [
      { key: 'type', value: type },
      { key: 'used', value: authorityUsed },
      { key: 'officeId', value: officeId },
    ];

    filterOptions.forEach(({ key, value }) => {
      if (value) {
        setFilterValue(key as keyof IAuthoritiesFilter, value);
        setGetAuthoritiesFilter(prev => ({
          ...prev,
          skip: 0,
          filter: {
            ...(officeId && officeId ? { officeId } : {}),
            ...(type && type !== '0' ? { type: type } : {}),
            ...(authorityUsed && authorityUsed !== '0' ? { used: authorityUsed } : {}),
          },
        }));
      }
    });
  }, [authorityUsed, type, officeId]);

  const authoritiesPagePermissions = usePermittedActions('profiles.authorities');

  const handleOfficeFilterSelect = (item: any) => {
    setSearchParams({
      ...searchParams,
      authorityUsed: authorityUsed === '2' ? '' : authorityUsed || '',
      officeId: item.id,
    });
  };

  const resetForm = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      id: '',
    });
    reset(defaultValues, { keepDirty: false });
    setAllFiles({ files: [], packet: [], broker: [] });
    clearErrors();
  };

  const onSubmit = (data: IAuthoritiesFormData) => {
    if (!isCreateLoading && mode === 'add') {
      createAuthority({ ...data, brackets }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully created',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          resetForm();
        }
      });
    } else if (mode === 'edit' && !isEditLoading) {
      editAuthority({ id, ...data, brackets }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully edited',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          resetForm();
          refetchAuthoritiesList();
        }
      });
    }
  };

  const handleStopResize = (columns: IAuthoritiesTableColumns[]): void => {
    editColumns({ type: 'authorities', columns });
  };

  const [dragColumns, setDragColumns] = useState<any>([]);

  useEffect(() => {
    if (columnsData?.columns.length) {
      setDragColumns(columnsData?.columns);
    }
  }, [columnsData?.columns]);

  const handleResetFilters = () => {
    resetFilterForm();
    setSearchParams({
      ...searchParams,
      officeId: '',
      type: '',
      authorityUsed: '',
    });
    setOfficesFilter({
      search: '',
      skip: 0,
      limit: 10,
    });
    setGetAuthoritiesFilter(prev => ({
      search: prev.search,
      skip: prev.skip,
      limit: prev.limit,
      order: prev.order,
      orderBy: prev.orderBy,
      filter: {},
    }));
  };

  const handleRowClick = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      ...(authoritiesTypeFilter ? { type: authoritiesTypeFilter } : {}),
      mode: 'view',
      open: 'true',
      id: rowId,
    });
  };

  const handleRowEdit = (rowId: number | string) => {
    setSearchParams({
      ...searchParams,
      mode: 'edit',
      open: 'true',
      id: String(rowId),
    });
  };

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setGetAuthoritiesFilter(prev => {
      if (sortOrder === 0)
        return {
          ...prev,
          order: 2,
          orderBy: 'createdAt',
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setGetAuthoritiesFilter(prev => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const handleSwitchClick = (checked: boolean, rowId: number | string) => {
    dispatch(
      openModal({
        title: !checked ? SUBJECT.CHECKED_DEACTIVATE : SUBJECT.CHECKED_ACTIVATE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'gray',
        okButtonVariant: checked ? 'modalPrimary' : 'danger',
        onOk: () => {
          changeStatus({
            id: rowId,
            status: checked
              ? AuthorityStatusTypes.AUTHORITY_STATUS_TYPES_ACTIVE
              : AuthorityStatusTypes.AUTHORITY_STATUS_TYPES_INACTIVE,
          }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 1,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: checked ? SUBJECT.SUCCESSFULLY_ACTIVATED : SUBJECT.SUCCESSFULLY_DEACTIVATED,
                duration: 1,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleDownloadClick = (rowId: number | string) => {
    const currentElement = data.result.find((el: any) => el.id === rowId);
    multiplyDownloadWithDelay(currentElement.files);
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setGetAuthoritiesFilter(prev => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleCloseModal = () => {
    setSearchParams({
      ...searchParams,
      ...(authoritiesTypeFilter ? { type: authoritiesTypeFilter } : {}),
      open: 'false',
    });
    resetForm();
    resetBrackets(defaultBracketValues);
    dispatch(closeModal());
  };

  const { isSuccess, setIsSuccess, defaultColumnsData, handleResetToDefault, onCustomizeSubmit, setIsDragged } =
    useResetColumns({
      setDragColumns,
      deleteColumns,
      editColumns,
      handleCloseModal,
      dragColumns,
      type: 'authorities',
    });

  const handleCancel = () => {
    if (isDirty || isBracketDirty) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: isValid && brackets?.length ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CLOSE,
          onOk:
            isValid && brackets?.length
              ? () => {
                  onSubmit(watch());
                  dispatch(closeModal());
                  clearErrors();
                }
              : () => {
                  dispatch(closeModal());
                },
          onCancel: handleCloseModal,
        })
      );
    } else {
      setSearchParams({
        ...searchParams,
        ...(authoritiesTypeFilter ? { type: authoritiesTypeFilter } : {}),
        open: 'false',
        id: '',
      });
      if (mode === 'edit') {
        resetForm();
      }
    }
  };

  const [localFiles, setLocalFiles] = useState<Array<any>>([]);

  const beforeUploadForCreate = (file: any, ctx: any, setLoading: any) => {
    if (isFileAllowedToBeUpload(file?.size)) {
      const formData = new FormData();
      formData.append('files', file);
      formData.append('type', '1');
      uploadFileNOA(formData)
        .then((data: any) => {
          if (data?.error) {
            notification.error({
              message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
              duration: 3,
              icon: <DangerIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
          } else {
            setLocalFiles(prev => [file, ...prev]);
            setValue(ctx, getValues(ctx) ? [...getValues(ctx), ...data!.data!.result!.ids] : data!.data!.result!.ids, {
              shouldDirty: true,
            });
            trigger(ctx);
            setAllFiles((prev: any) => {
              prev[ctx]?.unshift({
                fullName: `${userInfo?.username}`,
                fileName: file?.name,
                id: data?.data?.result?.ids?.[0],
                createdAt: new Date(),
                uid: file?.uid,
              });
              return {
                ...prev,
              };
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
      return false;
    } else {
      notification.error({
        message: FILE_MAX_SIZE_ERROR,
        duration: 3,
        icon: <DangerIcon />,
        placement: 'topRight',
        closeIcon: <CloseIcon />,
        key: notificationKey,
        btn: (
          <button type="button" onClick={() => notification.destroy(notificationKey)}>
            <CloseIcon />
          </button>
        ),
      });
    }
  };

  const beforeUploadForEdit = (file: any, ctx: any, setLoading: any) => {
    if (isFileAllowedToBeUpload(file?.size)) {
      const formData = new FormData();
      formData.append('files', file);
      formData.append('type', '1');
      uploadFileNOA(formData)
        .then((data: any) => {
          if (data?.error) {
            notification.error({
              message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
              duration: 3,
              icon: <DangerIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
          } else {
            addNewFile({
              id,
              fileId: data?.data?.result?.ids?.[0],
              type:
                ctx === 'files'
                  ? AuthorityFileTypes.NOA
                  : ctx === 'packet'
                  ? AuthorityFileTypes.PACKET
                  : AuthorityFileTypes.BROKER,
            }).then(() => {
              setLocalFiles(prev => [file, ...prev]);
              setValue(
                ctx,
                getValues(ctx) ? [...getValues(ctx), ...data!.data!.result!.ids] : data!.data!.result!.ids,
                {
                  shouldDirty: true,
                }
              );
              setAllFiles((prev: any) => {
                prev[ctx]?.unshift({
                  fullName: `${userInfo.firstName} ${userInfo.lastName}`,
                  fileName: file.name,
                  id: data?.data?.result?.ids?.[0],
                  createdAt: new Date(),
                  uid: file?.uid,
                });
                return {
                  ...prev,
                };
              });
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
      return false;
    } else {
      notification.error({
        message: FILE_MAX_SIZE_ERROR,
        duration: 3,
        icon: <DangerIcon />,
        placement: 'topRight',
        closeIcon: <CloseIcon />,
        key: notificationKey,
        btn: (
          <button type="button" onClick={() => notification.destroy(notificationKey)}>
            <CloseIcon />
          </button>
        ),
      });
    }
  };

  const handleDeleteAuthorityFileEdit = (fileId: number, ctx: any) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          deleteFile({
            id,
            fileId,
            type:
              ctx === 'files'
                ? AuthorityFileTypes.NOA
                : ctx === 'packet'
                ? AuthorityFileTypes.PACKET
                : AuthorityFileTypes.BROKER,
          }).then(() => {
            setAllFiles((prev: any) => {
              const updated = prev[ctx].filter((el: any) => el.id !== fileId);
              return {
                ...prev,
                [ctx]: updated,
              };
            });
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleDeleteAuthorityFileCreate = (fileId: number, ctx: any) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          setValue(
            ctx,
            getValues(ctx).filter((el: any) => el !== fileId)
          );
          trigger(ctx);
          setAllFiles((prev: any) => {
            const updated = prev[ctx].filter((el: any) => el.id !== fileId);
            return {
              ...prev,
              [ctx]: updated,
            };
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleDownloadAuthorityFileCreate = (file: any) => {
    const fileToDownload = localFiles.find(el => el.uid === file.uid);
    const blob = new Blob([fileToDownload]);

    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');

    downloadLink!.href = url;
    downloadLink.download = fileToDownload!.name;

    // Programmatically click the link to trigger the download
    downloadLink.click();

    // Clean up the temporary URL
    window.URL.revokeObjectURL(url);
  };

  const handleDownloadAuthorityFileEdit = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.substring(url.lastIndexOf('/') + 1);
    link.click();
  };
  const handleViewAuthorityFileEdit = (url: string) => {
    window.open(`${url}&response-content-disposition=inline`, '_blank');
  };

  const handleViewAuthorityFileCreate = (file: any) => {
    const fileToView = localFiles.find(el => el.uid === file.uid);
    if (fileToView) {
      const blob = new Blob([fileToView], { type: fileToView.type });

      const url = window.URL.createObjectURL(blob);

      window.open(url, '_blank');

      window.URL.revokeObjectURL(url);
    }
  };

  useEffect(() => {
    if (setExcelParams) {
      setExcelParams((prev: any) => {
        return {
          ...prev,
          ...getAuthoritiesFilter,
        };
      });
    }
  }, [getAuthoritiesFilter]);

  useEffect(() => {
    if (userInfo.officeIds && userType !== 1) {
      if (officeId && !userInfo?.officeIds?.some(el => el === Number(officeId))) {
        setTimeout(() => {
          navigate({ pathname: '/not-found' });
        }, 0);
      }
    }
  }, [userInfo]);

  const drawerChildren: IChildren = {
    customize: {
      component: (
        <CustomColumn
          columns={columnsData?.columns}
          isDefault={columnsData?.isDefault}
          dragColumns={dragColumns}
          setDragColumns={setDragColumns}
          handleResetToDefault={handleResetToDefault}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          defaultColumnsData={defaultColumnsData}
          setIsDragged={setIsDragged}
          onCustomizeSubmit={onCustomizeSubmit}
          isLoading={isLoadingColumnsEdit || isLoadingColumnsDelete}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: onCustomizeSubmit,
    },
    add: authoritiesPagePermissions.create
      ? {
          component: (
            <CreateAuthority
              watch={watch}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              getValues={getValues}
              control={control}
              errors={errors}
              trigger={trigger}
              mode={mode}
              statesData={statesData}
              beforeUpload={beforeUploadForCreate}
              allFiles={allFiles}
              authorityId={id}
              handleDeleteAuthorityFile={handleDeleteAuthorityFileCreate}
              handleDownloadAuthorityFile={handleDownloadAuthorityFileCreate}
              handleViewAuthorityFileCreate={handleViewAuthorityFileCreate}
              setValue={setValue}
              isCreateLoading={isCreateLoading}
              templates={templates}
              factoringCompaniesAutocomplete={factoringCompaniesAutocomplete}
              setFactoringCompaniesAutocompleteFilter={setFactoringCompaniesAutocompleteFilter}
              brackets={brackets}
              bracketErrors={bracketErrors}
              getBracketValues={getBracketValues}
              setBracketsValue={setBracketsValue}
              bracketControl={bracketControl}
              handleBracketSubmit={handleBracketSubmit}
              onBracketSubmit={onBracketSubmit}
              isBracketValid={isBracketValid}
              handleDeleteBracket={handleDeleteBracket}
              resetField={resetField}
            />
          ),
          buttonText: SUBJECT.BTN_TEXT,
          onCancel: handleCancel,
          onSubmit: handleSubmit(onSubmit),
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
    edit: authoritiesPagePermissions.edit
      ? {
          component: (
            <EditAuthority
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              getValues={getValues}
              control={control}
              errors={errors}
              trigger={trigger}
              mode={mode}
              data={authorityById}
              statesData={statesData}
              beforeUpload={beforeUploadForEdit}
              allFiles={allFiles}
              authorityId={id}
              handleDeleteAuthorityFile={handleDeleteAuthorityFileEdit}
              handleDownloadAuthorityFile={handleDownloadAuthorityFileEdit}
              handleViewAuthorityFileEdit={handleViewAuthorityFileEdit}
              handleDownloadNewFile={handleDownloadAuthorityFileCreate}
              handleViewNewFile={handleViewAuthorityFileCreate}
              watch={watch}
              setValue={setValue}
              isFetchingById={isFetchingById}
              isEditLoading={isEditLoading}
              isCreateLoading={isCreateLoading}
              templates={templates}
              factoringCompaniesAutocomplete={factoringCompaniesAutocomplete}
              setFactoringCompaniesAutocompleteFilter={setFactoringCompaniesAutocompleteFilter}
              brackets={brackets}
              bracketErrors={bracketErrors}
              getBracketValues={getBracketValues}
              setBracketsValue={setBracketsValue}
              bracketControl={bracketControl}
              handleBracketSubmit={handleBracketSubmit}
              onBracketSubmit={onBracketSubmit}
              isBracketValid={isBracketValid}
              handleDeleteBracket={handleDeleteBracket}
              resetField={resetField}
            />
          ),
          buttonText: SUBJECT.EDIT_BTN_TEXT,
          onCancel: handleCancel,
          onSubmit: handleSubmit(onSubmit),
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
    view: {
      component: (
        <AuthoritiesInfo
          data={authorityById}
          isFetchingById={isFetchingById}
          pagePermissions={authoritiesPagePermissions}
        />
      ),
      onCancel: handleCancel,
    },
  };

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren[mode]?.component,
      buttonText: drawerChildren[mode]?.buttonText,
      onCancel: drawerChildren[mode]?.onCancel,
      onSubmit: drawerChildren[mode]?.onSubmit,
      withFooter: mode !== 'view',
    };
  }, [
    mode,
    errors,
    isDirty,
    authorityById?.result,
    watch(),
    watchBrackets(),
    brackets?.length,
    statesData,
    allFiles,
    dragColumns,
    isEditLoading,
    isCreateLoading,
    isTemplatesFetching,
    isLoadingColumnsEdit,
    isLoadingColumnsDelete,
  ]);

  useEffect(() => {
    if (data?.result?.length) {
      dispatch(setButtonVisibility({ visible: true }));
    } else {
      dispatch(setButtonVisibility({ visible: false }));
    }
  }, [data?.result?.length]);

  const [visibleFilter, setVisibleFilter] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (officeFilterValue) {
      setTimeout(() => {
        setVisibleFilter(true);
      }, 500);
    } else {
      setVisibleFilter(false);
    }
  }, [officeFilterValue, visibleFilter]);

  useEffect(() => {
    if (authorityUsed || officeFilterValue) {
      setTimeout(() => {
        setVisible(true);
      }, 500);
    } else {
      setVisible(false);
    }
  }, [authorityUsed, officeFilterValue, visible]);

  return {
    visibleFilter,
    visible,
    filterControl,
    data,
    columns: columnsData?.columns,
    isFetching,
    type,
    handleRowClick,
    handleRowEdit,
    handleSortClick,
    handleColumnSearch,
    handleSwitchClick,
    handlePaginationClick,
    handleStopResize,
    handleResetFilters,
    currentChildren,
    isFilterDirty,
    filterOfficeData: finalOfficeOptions,
    setOfficesFilter,
    officesFilter,
    skip: getAuthoritiesFilter.skip,
    limit: getAuthoritiesFilter.limit,
    handleOfficeFilterSelect,
    setFilterValue,
    handleUsedFilter,
    authorityUsed,
    handleDownloadClick,
    resetOfficeParams,
    officeId,
    officeFilterValue,
    authoritiesPagePermissions,
    roleLevel,
  };
};

export default useAuthorities;
