import { withController } from 'ui/inputs/hoc/Controller';
import { ISelectProps } from 'ui/inputs/select/constants/types';
import ReusableSelect from 'ui/inputs/select/Select';
import { ISearchableDropdownProps } from 'ui/searchable-dropdown/constants/types';
import CustomSearchableDropdown from 'ui/searchable-dropdown/SearchableDropdown';

import { ICollectionFilter, IReceivablesDataGridFilter } from '../constants/types';

export const FilterSelect = withController<ISelectProps, ICollectionFilter>(ReusableSelect);

export const DataGridFilterSelect = withController<ISelectProps, IReceivablesDataGridFilter>(ReusableSelect);
export const SearchableDropdown = withController<ISearchableDropdownProps, any>(CustomSearchableDropdown);
