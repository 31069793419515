import { BillingTypesText } from 'services/profile/customers/interfaces';
import { formatDate } from 'utils/dates';
import { changeTimeZone, checkIsNumberDecimal, formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import { ICollection, ICollectionsData, ICustomerInfo, IGetCustomerInfo } from './interface';

export const getCollectionsSerializer = (body: ICollectionsData) => {
  const result = body?.result?.map((item: ICollection) => {
    return {
      id: item?.collection?.id,
      acceptBillingTimeAt: formatDate(item?.collection?.acceptBillingTimeAt as Date),
      loadId: item?.collection?.id,
      loadStatus: {
        processing: item?.collection?.processing,
        status: item?.collection?.status || 0,
        carrierStatus: item?.collection?.carrierStatus,
        customerStatus: item?.collection?.customerStatus,
      },
      dataType: 'receivables',
      refNumber: item?.collection?.referenceNumber,
      aging: item?.collection?.aging + 1,
      additionalAging: item?.collection?.additionalAging,
      factoringFee: item?.collection?.factoringFee || '',
      paymentTermDay: item?.collection?.paymentTermDay,
      paymentMethod: BillingTypesText[item?.collection?.paymentTermType],
      paymentPercent: item?.collection?.paymentTermPercent,
      customerId: item?.collection?.customer?.id,
      customerName: item?.collection?.customer?.name,
      customerFinalAmount: formatNumberWithThousandsSeparatorAndDecimal(item?.collection?.customerFinalAmount),
      customerBalance: formatNumberWithThousandsSeparatorAndDecimal(item?.collection?.customerBalance),
      carrierName: item?.collection?.carrier?.companyName,
      carrierFinalAmount: formatNumberWithThousandsSeparatorAndDecimal(item?.collection?.carrierFinalAmount),
      carrierAmount: formatNumberWithThousandsSeparatorAndDecimal(item?.collection?.carrierAmount),
      mcNumber: item?.collection?.authority?.mc,
      mcId: item?.collection?.authority?.id,
      invoiceIds: item.invoiceIds,
      invoice: formatDate(item?.invoiceDate as Date),
      invoiceReceivedByUser: item?.invoiceReceivedByUser?.username,
      sendInvoice: item?.collection?.customerStatus,
      proccesing: !!item?.collection?.processing,
      lastComment: item?.comment?.message,
      claimed: item?.collection?.claimed,
      loadType: item?.collection?.loadType,
      invoiceReceivedAt: item?.collection?.invoiceReceivedAt,
      code: item?.collection?.code,
      agencyName: item?.collection?.office?.name,
      branchName: item?.collection?.branch?.name,
      agentName: `${item?.collection?.creator?.username}`,
      takenAs: item?.collection?.authority?.name,
      moveDate: formatDate(item?.collection?.movedToCollectionAt),
      movedBy: item?.movedByUser?.username,
    };
  });

  return {
    result,
    count: body?.count,
    expectedCustomAmount: body?.expectedCustomAmount,
    expectedFactoringFeeAmount: body?.expectedFactoringFeeAmount,
    expectedTotalAmount: body?.expectedTotalAmount,
    expectedTotalCount: body?.expectedTotalCount,
    receivedCustomAmount: body?.receivedCustomAmount,
    receivedFactoringFeeAmount: body?.receivedFactoringFeeAmount,
    receivedTotalAmount: body?.receivedTotalAmount,
    receivedTotalCount: body?.receivedTotalCount,
  };
};

export const getCustomerInfoSerializer = (body: IGetCustomerInfo) => {
  const result = body?.result?.map((info: ICustomerInfo) => {
    return {
      keyType: 'receivables',
      id: info?.id,
      key: info?.id,
      code: info?.code,
      regDate: formatDate(info?.createdAt as Date),
      loadId: info?.id,
      customerBalance: checkIsNumberDecimal(info?.customerBalance),
      customerReceivable: info?.customerFinalAmount,
      customerDept: checkIsNumberDecimal(info?.customerDeptAmount),
      mc: info?.authority?.name,
      payment: info?.havePayment,
      status: info?.customerStatus,
      loadType: info?.type,
      invoice: {
        date: formatDate(info?.invoiceCreatedAt as Date),
        ids: info?.invoiceFileIds,
      },
      claimed: info?.claimed,
      refNumber: info?.referenceNumber,
      takenAs: info?.authority?.name,
    };
  });

  return {
    result,
    expected: checkIsNumberDecimal(body?.expected),
    received: checkIsNumberDecimal(body?.received),
    count: body?.count,
  };
};

export const getCollectionsParamsSerializer = (params: any) => {
  const queryParams = new URLSearchParams();
  if (params.search) {
    queryParams.append('search', String(params.search));
  }
  if (params.field) {
    queryParams.append('field', String(params.field));
  }
  if (String(params.skip)) {
    queryParams.append('skip', String(params.skip));
  }
  if (params.limit) {
    queryParams.append('limit', String(params.limit));
  }
  if (params.order) {
    queryParams.append('order', String(params.order));
  }
  if (params.orderBy) {
    queryParams.append('orderBy', String(params.orderBy));
  }
  if (params.filter.createdFrom) {
    queryParams.append(
      'createdFrom',
      params.filter?.isConvertingCreatedAtTimeNeeded
        ? String(changeTimeZone(new Date(Number(params.filter?.createdFrom)), 'America/Los_Angeles'))
        : String(params.filter?.createdFrom)
    );
  }
  if (params.filter.createdTo) {
    queryParams.append(
      'createdTo',
      params.filter?.isConvertingCreatedAtTimeNeeded
        ? String(changeTimeZone(new Date(Number(params.filter?.createdTo)), 'America/Los_Angeles'))
        : String(params.filter?.createdTo)
    );
  }
  if (params.filter.paidFrom) {
    queryParams.append(
      'paidFrom',
      params.filter?.isConvertingPaidAtTimeNeeded
        ? String(changeTimeZone(new Date(Number(params.filter?.paidFrom)), 'America/Los_Angeles'))
        : String(params.filter?.paidFrom)
    );
  }
  if (params.filter.paidTo) {
    queryParams.append(
      'paidTo',
      params.filter?.isConvertingPaidAtTimeNeeded
        ? String(changeTimeZone(new Date(Number(params.filter?.paidTo)), 'America/Los_Angeles'))
        : String(params.filter?.paidTo)
    );
  }
  if (params.filter?.officeIds?.length) {
    params.filter?.officeIds?.forEach((el: string | number) => queryParams.append('officeIds[]', String(el)));
  }
  if (params.filter?.branchIds?.length) {
    params.filter?.branchIds?.forEach((el: string | number) => queryParams.append('branchIds[]', String(el)));
  }
  if (params.filter?.agentIds?.length) {
    params.filter?.agentIds?.forEach((el: string | number) => queryParams.append('agentIds[]', String(el)));
  }
  if (params.filter?.customerIds?.length) {
    params.filter?.customerIds?.forEach((el: string | number) => queryParams.append('customerIds[]', String(el)));
  }
  if (params.filter?.authorityIds?.length) {
    params.filter?.authorityIds?.forEach((el: string | number) => queryParams.append('authorityIds[]', String(el)));
  }
  if (params.filter?.customerPaymentTypes?.length) {
    params.filter?.customerPaymentTypes?.forEach((el: string | number) =>
      queryParams.append('customerPaymentTypes[]', String(el))
    );
  }
  return queryParams;
};
