export interface IFilter {
  [key: string]: string;
}

export interface IGetOfficesParams {
  search: string;
  field: string;
  skip: number;
  limit: number;
  order: number;
  orderBy: string;
  filter: IFilter;
  ignoreAuthorityIds?: any[];
}

export interface IAuthorityByOfficeId {
  id: number;
  mc: string;
  name: string;
  type: number;
  createdAt?: number;
  companyName?: string;
}
export interface IDepthByCustomerAndAuthority {
  result: {
    depth: number;
    limit?: number;
    count?: number;
  };
}
export interface IDepthByCustomerAndAuthorityModified {
  result: {
    depth: string;
    limit: string | null;
    count?: number;
  };
}

export enum OfficeSetUpTypes {
  OFFICE_SET_UP_TYPES_UNSPECIFIED = 0,
  OFFICE_SET_UP_TYPES_INSIDE = 1,
  OFFICE_SET_UP_TYPES_OUTSIDE = 2,
}

export enum OfficeSetUpDisplayTypes {
  'Agency' = 1,
  'Broker' = 2,
}

export interface OfficePermissions {
  billingPermission: BillingPermission;
  payablePermission: PayablePermission;
  receivablePermission: ReceivablesPermission;
  accountCollectionPermission: AccountCollectionPermission;
}

export enum OfficeCreditCheckChargeTypes {
  OFFICE_CREDIT_CHECK_CHARGE_TYPES_UNSPECIFIED = 0,
  OFFICE_CREDIT_CHECK_CHARGE_TYPES_MONTH = 1,
  OFFICE_CREDIT_CHECK_CHARGE_TYPES_UNIT = 2,
}

export interface BillingPermission {
  view: boolean;
  download: boolean;
  actions: BillingActions;
  bothActions?: boolean;
}

export interface PayablePermission {
  view: boolean;
  download: boolean;
  actions: PayableActions;
  bothActions?: boolean;
}

export interface ReceivablesPermission {
  view: boolean;
  download: boolean;
  actions: ReceivablesActions;
  bothActions?: boolean;
}

export interface AccountCollectionPermission {
  view: boolean;
  download: boolean;
  actions: ReceivablesActions;
  bothActions?: boolean;
}

export interface PayableActions {
  pay: boolean;
  claimLoadPay: boolean;
  receiveInvoice: boolean;
}

export interface BillingActions {
  acceptToBilling: boolean;
  reject: boolean;
}

export interface ReceivablesActions {
  receive: boolean;
  claimLoadReceive: boolean;
  moveToCollection: boolean;
}

export interface AccountCollectionActions {
  receive: boolean;
  claimLoadReceive: boolean;
}
