import { UserTypes } from 'utils/constants';

import { IAgentType, IRoleLevel } from '../../services/truck-board/carrier-request/interfaces';

import { employeeLevelSubTabs, paymentLevelSubTabs, staticPermissions } from './constants';

export const permissionsSerializer = (permissionResponse: any) => {
  const data = permissionResponse?.permissions;
  const userType = permissionResponse?.type;
  const agentType = permissionResponse?.agentType;
  const employeeLevel = permissionResponse?.employeeLevel;
  const isOutside = !!permissionResponse?.outside;

  const result = {
    ...data,
    permissions: {
      profile: {
        visibleTabs: [],
        carrier: { visibleSubTabs: [] },
        customer: { visibleSubTabs: [] },
        employee: { visibleSubTabs: [] },
      },
      loads: { visibleTabs: [] },
      payroll: { visibleTabs: [], informationCards: [], payment: { visibleSubTabs: [] } },
      'credit-check': { visibleTabs: [] },
      contact: { visibleTabs: [] },
      factoring: { visibleTabs: [] },
      'truck-board': { visibleTabs: [] },
      permission: { visibleTabs: [] },
    },
    routes: [],
    allPossibleTabs: [],
    allPossibleSubTabs: [],
    roleLevel: 0,
  };
  if (userType == 2) {
    for (const key in data?.permissions) {
      if (
        key === 'agent' ||
        key === 'office' ||
        key === 'branch' ||
        key === 'authority' ||
        key === 'employee' ||
        key === 'factoringCompanies'
      ) {
        result.permissions.profile[key] = { ...result.permissions.profile[key], ...data?.permissions?.[key] };
        if (result.permissions.profile[key].view) {
          result.permissions.profile.visibleTabs.push(key);
        }
      } else if (
        key === 'carrierTable' ||
        key === 'carriersBlackList' ||
        key === 'carrierRequest' ||
        key === 'carrierOnBoard'
      ) {
        result.permissions.profile.carrier = {
          ...result.permissions.profile.carrier,
          [key]: data?.permissions?.[key],
        };
        if (result.permissions.profile.carrier[key].view) {
          result.permissions.profile.visibleTabs.push('carrier');
          result.permissions.profile.carrier.visibleSubTabs.push(key);
          result.allPossibleSubTabs.push(key);
        }
      } else if (key === 'customersTable' || key === 'customersBlackList') {
        result.permissions.profile.customer = {
          ...result.permissions.profile.customer,
          [key]: data?.permissions?.[key],
        };
        if (result.permissions.profile.customer[key].view) {
          result.permissions.profile.visibleTabs.push('customer');
          result.permissions.profile.customer.visibleSubTabs.push(key);
          result.allPossibleSubTabs.push(key);
        }
      } else if (key === 'load' || key === 'newLoad') {
        result.permissions.loads[key] = data?.permissions?.[key];
        if (result.permissions.loads[key].view) {
          result.permissions.loads.visibleTabs.push(key);
        }
      } else if (
        key === 'payrollAdvance' ||
        key === 'payrollCharge' ||
        key === 'payrollCollection' ||
        key === 'payrollCredit' ||
        key === 'payrollGrossProfit' ||
        key === 'payrollLoad' ||
        key === 'payrollOtherServiceFee' ||
        key === 'payrollPotentialGrossProfit' ||
        key === 'payrollServiceFee' ||
        key === 'payrollPayout' ||
        key === 'payrollCreditCheck' ||
        key === 'payrollLpf' ||
        key === 'payrollSoftwareFee' ||
        key === 'payrollFactoringFee'
      ) {
        result.permissions.payroll[key] = data?.permissions?.[key];
        if (result.permissions.payroll[key].view && key === 'payrollPayout') {
          result.permissions.payroll.visibleTabs.push(key);
        } else if (
          result.permissions.payroll[key].view &&
          key !== 'payrollPayout' &&
          !(result.permissions.payroll.visibleTabs?.length >= 2)
        ) {
          result.permissions.payroll.visibleTabs.push('payrollInformation');
        }
        if (key !== 'payrollPayout' && result.permissions.payroll[key].view) {
          result.permissions.payroll.informationCards.push(key);
        }
      } else if (key === 'newCredit' || key === 'mcCustomer') {
        result.permissions['credit-check'][key] = data?.permissions?.[key];
        if (result.permissions['credit-check'][key].view) {
          result.permissions['credit-check'].visibleTabs.push(key);
        }
      } else if (key === 'contact') {
        result.permissions.contact[key] = data?.permissions?.[key];
        if (result.permissions.contact[key].view) {
          result.permissions.contact.visibleTabs.push(key);
        }
      } else if (key === 'payable' || key === 'receivable' || key === 'billing' || key === 'accountCollection') {
        result.permissions.factoring[key] = data?.permissions?.[key];
        if (result.permissions.factoring[key].view) {
          result.permissions.factoring.visibleTabs.push(key);
        }
      } else if (key === 'truckBoard' || key === 'truckBoardCoordinator') {
        result.permissions['truck-board'][key] = data?.permissions?.[key];
        if (result.permissions['truck-board'][key].view) {
          result.permissions['truck-board'].visibleTabs.push(key);
        }
      } else if (key === 'permission') {
        result.permissions.permission[key] = data?.permissions?.[key];
        if (result.permissions.permission[key].view) {
          result.permissions.permission.visibleTabs.push(key);
        }
      }
    }
    result.roleLevel = +employeeLevel;
    result.permissions.profile.employee.visibleSubTabs = employeeLevelSubTabs.slice(+employeeLevel - 1);

    result.permissions.payroll.payment.visibleSubTabs = paymentLevelSubTabs.slice(
      result.roleLevel === IRoleLevel.corpLevel || result.roleLevel === IRoleLevel.officeLevel
        ? 0
        : +result.roleLevel - 2
    );
  } else {
    if (userType === 1) {
      result.roleLevel = 1;
    } else {
      result.roleLevel = +agentType + 1;
    }

    if (agentType === IAgentType.Owner && isOutside) {
      const visibleTabs = [];
      data?.permissions?.billing?.view && visibleTabs.push('billing');
      data?.permissions?.payable?.view && visibleTabs.push('payable');
      data?.permissions?.receivable?.view && visibleTabs.push('receivable');
      data?.permissions?.accountCollection?.view && visibleTabs.push('accountCollection');
      result.permissions = {
        ...staticPermissions[agentType as keyof typeof staticPermissions],
        factoring: {
          billing: data?.permissions?.billing,
          payable: data?.permissions?.payable,
          receivable: data?.permissions?.receivable,
          accountCollection: data?.permissions?.accountCollection,
          visibleTabs: visibleTabs,
        },
        payroll: {
          ...result.permissions.payroll,
          payrollSoftwareFee: data?.permissions.payrollSoftwareFee,
          payrollFactoringFee: data?.permissions.payrollFactoringFee,
          payrollLpf: data?.permissions.payrollLpf,
          visibleTabs:
            data?.permissions?.payrollSoftwareFee?.view || data?.permissions?.payrollSoftwareFee?.view
              ? [...result.permissions.payroll.visibleTabs, 'payrollInformation']
              : result.permissions.payroll.visibleTabs,
        },
      };
      for (const key in data?.permissions) {
        if (
          key === 'payrollAdvance' ||
          key === 'payrollCharge' ||
          key === 'payrollCollection' ||
          key === 'payrollCredit' ||
          key === 'payrollGrossProfit' ||
          key === 'payrollLoad' ||
          key === 'payrollOtherServiceFee' ||
          key === 'payrollPotentialGrossProfit' ||
          key === 'payrollServiceFee' ||
          key === 'payrollPayout' ||
          key === 'payrollCreditCheck' ||
          key === 'payrollLpf' ||
          key === 'payrollSoftwareFee' ||
          key === 'payrollFactoringFee'
        ) {
          result.permissions.payroll[key] = data?.permissions?.[key];
          if (result.permissions.payroll[key].view && key === 'payrollPayout') {
            result.permissions.payroll.visibleTabs.push(key);
          } else if (
            result.permissions.payroll[key].view &&
            key !== 'payrollPayout' &&
            !(result.permissions.payroll.visibleTabs?.length >= 2)
          ) {
            result.permissions.payroll.visibleTabs.push('payrollInformation');
          }
          if (key !== 'payrollPayout' && result.permissions.payroll[key].view) {
            result.permissions.payroll.informationCards.push(key);
          }
        }
      }
    } else {
      result.permissions = staticPermissions[agentType as keyof typeof staticPermissions];
    }
    if (userType === UserTypes.ADMIN || data?.permissions?.payrollCreditCheck?.view) {
      result.permissions = {
        ...result?.permissions,
        payroll: {
          ...result?.permissions?.payroll,
          payrollCreditCheck: {
            view: true,
            download: false,
            actions: {
              noAction: true,
            },
          },
          ...(userType === UserTypes.ADMIN
            ? {
                payrollSoftwareFee: {
                  view: true,
                  download: false,
                  actions: {
                    noAction: true,
                  },
                },
              }
            : {}),
          ...(userType === UserTypes.ADMIN
            ? {
                payrollFactoringFee: {
                  view: true,
                  download: false,
                  actions: {
                    noAction: true,
                  },
                },
              }
            : {}),
        },
      };
    }

    if (agentType !== 3) {
      result.allPossibleTabs.push('permission');
      result.routes.push('permission');
    }
  }

  for (const key in result.permissions) {
    if (result.permissions[key].visibleTabs.length) {
      result.routes.push(key);
      result.allPossibleTabs.push(...result.permissions[key].visibleTabs);
    }
  }

  result.allPossibleSubTabs = [
    ...result.permissions.profile.carrier.visibleSubTabs,
    ...result.permissions.profile.customer.visibleSubTabs,
    ...result.permissions.profile.employee.visibleSubTabs,
    ...(result?.permissions?.payroll?.payment?.visibleSubTabs || []),
  ];

  return { ...result, allPossibleTabs: Array.from(new Set(result.allPossibleTabs)) };
};
