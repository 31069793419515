import { ReactNode } from 'react';

export interface IInformationTableColumns {
  title: string;
  id: string;
  dataIndex: string;
  displayType: string;
  isVisible: boolean;
  sortable: boolean;
  width: number;
  isSearchable?: boolean;
}

export interface IExpenses {
  agency: string;
  type: string;
  amount: string;
}

export interface IInformationTableData {
  id: number | string;
  date: string;
  load_type: string;
  ref_number: string;
  customer_name: string;
  distribute: string;
}

export enum FactoredCards {
  LOADS = '1',
  GROSS_PROFIT = '3',
  SERVICE_FEE = '6',
}

export enum NotFactoredCards {
  POTENTIAL_GROSS_PROFIT = '2',
}

export enum DistributeCards {
  CREDITS = '4',
  CHARGES = '5',
}

export enum AdvancedCards {
  ADVANCE = '8',
}

export enum CollectionCards {
  COLLECTION = '9',
}

export enum CreditCheckCard {
  CREDIT_CHECK = '10',
}

export enum LPF_SOFTWARE_FEE_CARDS_ENUM {
  SOFTWARE_FEE = '11',
  LPF = '12',
  FACTORING_FEE = '13',
}

export enum OtherServiceFeeCards {
  OTHER_SERVICE_FEE = '7',
}

export enum IInformationTabColumnsName {
  Advance = 'payrollInformationAdvance',
  Charges = 'payrollInformationCharges',
  Collection = 'payrollInformationCollection',
  Credit = 'payrollInformationCredit',
  GrossProfit = 'payrollInformationGrossProfit',
  LoadProfitFee = 'payrollInformationLoadProfitFee',
  Loads = 'payrollInformationLoads',
  OtherServiceFee = 'payrollInformationOtherServiceFee',
  PotencialGrossProfit = 'payrollInformationPotencialGrossProfit',
  ServiceFee = 'payrollInformationServiceFee',
  CreditCheck = 'payrollInformationCreditCheck',
  SoftwareFee = 'payrollInformationLoadProfitSoftwareFee',
  Lpf = 'payrollInformationLpf',
  FactoringFee = 'payrollInformationFactoringFee',
}

export type TFields = 'corporate' | 'agency' | 'branch' | 'agent';

export interface IDistributeValues {
  corporate: {
    sum: number | string | null;
    percent: string | number | null;
  };
  agency: {
    sum: number | string | null;
    percent: string | number | null;
  };
  branch: {
    sum: number | string | null;
    percent: string | number | null;
  };
  agent: {
    sum: number | string | null;
    percent: string | number | null;
  };
}

export interface ICollectionValues {
  agency: any;
  branch: any;
  agent: any;
  type: string;
  amount?: string | number;
  reason?: string;
  paymentPlan?: string | null;
  paymentPlanId?: number | null;
}

export interface IChildren {
  [key: string]: {
    component: ReactNode;
    buttonText?: string;
    onCancel?: () => void;
    onSubmit?: () => void;
  };
}

export interface IInformTabCards {
  [key: string]: IInformationTabColumnsName;
}
export interface IInformationFilters {
  monthPickerPayroll: { startDate: Date | number | string; endDate: Date | number | string };
  officePayroll?: any;
  branchPayroll?: any;
  agentPayroll?: any;
}

export enum SUMMARIES_FACTORED_CARDS {
  'loads' = 1,
  'grossProfit' = 3,
  'serviceFee' = 6,
}
