import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { closeModal } from 'store/modal-slice/modals';
import { selectModals } from 'store/modal-slice/selector';
import { checkIsNumberDecimal, formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import { defaultValues } from '../collection-view/constants/constants';
import { ICollectionModalContentFormData } from '../collection-view/constants/types';

import { SUBJECT } from './constants/constants';
import { ICollectionModalContentProps } from './constants/types';
import { validation } from './constants/validation';

const useCollectionModalContent = ({
  total,
  enteredCount,
  customerDeptAmount,
}: Partial<ICollectionModalContentProps>) => {
  const dispatch = useDispatch();

  const modalData = useSelector(selectModals);
  const opened = modalData?.opened;
  const loadIds = modalData?.loadIds;

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
    clearErrors,
    watch,
    getValues,
    reset,
    setValue,
    trigger,
  } = useForm<ICollectionModalContentFormData>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validation()),
  });

  const handleCancel = () => {
    dispatch(closeModal());
    clearErrors();
    reset();
  };

  useEffect(() => {
    if (!opened) {
      reset();
      clearErrors();
    }
  }, [opened]);

  const isMoneyWarning = useMemo(() => {
    return !!String(watch('amount'))?.length && +watch('amount') < Number(customerDeptAmount || 0)!;
  }, [watch('amount')]);

  const isMoneyWarningGrader = useMemo(() => {
    return !!String(watch('amount'))?.length && +watch('amount') > Number(customerDeptAmount || 0)!;
  }, [watch('amount')]);

  const showMoneyTypeInput =
    watch('moneyType') === SUBJECT.EFS ||
    watch('moneyType') === SUBJECT.COMDATA ||
    watch('moneyType') === SUBJECT.TCHECK;

  useEffect(() => {
    if (enteredCount! > 1) {
      setValue('amount', formatNumberWithThousandsSeparatorAndDecimal(checkIsNumberDecimal(customerDeptAmount!)), {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [total, enteredCount]);

  return {
    handleSubmit,
    loadIds,
    control,
    trigger,
    watch,
    errors,
    getValues,
    showMoneyTypeInput,
    isMoneyWarning,
    handleCancel,
    isValid,
    setValue,
    isMoneyWarningGrader,
  };
};

export default useCollectionModalContent;
