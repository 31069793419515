import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/service';

import { customerReceiveDeserializer } from './deserializers';
import { ICollectionsData, ICustomerBalkPayRequest, IGetCustomerInfo, IGetCustomerInfoParams } from './interface';
import { getCollectionsParamsSerializer, getCollectionsSerializer, getCustomerInfoSerializer } from './serializers';

export const collectionsAPI = createApi({
  reducerPath: 'collectionsAPI',
  baseQuery,
  tagTypes: ['Collections', 'Loads'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    getCollections: builder.query({
      query: (filters: any) => {
        const queryParams = getCollectionsParamsSerializer(filters);
        return {
          url: 'factoring/collections',
          params: queryParams,
        };
      },
      providesTags: ['Collections', 'Loads'],
      transformResponse: (body: ICollectionsData) => getCollectionsSerializer(body),
    }),
    getCustomerInfo: builder.mutation({
      query: ({ customerId, authorityIds, skip, limit }: IGetCustomerInfoParams) => ({
        url: 'loads/customer-info',
        method: 'POST',
        body: {
          customerId: Number(customerId),
          authorityIds: authorityIds?.map(el => Number(el)),
          skip,
          limit,
          place: 'collection',
        },
      }),
      transformResponse: (body: IGetCustomerInfo) => getCustomerInfoSerializer(body),
    }),
    getDetailedCustomer: builder.query({
      query: id => `customers/${id}/detailed`,
      providesTags: ['Collections'],
    }),
    getPaymentDetails: builder.query({
      query: (id: string) => ({
        url: `/loads/${id}/customer-pay-info`,
        providesTags: ['Collections'],
      }),
    }),
    getCustomerReceive: builder.mutation({
      query: (body: ICustomerBalkPayRequest) => ({
        url: '/loads/customer-balk-pay',
        method: 'PATCH',
        body: customerReceiveDeserializer(body),
      }),
    }),
    sendInvoiceReceivables: builder.mutation({
      query: (id: string) => ({
        url: `/loads/${id}/send-invoice`,
        method: 'POST',
      }),
      invalidatesTags: ['Collections'],
    }),
    markAsInvoiceReceived: builder.mutation({
      query: (id: string) => ({
        url: `/loads/${id}/receive-invoice`,
        method: 'POST',
      }),
      invalidatesTags: ['Collections', 'Loads'],
    }),
  }),
});

export const {
  useGetCollectionsQuery,
  useGetCustomerInfoMutation,
  useGetDetailedCustomerQuery,
  useGetPaymentDetailsQuery,
  useLazyGetPaymentDetailsQuery,
  useGetCustomerReceiveMutation,
  useSendInvoiceReceivablesMutation,
  useMarkAsInvoiceReceivedMutation,
} = collectionsAPI;
