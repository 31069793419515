export interface IPermissions {
  agent: IBasePermissions;
  office: IBasePermissions;
  branch: IBasePermissions;
  authority: IBasePermissions;
  employee: IBasePermissions;
  carrierTable: CarriersTablePermission;
  carrierRequest: CarriersRequestPermission;
  carriersBlackList: CarriersBlackListPermission;
  customersTable: CustomersTablePermission;
  customersBlackList: CustomersBlackListPermission;
  load: LoadsPermission;
  newLoad: NewLoadsPermission;
  payrollInformation: PayrollInformationPermission;
  payrollPayout: PayrollPayoutPermission;
  newCredit: NewCreditPermission;
  mcCustomer: McAndCustomersPermission;
  contact: ContactPermission;
  billing: BillingPermission;
  payable: PayablePermission;
  receivable: ReceivablesPermission;
  truckBoard: TruckBoardPermission;
  truckBoardCoordinator: TruckBoardCoordinatorPermission;
  truckBoardCarrierRequest: TruckBoardCarrierRequestPermission;
}
export interface IPermissionsModified {
  profiles: IBasePermissions[];
  loads: [LoadsPermission, NewLoadsPermission];
  payroll: [PayrollInformationPermission, PayrollPayoutPermission];
  creditcheck: [NewCreditPermission, McAndCustomersPermission];
  contact: [ContactPermission];
  factoring: [BillingPermission, PayablePermission, ReceivablesPermission];
  truckboard: [TruckBoardPermission, TruckBoardCoordinatorPermission, TruckBoardCarrierRequestPermission];
}
export interface IEditRequest {
  data: any;
  id: number;
  defaultPermissions: any;
  mode: string;
}
export interface IBasePermissions {
  view: boolean;
  download: boolean;
  actions: IBaseActions;
}
export interface IBaseActions {
  create: boolean;
  edit: boolean;
  terminateOn: boolean;
  terminateOff: boolean;
}
export interface CarriersTablePermission extends IBasePermissions {
  actions: CarriersTableActions;
}
export interface CarriersTableActions extends IBaseActions {
  deleteComment: boolean;
}
export interface CarriersRequestPermission {
  view: boolean;
  download: boolean;
  actions: CarriersRequestActions;
}
export interface CarriersRequestActions {
  approve: boolean;
  deny: boolean;
}
export interface CarriersBlackListPermission {
  view: boolean;
  download: boolean;
  actions: CarriersBlackListActions;
}
export interface CarriersBlackListActions {
  lock: boolean;
  unlock: boolean;
}
export interface CustomersTablePermission extends IBasePermissions {
  actions: CustomersTableActions;
}
export interface CustomersBlackListPermission {
  view: boolean;
  download: boolean;
  actions: CustomersBlackListActions;
}
export interface CustomersBlackListActions {
  lock: boolean;
  unlock: boolean;
}
export interface LoadsPermission {
  view: boolean;
  download: boolean;
  actions: LoadsActions;
}
export interface LoadsActions {
  addPickUpDropOff: boolean;
  editPickUpDropOff: boolean;
  uploadsFiles: boolean;
  filesDelete: boolean;
  transaction: boolean;
  deleteComment: boolean;
  editReferenceNumber: boolean;
  sendToBilling: boolean;
  acceptToBilling: boolean;
  reject: boolean;
  claim: boolean;
  resolve: boolean;
  cancel: boolean;
  editSoldAsMc: boolean;
  transactionDelete: boolean;
}
export interface NewLoadsPermission {
  view: boolean;
  download: boolean;
}
export interface PayrollInformationPermission {
  view: boolean;
  download: boolean;
  actions: PayrollInformationActions;
}
export interface PayrollInformationActions {
  createOtherServiceFee: boolean;
  editOtherServiceFee: boolean;
  deleteOtherServiceFee: boolean;
  activeOtherServiceFee: boolean;
  passivateOtherServiceFee: boolean;
  createAdvance: boolean;
  editAdvance: boolean;
  deleteAdvance: boolean;
  distribute: boolean;
}
export interface PayrollPayoutPermission {
  view: boolean;
  download: boolean;
  actions: PayrollPayoutActions;
}
export interface PayrollPayoutActions {
  view: boolean;
  download: boolean;
  actions: PaymentActions;
}
export interface NewCreditPermission {
  view: boolean;
  download: boolean;
  actions: NewCreditActions;
}
export interface NewCreditActions {
  approve: boolean;
  deny: boolean;
  cod: boolean;
  consignment: boolean;
}
export interface PaymentActions {
  payNegativeBalance: boolean;
  payPositiveBalance: boolean;
}
export interface McAndCustomersPermission {
  view: boolean;
  download: boolean;
}
export interface ContactPermission {
  view: boolean;
  download: boolean;
  actions: ContactActions;
}
export interface ContactActions {
  create: boolean;
  edit: boolean;
  delete: boolean;
}
export interface BillingPermission {
  view: boolean;
  download: boolean;
  actions: BillingActions;
}
export interface BillingActions {
  acceptToBilling: boolean;
  reject: boolean;
  claimLoadAcceptToBilling: boolean;
  claimLoadReject: boolean;
}
export interface PayablePermission {
  view: boolean;
  download: boolean;
  actions: PayableActions;
}

export interface PayableActions {
  pay: boolean;
  claimLoadPay: boolean;
}
export interface ReceivablesPermission {
  view: boolean;
  download: boolean;
  actions: ReceivablesActions;
}

export interface ReceivablesActions {
  receive: boolean;
  claimLoadReceive: boolean;
  moveToCollection: boolean;
}
export interface TruckBoardPermission {
  view: boolean;
  download: boolean;
  actions: TruckBoardActions;
}

export interface TruckBoardActions {
  createSearch: boolean;
}
export interface TruckBoardPermission {
  view: boolean;
  download: boolean;
  actions: TruckBoardActions;
}

export interface TruckBoardActions {
  createSearch: boolean;
}
export interface TruckBoardCoordinatorPermission {
  view: boolean;
  download: boolean;
  actions: TruckBoardCoordinatorActions;
}

export interface TruckBoardCoordinatorActions {
  coordinator: boolean;
}
export interface TruckBoardCarrierRequestPermission {
  view: boolean;
  download: boolean;
  actions: TruckBoardCarrierRequestActions;
}

export interface TruckBoardCarrierRequestActions {
  carrierRequest: boolean;
}
export interface CustomersTableActions extends IBaseActions {
  deleteComment: boolean;
}
export interface ChangeStatusRequest {
  id: number;
  status: StatusTypes;
}
export enum StatusTypes {
  STATUS_TYPES_ACTIVE = 1,
  STATUS_TYPES_INACTIVE = 2,
}
export interface GetResponse {
  result: Array<PermissionGroup>;
  count: number;
}
export interface PermissionGroup {
  id: number;
  name: string;
}
export interface IPermissionByIdResponse {
  result: Permission;
}
export interface Permission {
  id: number;
  name: string;
  officeId: number;
  branchId: number;
  terminatedAt?: number;
  terminatedBy?: number;
  createdAt: number;
  createdBy: number;
  updatedAt?: number;
  updatedBy?: number;
  permissions: IPermissions;
}
export interface IPermissionByIdResponseSerialized {
  id: number;
  name: string;
  officeId: number;
  branchId: number;
  terminatedAt?: number;
  terminatedBy?: number;
  createdAt: number;
  createdBy: number;
  updatedAt?: number;
  updatedBy?: number;
  permissions: IPermissionsModified;
}

export enum IpWhitelistEntityTypes {
  unspecified = '',
  agency = 'agency',
  corporate = 'corporate',
}
